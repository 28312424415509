import React from 'react'
import UserSidebar from '../../../../components/UserSidebar'
function Offerfive() {
  return (
<section className="dasgboard-miansection">
  <div className="container">
    <div className="row pt-2">
    <UserSidebar/>
      <div className="col-sm-9 col-md-9 col-lg-9 dashboard-main-content">
        <div className="dashboard-title">
          <div className="sllermain-title">
            <h1>DASHBOARD</h1>
            <div className="sidebar-brand">
              <h2 id="formButton" onclick="location.href='../offerone.html';">
                Switch to buyer{" "}
                <i className="fa fa-refresh" aria-hidden="true" />
               
              </h2>
            </div>
          </div>
        </div>
        <div className="row my-4 Latest-Order gx-0">
          <div className="col-10 latest_order">
            <div className="order-heading">
              <h1>
                {" "}
                <span className="icon-shop">
                  {" "}
                  <i className="fa-solid fa-location-arrow" />{" "}
                </span>
                Offer
              </h1>
            </div>
            <div className="tabmake offerfivelist">
              <div
                className="order-heading"
                style={{ paddingTop: 15, textAlign: "center" }}
              >
                <h1>Victorian Signature plate VIC XX3</h1>
              </div>
              <div className="orderhead auction-table">
                <ul className="order-first auction-head">
                  <li> Name </li>
                  <li> BUYER OFFER </li>
                  <li> COUNTER OFFER </li>
                  <li></li>
                </ul>
                <ul className="order-five auction-table-body">
                  <li> Lorem ipsum</li>
                  <li> $6,500.00 </li>
                  <li> $6,500.00 </li>
                  <li>
                    {" "}
                    <a href="" className="accep">
                      {" "}
                      Accept{" "}
                    </a>
                    <a href="" className="decline">
                      {" "}
                      Decline{" "}
                    </a>
                    <a href="./offersix.html" className="counter">
                      {" "}
                      Counter
                    </a>
                  </li>
                </ul>
                <ul
                  className="order-five auction-table-body vichover"
                  style={{ backgroundColor: "#EBEBEB" }}
                >
                  <li> Lorem ipsum</li>
                  <li> $6,500.00 </li>
                  <li> $6,500.00 </li>
                  <li>
                    {" "}
                    <a href="" className="accep">
                      {" "}
                      Accept{" "}
                    </a>
                    <a href="" className="decline">
                      {" "}
                      Decline{" "}
                    </a>
                    <a href="./offersix.html" className="counter">
                      {" "}
                      Counter
                    </a>
                  </li>
                </ul>
                <ul className="order-five auction-table-body">
                  <li> Lorem ipsum</li>
                  <li> $6,500.00 </li>
                  <li> $6,500.00 </li>
                  <li>
                    {" "}
                    <a href="" className="accep">
                      {" "}
                      Accept{" "}
                    </a>
                    <a href="" className="decline">
                      {" "}
                      Decline{" "}
                    </a>
                    <a href="./offersix.html" className="counter">
                      {" "}
                      Counter
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>

  )
}

export default Offerfive