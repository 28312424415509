import React from 'react';
import { ApolloProvider, ApolloClient, InMemoryCache, createHttpLink } from '@apollo/client';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { BrowserRouter } from "react-router-dom";
import "../node_modules/bootstrap/dist/css/bootstrap.min.css";
import { store, persistor } from './redux/store'
import { Provider, useSelector } from "react-redux";
import { IpAddress } from './utils/Function';
import { ErrorBoundary } from "react-error-boundary";
import { PersistGate } from "redux-persist/integration/react";





const Comp = () => {
  const userData = useSelector(state => state.user.userData)
  // console.log(IpAddress, 'ip addressss')
  const httpLink = createHttpLink({
    uri: IpAddress, // Replace with your GraphQL API endpoint
    headers: {
      Authorization: `Bearer ${userData?.accessToken}`, // Set authorization token from localStorage
      'Content-Type': 'application/json'
    }
  });

  const client = new ApolloClient({
    link: httpLink,
    cache: new InMemoryCache(),
  });

  return (
    <ErrorBoundary fallback={<div class="container pt-5 mt-5 text-center"><h1>Oops!</h1><br/><h2>Something went wrong...</h2></div>}>
      <ApolloProvider client={client}>
        <BrowserRouter>
          <App />
        </BrowserRouter>
      </ApolloProvider>
      </ErrorBoundary>
  )
}




const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <Provider store={store}>
    <PersistGate loading={null} persistor={persistor}>
      <Comp />
    </PersistGate>
  </Provider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
