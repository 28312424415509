import { Link } from 'react-router-dom';
import Sidebar from '../../../components/Sidebar'
import counter from '../../../assets/images/encounter.png'
import MainCancelImg from '../../../assets/images/cancel-icn.png'

function Main_first_modal() {
  return (
<div class="bg-dashboard">
  <main id="main">
  <Sidebar />
    <div className="dashboard-right-side main-first-modal-parent">
      <div className="modal-form-request-parent">
        <div className="modal-form-request-inner">
          <div className="cancel-icn">
            <Link to="">
              <img src={MainCancelImg} alt="" />
            </Link>
          </div>
          <div className="abc-heading text-center">
            <h1>ABC-123</h1>
          </div>
          <div className="counter-main-modal text-center mt-3">
            <img src={counter} alt="" />
          </div>
          <div className="winner-table-parent mt-3">
            <div className="winner-table d-flex w-100">
              <div
                style={{ width: "33.3%" }}
                className="winner-table-inner text-center"
              >
                <h3>Name of Bidder</h3>
              </div>
              <div
                style={{ width: "33.3%" }}
                className="winner-table-inner text-center"
              >
                <h3>Bidding Amount</h3>
              </div>
              <div
                style={{ width: "33.3%" }}
                className="winner-table-inner text-center"
              >
                <h3>Current Highest Bid</h3>
              </div>
            </div>
            <div className="winner-table winner-table-2 d-flex w-100">
              <div
                style={{ width: "33.3%" }}
                className="winner-table-inner text-center"
              >
                <h3>Ethen Booker</h3>
              </div>
              <div
                style={{ width: "33.3%" }}
                className="winner-table-inner text-center"
              >
                <h3>$5000.00</h3>
              </div>
              <div
                style={{ width: "33.3%" }}
                className="winner-table-inner text-center"
              >
                <h3>$5000.00</h3>
              </div>
            </div>
            <div className="winner-table winner-table-2 d-flex w-100">
              <div
                style={{ width: "33.3%" }}
                className="winner-table-inner text-center"
              >
                <h3 style={{ background: "#EBEBEB" }}>Mark Allen</h3>
              </div>
              <div
                style={{ width: "33.3%" }}
                className="winner-table-inner text-center"
              >
                <h3 style={{ background: "#EBEBEB" }}>$5000.00</h3>
              </div>
              <div
                style={{ width: "33.3%" }}
                className="winner-table-inner text-center"
              >
                <h3 style={{ background: "#EBEBEB" }}>$5000.00</h3>
              </div>
            </div>
            <div className="winner-table winner-table-2 d-flex w-100">
              <div
                style={{ width: "33.3%" }}
                className="winner-table-inner text-center"
              >
                <h3>John Smith</h3>
              </div>
              <div
                style={{ width: "33.3%" }}
                className="winner-table-inner text-center"
              >
                <h3>$5000.00</h3>
              </div>
              <div
                style={{ width: "33.3%" }}
                className="winner-table-inner text-center"
              >
                <h3>$5000.00</h3>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </main>
  {/* Latest compiled JavaScript */}
</div>

  )
}

export default Main_first_modal