import React, { useState } from "react";
import Sidebar from '../../../components/Sidebar'
import box1 from '../../../assets/images/an-top-box-1.png'
import box2 from '../../../assets/images/an-top-box-2.png'
import box3 from '../../../assets/images/an-top-box-3.png'
import box4 from '../../../assets/images/an-top-box-4.png'
import graph1 from "../../../assets/images/graph-1.png";
import graph2 from "../../../assets/images/graph-2.png";
import graph3 from "../../../assets/images/graph-3.png";
import cust1 from "../../../assets/images/cust-img1.png";
import cust2 from "../../../assets/images/cust-img2.png";
import cust3 from "../../../assets/images/cust-img3.png";
import cust4 from "../../../assets/images/cust-img4.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronDown } from "@fortawesome/free-solid-svg-icons";
import orderCustomer from "../../../assets/images/order-customer-box-check.png";
import BarChart from "../../Dasboard/BarChart";
import { UserData } from "../../Dasboard/Data";
// import { Bar } from "recharts";
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
} from "recharts";
import ResponsiveNavBarDasbord from "../../../components/ResponsiveNavBarDasbord";


function Analytic() {
  // ----------------------------Line bar data --------------------

  const [userData, setUserData] = useState({
    labels: UserData.map((data) => data.year),
    datasets: [
      {
        // label: "Users Gained",
        data: UserData.map((data) => data.userGain),
        backgroundColor: ["#7A724E", "#DFD5AA"],
        width: "20px",
      },
    ],
  });

  // const data = [
  //   {
  //     name: "jan",
  //     uv: 3800,
  //     pv: 5000,
  //     amt: 2400,
  //   },
  //   {
  //     name: "feb",
  //     uv: 3400,
  //     pv: 4098,
  //     amt: 2210,
  //   },
  //   {
  //     name: "mar",
  //     uv: 4000,
  //     pv: 3000,
  //     amt: 2290,
  //   },
  //   {
  //     name: "Apr",
  //     uv: 4100,
  //     pv: 3808,
  //     amt: 2000,
  //   },
  //   {
  //     name: "May",
  //     uv: 4800,
  //     pv: 4300,
  //     amt: 2181,
  //   },
  //   {
  //     name: "jun",
  //     uv: 4400,
  //     pv: 5300,
  //     amt: 2500,
  //   },
  //   {
  //     name: "july",
  //     uv: 5200,
  //     pv: 4300,
  //     amt: 2100,
  //   },
  //   {
  //     name: "Aug",
  //     uv: 6200,
  //     pv: 4100,
  //     amt: 2100,
  //   },
  //   {
  //     name: "sep",
  //     uv: 4300,
  //     pv: 4400,
  //     amt: 2100,
  //   },
  //   {
  //     name: "Oct",
  //     uv: 5700,
  //     pv: 3700,
  //     amt: 2100,
  //   },
  //   {
  //     name: "Nov",
  //     uv: 4100,
  //     pv: 4100,
  //     amt: 2100,
  //   },
  // ];


  return (
<>    
<div class="bg-dashboard">
    <div id="main">
    <ResponsiveNavBarDasbord/>
            <Sidebar/>
        <div class="dashboard-right-side">
            <div class="page-title">
              <h1>Analytics</h1>
            </div>
            <div class="payment-inner-page-set box-analytics-parent-al">
              <div class="content-analytics-parent">
                <div class="row">
                  <div class="col-lg-3">
                    <div class="analytics-top-box-parent">
                      <div class="analytics-top-box d-flex">
                        <div class="analytics-top-box-content d-flex flex-column">
                          <h3>Total Sessions</h3>
                          <h4>14k</h4>
                          <h5>
                            <span>
                              2.3% <i class="fa-solid fa-arrow-up"></i>
                            </span>{" "}
                            than last year
                          </h5>
                        </div>
                        <div class="analytics-top-box-content d-flex align-items-center">
                          <img src={box1} alt="" />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-lg-3">
                    <div class="analytics-top-box-parent">
                      <div class="analytics-top-box d-flex">
                        <div class="analytics-top-box-content d-flex flex-column">
                          <h3>Total Sessions</h3>
                          <h4>56%</h4>
                          <h5>
                            <span>
                              2.1% <i class="fa-solid fa-arrow-up"></i>
                            </span>{" "}
                            than last year
                          </h5>
                        </div>
                        <div class="analytics-top-box-content d-flex align-items-center">
                          <img src={box2} alt="" />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-lg-3">
                    <div class="analytics-top-box-parent">
                      <div class="analytics-top-box d-flex">
                        <div class="analytics-top-box-content d-flex flex-column">
                          <h3>Live Users</h3>
                          <h4>10</h4>
                          <h5>
                            <span style={{ color: "#F0142F" }}>
                              1.8% <i class="fa-solid fa-arrow-down"></i>
                            </span>{" "}
                            than last hour
                          </h5>
                        </div>
                        <div class="analytics-top-box-content d-flex align-items-center">
                          <img src={box3} alt="" />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-lg-3">
                    <div class="analytics-top-box-parent">
                      <div class="analytics-top-box d-flex">
                        <div class="analytics-top-box-content d-flex flex-column">
                          <h3>Total Users</h3>
                          <h4>20k</h4>
                          <h5>
                            <span>
                              1.3% <i class="fa-solid fa-arrow-up"></i>
                            </span>{" "}
                            than last year
                          </h5>
                        </div>
                        <div class="analytics-top-box-content d-flex align-items-center">
                          <img src={box4} alt="" />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="analytics-revenue-parent my-4">
                  <div class="analytics-revenue-heading pt-2 pb-4 px-4">
                    <div class="analytics-revenue-heading-inner">
                      <h3>Total Revenues</h3>
                    </div>
                    <div class="analytics-revenue-heading-inner">
                      <h4>
                        Last 12 Months <FontAwesomeIcon icon={faChevronDown} style={{fontSize:"15px", color: "black",}}/>
                      </h4>
                    </div>
                  </div>
                  <div class="radio-type-circle-parent d-flex justify-content-center">
                    <div class="radio-type-circle">
                      <h4 class="mx-4">
                        <span class="circ-1"></span>Auction
                      </h4>
                    </div>
                    <div class="radio-type-circle">
                      <h4 class="mx-4">
                        <span class="circ-2"></span>Listing
                      </h4>
                    </div>
                  </div>
                  <div class="graph-parent">
                    <img src={graph1} />
                    {/* <LineChart width={1100} height={400} data={data}>
                      <CartesianGrid strokeDasharray="3 3" />
                      <XAxis dataKey="name" padding={{ left: 30, right: 30 }} />
                      <YAxis />
                      <Tooltip />
                      <Legend />
                      <Line
                        type="monotone"
                        dataKey="pv"
                        stroke="#c9ba78"
                        dot={false}
                      />
                      <Line
                        type="monotone"
                        dataKey="uv"
                        stroke="#5e5635"
                        dot={false}
                      />
                    </LineChart> */}
                  </div>
                </div>
                <div class="revenue-row-parent">
                  <div class="row">
                    <div class="col-lg-8">
                      <div class="analytics-revenue-parent my-4">
                        <div class="analytics-revenue-heading pt-2 pb-3 px-4">
                          <div class="analytics-revenue-heading-inner">
                            <h3>Customer Growth</h3>
                          </div>
                        </div>
                        <div class="radio-type-circle-parent d-flex justify-content-start pb-4 ps-3">
                          <div class="radio-type-circle">
                            <h4 class="mx-4">
                              <span class="circ-1"></span>Men
                            </h4>
                          </div>
                          <div class="radio-type-circle">
                            <h4 class="mx-4">
                              <span class="circ-2"></span>Women
                            </h4>
                          </div>
                          <div class="radio-type-circle">
                            <h4 class="mx-4">
                              <span class="circ-3"></span>New Customer
                            </h4>
                          </div>
                        </div>
                        <div class="graph-parent">
                          <div class="an-graph-one px-4">
                            <img src={graph2} alt="" />

                            {/* <BarChart
                              width={700}
                              height={300}
                              data={graphdata}
                              margin={{
                                top: 0,
                                right: 10,
                                left: 20,
                                bottom: 0,
                              }}
                            >
                              <CartesianGrid strokeDasharray="  10" />
                              <XAxis dataKey="name" />
                              <YAxis />
                              <Tooltip />
                              <Legend />
                              <Bar
                                barSize={10}
                                dataKey="women"
                                stackId="a"
                                fill="#DFD5AA"
                              />
                              <Bar dataKey="men" stackId="a" fill="#7A724E" />
                              <Bar
                                dataKey="newCustomer"
                                stackId="a"
                                fill="#b1b1b1"
                              />
                            </BarChart> */}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="col-lg-4">
                      <div class="new-customer-box-parent my-4">
                        <div class="new-customer-box">
                          <div class="analytics-revenue-heading-inner mt-2 mb-3">
                            <h3>New Customers</h3>
                          </div>
                          <div class="d-flex justify-content-between content-new-cust">
                            <div class="d-flex">
                              <div class="img-customer me-2">
                                <img src={cust1} alt="" />
                              </div>
                              <div class="d-flex flex-column">
                                <h3>Mitchell Williams</h3>
                                <h4>Customer ID#01223</h4>
                              </div>
                            </div>
                            <div class="env-icn pt-1">
                              <i class="fa-regular fa-envelope"></i>
                            </div>
                          </div>
                          <div class="d-flex justify-content-between content-new-cust">
                            <div class="d-flex">
                              <div class="img-customer me-2">
                                <img src={cust2} alt="" />
                              </div>
                              <div class="d-flex flex-column">
                                <h3>Sam Conner</h3>
                                <h4>Customer ID#01221</h4>
                              </div>
                            </div>
                            <div class="env-icn pt-1">
                              <i class="fa-regular fa-envelope"></i>
                            </div>
                          </div>
                          <div class="d-flex justify-content-between content-new-cust">
                            <div class="d-flex">
                              <div class="img-customer me-2">
                                <img src={cust3} alt="" />
                              </div>
                              <div class="d-flex flex-column">
                                <h3>Christina Castro</h3>
                                <h4>Customer ID#01222</h4>
                              </div>
                            </div>
                            <div class="env-icn pt-1">
                              <i class="fa-regular fa-envelope"></i>
                            </div>
                          </div>
                          <div class="d-flex justify-content-between content-new-cust pb-4">
                            <div class="d-flex">
                              <div class="img-customer me-2">
                                <img src={cust4} alt="" />
                              </div>
                              <div class="d-flex flex-column">
                                <h3>Harriett Clark</h3>
                                <h4>Customer ID#01222</h4>
                              </div>
                            </div>
                            <div class="env-icn pt-1">
                              <i class="fa-regular fa-envelope"></i>
                            </div>
                          </div>
                          <div class="more-insight-anchor">
                            <a href="">
                              More Insight{" "}
                              <i class="fa-solid fa-chevron-right"></i>
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-lg-4">
                    <div class="btm-box-4-parent">
                      {/*  row nested  */}
                      <div class="row">
                        <div class="col-lg-5">
                          <div class="btm-an-box-parent">
                            <div class="btm-an-box-heading">
                              <h2>
                                Plates For <br />{" "}
                                <span
                                  style={{
                                    FontWeight: "500",
                                    color: "#212121",
                                  }}
                                >
                                  Auction
                                </span>
                              </h2>
                            </div>
                          </div>
                        </div>
                        <div class="col-lg-7">
                          <div class="month-year-parent-an d-flex justify-content-end mb-2">
                            <div class="month-year-inner-an">
                              <h5 class="me-2">Monthly</h5>
                            </div>
                            <div class="month-year-inner-an">
                              <label id="toggleSwitch" class="switch">
                                <input type="checkbox" class="switch1" />
                                <span class="slider an-slid"></span>
                              </label>
                            </div>
                            <div class="month-year-inner-an">
                              <h5 class="ms-2">Yearly</h5>
                            </div>
                          </div>
                          <div class="ord-customer-an-btm d-flex justify-content-end mt-2">
                            <img src={orderCustomer} alt="" />
                          </div>
                        </div>
                      </div>
                      {/* row nested  */}
                      <div class="graph-btm-parent d-flex justify-content-center">
                        <img src={graph3} alt="" />
                        <div style={{ width: 700 }}>
                          {/* <BarChart redraw={true} chartData={userData} /> */}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-lg-4">
                    <div class="btm-box-4-parent">
                      {/* row nested  */}
                      <div class="row">
                        <div class="col-lg-5">
                          <div class="btm-an-box-parent">
                            <div class="btm-an-box-heading">
                              <h2>
                                Plates For <br />{" "}
                                <span
                                  style={{
                                    FontWeight: "500",
                                    color: "#212121",
                                  }}
                                >
                                  Listing
                                </span>
                              </h2>
                            </div>
                          </div>
                        </div>
                        <div class="col-lg-7">
                          <div class="month-year-parent-an d-flex justify-content-end mb-2">
                            <div class="month-year-inner-an">
                              <h5 class="me-2">Monthly</h5>
                            </div>
                            <div class="month-year-inner-an">
                              <label id="toggleSwitch" class="switch">
                                <input type="checkbox" class="switch1" />
                                <span class="slider an-slid"></span>
                              </label>
                            </div>
                            <div class="month-year-inner-an">
                              <h5 class="ms-2">Yearly</h5>
                            </div>
                          </div>
                          <div class="ord-customer-an-btm d-flex justify-content-end mt-2">
                            <img src={orderCustomer} alt="" />
                          </div>
                        </div>
                      </div>
                      {/* row nested  */}
                      <div class="graph-btm-parent d-flex justify-content-center">
                        <img src={graph3} alt="" />
                        <div style={{ width: 700 }}>
                          {/* <BarChart redraw={true} chartData={userData} /> */}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-lg-4">
                    <div class="btm-box-4-parent">
                      {/* <!-- row nested  --> */}
                      <div class="row">
                        <div class="col-lg-5">
                          <div class="btm-an-box-parent">
                            <div class="btm-an-box-heading">
                              <h2>
                                Total <br />{" "}
                                <span
                                  style={{
                                    FontWeight: "500",
                                    color: "#212121",
                                  }}
                                >
                                  Sales
                                </span>
                              </h2>
                            </div>
                          </div>
                        </div>
                        <div class="col-lg-7">
                          <div class="month-year-parent-an d-flex justify-content-end mb-2">
                            <div class="month-year-inner-an">
                              <h5 class="me-2">Monthly</h5>
                            </div>
                            <div class="month-year-inner-an">
                              <label id="toggleSwitch" class="switch">
                                <input type="checkbox" class="switch1" />
                                <span class="slider an-slid"></span>
                              </label>
                            </div>
                            <div class="month-year-inner-an">
                              <h5 class="ms-2">Yearly</h5>
                            </div>
                          </div>
                          <div class="ord-customer-an-btm d-flex justify-content-end mt-2">
                            <img src={orderCustomer} alt="" />
                          </div>
                        </div>
                      </div>
                      {/* <!-- row nested  --> */}
                      <div class="graph-btm-parent d-flex justify-content-center">
                        <img src={graph3} alt="" />
                        <div style={{ width: 700 }}>
                          {/* <BarChart redraw={true} chartData={userData} /> */}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Analytic;

{
  /* <!-- Latest compiled JavaScript -->
    <script src="https://cdn.jsdelivr.net/npm/bootstrap@5.2.3/dist/js/bootstrap.bundle.min.js"></script>
    <script src="https://ajax.googleapis.com/ajax/libs/jquery/3.6.4/jquery.min.js"></script>
    <script src="assets/js/custom.js"></script>
    <script src="https://kit.fontawesome.com/a076d05399.js" crossorigin="anonymous"></script>
    */
}
