import React from 'react'
import UserSidebar from '../../../../components/UserSidebar'
function Update_address() {
  return (
<section className="dasgboard-miansection pb-5">
  {/* Dashboard */}
  {/* dashboard */}
  <div className="user-seller-approved-unapproved-container pb-5">
    <div className="row pt-2">
    <UserSidebar/>
      <div className="col-sm-9 col-md-9 col-lg-9 dashboard-main-content">
        <div className="dashboard-title">
          <h1>ADDRESS</h1>
        </div>
        <div className="row pb-5 my-4 Latest-Order gx-0">
          <div className="col-sm-12 col-md-12 col-lg-12 latest_order">
            <div className="order-heading">
              <div className="row">
                <div className="col-8">
                  <h1>
                    {" "}
                    <span className="icon-shop">
                      {" "}
                      <i className="fa-solid fa-address-book" />{" "}
                    </span>{" "}
                    CHANGE Or UPDATE Address{" "}
                  </h1>
                </div>
                <div className="col-sm-12 col-md-4 col-lg-4">
                  <a
                    href="./change-update-address.html"
                    className="logout Shipping edit update"
                  >
                    EDIT
                  </a>
                </div>
              </div>
            </div>
            <div className="formset pb-5 pr-5">
              <fieldset className="row">
                <div className="col-sm-12 col-md-7 col-lg-7 pt-3 ship">
                  <legend>Shipping Address</legend>
                  <textarea
                    rows={4}
                    cols={50}
                    placeholder="Lorem ipsum dolor sit amet, consetetur sadipscing elitr"
                    defaultValue={""}
                  />
                  <div className="setting-icon-textar">
                    <img
                      src="./assets/images/icon-settings.png"
                      alt=""
                      className="img-fluid"
                    />
                  </div>
                </div>
              </fieldset>
              <fieldset className="row">
                <div className="col-sm-12 col-md-7 col-lg-7 pt-3 ship">
                  <legend>Billing Address</legend>
                  <textarea
                    rows={4}
                    cols={50}
                    placeholder="Lorem ipsum dolor sit amet, consetetur sadipscing elitr"
                    defaultValue={""}
                  />
                  <div className="setting-icon-textar">
                    <img
                      src="./assets/images/icon-settings.png"
                      alt=""
                      className="img-fluid"
                    />
                  </div>
                </div>
              </fieldset>
              <a href="" className="logout">
                Save Changes
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>

  )
}

export default Update_address