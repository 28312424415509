import { Routes, Route, Outlet, Link } from "react-router-dom";
import OfferConsideredTwoSearchlogo from '../../../assets/images/search-logo.png'
import OfferConsideredTwologo from '../../../assets/images/logo.png'
import OfferConsideredTwoXX3Plate from '../../../assets/images/XX-3-plate.png'
import OfferConsideredTwoBtmCardOne from '../../../assets/images/btm-card-one.png'
import OfferConsideredTwoBtmCardTwo from '../../../assets/images/btm-card-two.png'
import { useState } from "react";
import Footer from '../../../components/Footer'
import { baseUrl } from '../../../utils/Function';

import Header from '../../../components/Header'
import AnimatedCursor from '../../../components/AnimatedCursor'
import { useParams } from 'react-router-dom'    
import { useQuery, useMutation, gql } from '@apollo/client';

const GET_LISTING_PLATE = gql`
  query GetListingPlate($id: Int!) {
    plate(findPlateInput: { id: $id }) {
      askingPrice
      combination
      comments
      numberPlate
      id
    }
  }
  
`;


const CREATE_OFFER = gql`
  mutation CreateOffer($plateListingId: Int!, $price: Float!) {
    createOffer(data: { plateListingId: $plateListingId, price: $price }) {
      counter
      currentOffer
      id
      price
      status
    }
  }
`;

// const baseUrl = "http://192.168.18.8:5000/";



function OfferConsideredThree() {


    const [price, setPrice] = useState('');

    const [createOffer, { loading, error }] = useMutation(CREATE_OFFER, {
        onError: (error) => {
            console.log('Mutation error:', error);
        },
        onCompleted: (data) => {
            console.log('Mutation completed:', data);
            // Perform any additional logic or redirect after successful mutation
        },
    });

    const handleSubmit = (event) => {
        event.preventDefault();

        // Call the createOffer mutation
        createOffer({ variables: { plateListingId: 4, price: parseFloat(price) } });
    };

    const { id } = useParams();

    const { loading2, error2, data } = useQuery(GET_LISTING_PLATE, {
        variables: { id: parseInt(id) },
        
      });

    return (
        <>
        <AnimatedCursor/>
            <div className="main-scroll-bar">
                <header className="header-bg">
                    <Header/>
                    <section className="top_banner">
                        <div className="Web-Container">
                            <div className="grid-row contactpage">
                                <div className="grid-col-2">
                                    <div className="contentBox">
                                        <h1>
                                           {data?.plate?.combination}
                                        </h1>
                                        <p>
                                            {data?.plate?.comments}
                                        </p>
                                    </div>
                                </div>
                                <div className="grid-col-2"></div>
                            </div>
                        </div>
                    </section>
                </header>
                <div className="main_contact-page">
                    <section className="bid-main-sec">
                        <div className="Web-Container">
                            <div className="bid-parent">
                                <div className="bid-grid-main">
                                    <div className="bid-inner-grid-content">
                                        <div className="prent-left-grid">
                                            <div className="main-left-bid-grid">
                                                <div className="serch-icn-cont">
                                                    <img src={OfferConsideredTwoSearchlogo} alt="" />
                                                </div>
                                                <div className="logo-inner-card">
                                                    <img src={OfferConsideredTwologo} alt="" />
                                                </div>
                                                <div className="plate-bid-card">
                                                    <img src={`${baseUrl}${data?.plate?.numberPlate}`} alt="" />
                                                </div>
                                                {console.log(data?.plate?.numberPlate)}
                                                <div className="text-bid-card">
                                                    <p style={{color:"white",padding:"20px"}}>
                                                        {data?.plate?.comments}
                                                    </p>
                                                </div>
                                            </div>
                                            <div className="btm-card-img">
                                                <div className="btm-card-inner">
                                                    <div className="btm-img">
                                                        <img src={OfferConsideredTwoBtmCardOne} alt="" />
                                                    </div>
                                                    <div className="btm-img">
                                                        <img src={OfferConsideredTwoBtmCardTwo} alt="" />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="bid-inner-grid-content">
                                        <div className="top-txt-rightbid">
                                            <h2 className="fw-400">Offer considered</h2>
                                            <h4 className="fw-400 color-fff pd-h4-custom">
                                                Current Bid : {data?.plate?.askingPrice}
                                            </h4>
                                        </div>
                                        <div className="btm-txt-right-col">
                                            <h2 className="para-askprice-one">
                                            you need to be verified first with a registered account to be
                                            able to visit this page.
                                            </h2>
                                        </div>
                                        <div className="anchor-ask-price-parent">
                                            <div className="anchor-ask-price-inner">
                                                <Link to="/">BACK TO HOME</Link>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                    <Footer/>
                </div>
            </div>

        </>
    )
    
}

export default OfferConsideredThree