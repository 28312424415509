import React, { useState } from 'react';
import Footer from '../../../components/Footer';
import Header from '../../../components/Header';
import AnimatedCursor from '../../../components/AnimatedCursor';

function FAQ() {
  const [activeAccordion1, setActiveAccordion1] = useState(null);
  const [activeAccordion2, setActiveAccordion2] = useState(null);
  const [activeAccordion3, setActiveAccordion3] = useState(null);

  const handleAccordionClick1 = (index) => {
    setActiveAccordion1(activeAccordion1 === index ? null : index);
  };

  const handleAccordionClick2 = (index) => {
    setActiveAccordion2(activeAccordion2 === index ? null : index);
  };

  const handleAccordionClick3 = (index) => {
    setActiveAccordion3(activeAccordion3 === index ? null : index);
  };
  const faqItems = [
    {
      question: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit?',
      answer: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Elementum sagittis vitae et leo duis ut. Ut tortor pretium viverra suspendisse potenti.',
    },
    {
        question: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit?',
        answer: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Elementum sagittis vitae et leo duis ut. Ut tortor pretium viverra suspendisse potenti.',
      },
      {
        question: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit?',
        answer: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Elementum sagittis vitae et leo duis ut. Ut tortor pretium viverra suspendisse potenti.',
      },
      {
        question: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit?',
        answer: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Elementum sagittis vitae et leo duis ut. Ut tortor pretium viverra suspendisse potenti.',
      },
      {
        question: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit?',
        answer: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Elementum sagittis vitae et leo duis ut. Ut tortor pretium viverra suspendisse potenti.',
      },
    ]
      const faqItems2 = [
        {
          question: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit?',
          answer: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Elementum sagittis vitae et leo duis ut. Ut tortor pretium viverra suspendisse potenti.',
        },
        {
            question: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit?',
            answer: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Elementum sagittis vitae et leo duis ut. Ut tortor pretium viverra suspendisse potenti.',
          },
          {
            question: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit?',
            answer: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Elementum sagittis vitae et leo duis ut. Ut tortor pretium viverra suspendisse potenti.',
          },
          {
            question: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit?',
            answer: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Elementum sagittis vitae et leo duis ut. Ut tortor pretium viverra suspendisse potenti.',
          },
          {
            question: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit?',
            answer: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Elementum sagittis vitae et leo duis ut. Ut tortor pretium viverra suspendisse potenti.',
          },
        ]
          const faqItems3 = [
            {
              question: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit?',
              answer: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Elementum sagittis vitae et leo duis ut. Ut tortor pretium viverra suspendisse potenti.',
            },
            {
                question: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit?',
                answer: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Elementum sagittis vitae et leo duis ut. Ut tortor pretium viverra suspendisse potenti.',
              },
              {
                question: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit?',
                answer: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Elementum sagittis vitae et leo duis ut. Ut tortor pretium viverra suspendisse potenti.',
              },
              {
                question: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit?',
                answer: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Elementum sagittis vitae et leo duis ut. Ut tortor pretium viverra suspendisse potenti.',
              },
              {
                question: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit?',
                answer: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Elementum sagittis vitae et leo duis ut. Ut tortor pretium viverra suspendisse potenti.',
              },
    // Add more FAQ items as needed
  ];

  return (
    <>
      <AnimatedCursor/>
      <div className="main-scroll-bar">
        <header className="header-bg">
          <Header />
          <section className="top_banner">
            <div className="container">
              <div className="grid-row auction-grid">
                <div className="grid-col-2">
                  <div className="contentBox content leFadeIn">
                    <h1 className="leFadeIn" data-aos="fade-right" data-aos-duration="1700">FAQ</h1>
                    <p data-aos="fade-right" data-aos-duration="1800">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nam convallis rhoncus eros, nec scelerisque nisi.</p>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </header>
        <section className="faq-sec">
        <div className="container">
          <div className="accordion">
            <div className="gnrl-faq-grid accordion-item">
              <div className="gnrl-heading">
                <h1 data-aos="fade-right" data-aos-duration="1500" style={{ color: 'white' }}>General</h1>
              </div>
              <div data-aos="fade-right" data-aos-duration="1500">
                {faqItems.map((item, index) => (
                  <React.Fragment key={index}>
                    <button
                      id={`accordion-button-${index}`}
                      aria-expanded={activeAccordion1 === index}
                      onClick={() => handleAccordionClick1(index)}
                    >
                      <span className="accordion-title">{item.question}</span>
                      <span className="icon" aria-hidden="true"></span>
                    </button>
                    {activeAccordion1 === index && (
                      <div className="accordion-content">
                        <p>{item.answer}</p>
                      </div>
                    )}
                  </React.Fragment>
                ))}
              </div>
            </div>
            <div className="gnrl-faq-grid accordion-item">
              <div className="gnrl-heading">
                <h1 data-aos="fade-right" data-aos-duration="1500" style={{ color: 'white' }}>Buying</h1>
              </div>
              <div data-aos="fade-right" data-aos-duration="1500">
                {faqItems2.map((item, index) => (
                  <React.Fragment key={index}>
                    <button
                      id={`accordion-button-${index}`}
                      aria-expanded={activeAccordion2 === index}
                      onClick={() => handleAccordionClick2(index)}
                    >
                      <span className="accordion-title">{item.question}</span>
                      <span className="icon" aria-hidden="true"></span>
                    </button>
                    {activeAccordion2 === index && (
                      <div className="accordion-content">
                        <p>{item.answer}</p>
                      </div>      
                    )}
                  </React.Fragment>
                ))}
              </div>
            </div>
            <div className="gnrl-faq-grid accordion-item">
              <div className="gnrl-heading">
                <h1 data-aos="fade-right" data-aos-duration="1500" style={{ color: 'white' }}>Selling</h1>
              </div>
              <div data-aos="fade-right" data-aos-duration="1500">
                {faqItems3.map((item, index) => (
                  <React.Fragment key={index}>
                    <button
                      id={`accordion-button-${index}`}
                      aria-expanded={activeAccordion3 === index}
                      onClick={() => handleAccordionClick3(index)}
                    >
                      <span className="accordion-title">{item.question}</span>
                      <span className="icon" aria-hidden="true"></span>
                    </button>
                    {activeAccordion3 === index && (
                      <div className="accordion-content">
                        <p>{item.answer}</p>
                      </div>
                    )}
                  </React.Fragment>
                ))}
              </div>
            </div>
          </div>
        </div>
      </section>
        <Footer />
      </div>
    </>
  );
}

export default FAQ;