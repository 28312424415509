import React from "react";
import { Link, useLocation } from "react-router-dom";
import AnimatedCursor from "../../components/AnimatedCursor";
import Header from "../../components/Header";
import axios from "axios";
import ForgetGroup from "../../assets/images/_Group_.png";
import './style.css'
import { useNavigate } from 'react-router-dom';
import { useState } from "react";
import { baseUrl } from "../../utils/Function";

function ForgetPassword() {

  const [email, setEmail] = useState(""); // State to store the email input
  const navigate = useNavigate();
  const handleEmailChange = (event) => {
    setEmail(event.target.value);
  };


  const handleSubmit = async () => {


    // const axios = require('axios');
    let data = JSON.stringify({
      "email": email
    });
    let config = {
      method: 'post',
      maxBodyLength: Infinity,
      url: `${baseUrl}auth/forget-password`,
      headers: {
        'Content-Type': 'application/json'
      },
      data: data
    };
    axios.request(config)
      .then((response) => {
        console.log(JSON.stringify(response.data));

      })
      .catch((error) => {
        console.log(error);
      });
    navigate("/VerifyLogin", { state: { email } })
  };

  return (
    <>
      <AnimatedCursor />
      <div className="main-scroll-bar login-page set-height-login-back">
        <Header />

        <section className="login-sect">
          <div className="container">
            <div className="form-box">
              <div className="form-border-box set-frgt-frm-box">
                <div className="icn hide">
                  <i className="fa-solid fa-xmark"></i>
                </div>
                <div className="form-img set-frgt-img">
                <Link to="/LoginApiTest" className="rght-bk-arrow">
                <i className="fa-solid fa-rectangle-xmark"></i>
                  </Link>
                  <img src={ForgetGroup} alt="" />
                </div>
                {/* <div className="frgt-vrfy-txt-main">
                  <h3>VERIFY YOUR ACCOUNT</h3>
                  <p>We will send a code to your email</p>
                </div> */}
                <div className="frgt-email-id-feild">
             
                  <input type="email" value={email} onChange={handleEmailChange} placeholder="Enter Email Address" />
                </div>
                <div className="sbmt-back-parent">
              
                  <div className="vrfy-sbmt-btn">
                    <button onClick={handleSubmit}>Send Reset Password Code</button>
                  </div>
                </div>
                <div className="form-img-txt">
                  <img src={ForgetGroup} alt="" />
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    height="1em"
                    viewBox="0 0 512 512"
                  >
                    <path d="M9.4 233.4c-12.5 12.5-12.5 32.8 0 45.3l128 128c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L109.3 288 480 288c17.7 0 32-14.3 32-32s-14.3-32-32-32l-370.7 0 73.4-73.4c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0l-128 128z" />
                  </svg>
                  <Link to="/">Go to Signature +</Link>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </>
  );
}

export default ForgetPassword;