import React from 'react'

function Offersix() {
  return (
<section className="dasgboard-miansection">
  <div className="container">
    <div className="row pt-2">
      <div className="col-sm-3 col-md-3 col-lg-3">
        <div id="wrapper">
          <div className="navbar-header">
            <a href="#" className="navbar-brand" id="sidebar-toggle">
              <i className="fa fa-bars" />
            </a>
          </div>
          <aside id="sidebar-wrapper">
            <div className="logo-img">
              <img src="./asstes/images/logoas.png" alt="" />
            </div>
            <div className="profile-img">
              <div className="avatar-upload">
                <div className="avatar-edit">
                  <input
                    type="file"
                    id="imageUpload"
                    accept=".png, .jpg, .jpeg"
                  />
                  <label htmlFor="imageUpload" />
                </div>
                <div className="avatar-preview">
                  <div
                    id="imagePreview"
                    style={{
                      backgroundImage: "url(./asstes/images/profileas.png)"
                    }}
                  ></div>
                </div>
              </div>
            </div>
            <ul className="sidebar-nav">
              <li>
                <a href="../index.html">
                  <i className="fa-solid fa-list-ul" />
                  Dashboard
                </a>
              </li>
              <li>
                <a href="../profile.html">
                  <i className="fa-solid fa-user" />
                  Profile
                </a>
              </li>
              <li>
                <a href="../account-security.html">
                  <i className="fa-solid fa-shield-halved" />
                  Account Security
                </a>
              </li>
              <li>
                <a href="../address.html">
                  <i className="fa-solid fa-address-book" />
                  Address
                </a>
              </li>
              <li>
                <a href="../payment.html">
                  <i className="fa-solid fa-credit-card" />
                  Payment Method
                </a>
              </li>
              <li>
                <a href="../notification.html">
                  <i className="fa-solid fa-bell" />
                  Notifications
                </a>
              </li>
              <li>
                <a href="../auction.html">
                  <i className="fa-solid fa-gavel" />
                  Auction
                </a>
              </li>
              <li className="active">
                <a href="../offerone.html">
                  <i className="fa-solid fa-location-arrow" />
                  Offer
                </a>
              </li>
              <div className="nick">
                <h2>
                  {" "}
                  <span className="pen-icon">
                    {" "}
                    <i className="fa-regular fa-circle-dot" />{" "}
                  </span>{" "}
                  Nick parker
                  <br /> <span className="jobname">Seller</span>
                </h2>
                <a href="../login.html" className="logout">
                  Logout
                </a>
              </div>
            </ul>
          </aside>
        </div>
      </div>
      <div className="col-sm-9 col-md-9 col-lg-9 dashboard-main-content">
        <div className="dashboard-title">
          <div className="sllermain-title">
            <h1>DASHBOARD</h1>
            <div className="sidebar-brand">
              <h2 id="formButton" onclick="location.href='../offerone.html';">
                Switch to buyer{" "}
                <i className="fa fa-refresh" aria-hidden="true" />
              </h2>
            </div>
          </div>
        </div>
        <div className="row my-4 Latest-Order gx-0">
          <div className="col-10 latest_order">
            <div className="order-heading">
              <h1>
                {" "}
                <span className="icon-shop">
                  {" "}
                  <i className="fa-solid fa-location-arrow" />{" "}
                </span>
                Offer
              </h1>
            </div>
            <div className="tabmake offersixlist">
              <fieldset className="row py-4">
                <div className="col-sm-12 col-md-8 col-lg-8 offersixinpt">
                  <label htmlFor="">Amount</label>
                  <span className="btnn">$</span>
                  <input
                    className="form-input"
                    type="text"
                    id="fname"
                    name="fname"
                  />
                </div>
              </fieldset>
              <a href="" className="logout submit">
                Submit
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>

  )
}

export default Offersix