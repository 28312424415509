import React, { useState, useEffect } from "react";
import SideBar from "../../../components/Sidebar";
import UserLogoDale from "../../../assets/images/logodale.png";
import UserCartSvg1 from "../../../assets/images/cart.svg";
import UserLostIcnSvg2 from "../../../assets/images/lost-icon.svg";
import UserEyeIcnSvg3 from "../../../assets/images/eye-icon.svg";
import UserWonIcnSvg4 from "../../../assets/images/wonicon.svg";
import UserAuctionIcnSvg5 from "../../../assets/images/auction.svg";
import UserSecCardWinIcnSvg6 from "../../../assets/images/sec-card-win.svg";
import UserThirdThumbDownSvg7 from "../../../assets/images/Third-thumb-down.svg";
import UserWatchListSvg8 from "../../../assets/images/watchlist.svg";
import UserTabsicnSvg1 from "../../../assets/images/icon1.svg";
import UserTabsProfilepng2 from "../../../assets/images/profile.png";
import UserTabsSecuritySvg2 from "../../../assets/images/security.svg";
import UserTabsAddressBookSvg2 from "../../../assets/images/address-book.svg";
import UserTabsPaymentSvg2 from "../../../assets/images/payment.svg";
import UserTabsNotificationSvg2 from "../../../assets/images/notifications.svg";
import UserTabsHammerSvg2 from "../../../assets/images/hammer.svg";
import UserTabsOfferSvg2 from "../../../assets/images/offer.svg";
import UserBoxIcnMaterialSecurity from "../../../assets/images/Icon-material-security.png";
import UserBoxIcnAwesomeAddressBook from "../../../assets/images/Icon-awesome-address-book.png";
import UserBoxIcnMaterialPayment from "../../../assets/images/Icon-material-payment.png";
import UserBoxIcnIonicIosNotification from "../../../assets/images/Icon-ionic-ios-notifications.png";
import UserBoxIcnMetroHammer from "../../../assets/images/Icon-metro-hammer.png";
import UserMaskGroup1 from "../../../assets/images/Mask-Group-1.png";
import UserLayer1 from "../../../assets/images/Layer-2.png";
import UserIcnAwesomeCrown from "../../../assets/images/Icon-awesome-crown.png";
import UserIcnMaterialLocalOffer from "../../../assets/images/Icon-material-local-offer.png";
import UserIcnAwesomeAddressBookpng from "../../../assets/images/Icon-awesome-address-book.png";
import UserIcnIonicIosSettingspng from "../../../assets/images/Icon-ionic-ios-settings.png";
import "../../../../node_modules/bootstrap/js/dist/tab";
import Modal from "react-modal";
import Switch from "react-switch";
import { useParams } from "react-router-dom";
import { gql } from "@apollo/client";
import { useQuery, useMutation } from "@apollo/client";
import Swal from "sweetalert2";
import { baseUrl, baseUrl2 } from "../../../utils/Function";
import UserSidebar from "../../../components/UserSidebar";

const GET_USERS_QUERY = gql`
  query user($id: Int!) {
    user(where: { id: $id }) {
      id
      username
      email
      drivingLicense
      firstName
      lastName
      city
      country
      companyName
      streetAddress
      city
      phoneNumber
      postCode
      state
      accountNumber
      accountName
    }
  }
`;

const UPDATE_USER_MUTATION = gql`
  mutation UpdateUser($data: UpdateUserInput!) {
    updateUser(data: $data) {
      username
    }
  }
`;
const GET_USER_ACTIVITIES = gql`
  query {
    me {
      activities {
        id
        createdAt
        mode
        type
        user {
          username
          status
        }
      }
    }
  }
`;

const customStyles = {
  content: {
    top: "60%",
    left: "60%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    backgroundColor: "transparent",
    border: "none",
    width: "60%",
  },
};

function UserDashBoard() {
  const [isTwoFactorEnable, setIsTwoFactorEnable] = useState(false);
  const Swal = require("sweetalert2");
  const [updateUser] = useMutation(UPDATE_USER_MUTATION);
  const handleSubmit = (event) => {
    event.preventDefault();
    const formData = {
      streetAddress: event.target.streetAddress.value,
    };
    updateUser({
      variables: {
        data: formData,
      },
    })
      .then((result) => {
        Swal.fire("Address Updated Successfully.");
        console.log("User updated:", result.data.updateUser);
        // Handle success or show a success message
      })
      .catch((error) => {
        console.error("Error updating user:", error);
        // Handle error or show an error message
      });
  };

  const [isReqVerificationModal, setIsReqVerificationModal] = useState(false);
  const [addingShipAddOrBillAdd, setAddingShipAddOrBillAdd] = useState("");
  const [addingAddressDetail, setAddingAddressDetail] = useState("");
  const [addpaymentdetail, setAddPaymentDetail] = useState("");
  const [addcardcounterapproved, setCardCounterApproved] = useState("");
  const [addcounterinneroffer, setCardCounterInnerOffer] = useState("");
  const onReqVerification = () => {
    setIsReqVerificationModal((prev) => !prev);
  };

  const { id } = useParams();
  const { loading, error, data } = useQuery(GET_USERS_QUERY, {
    variables: { id: parseInt(id) },
  });

  if (loading) return <div>Loading...</div>;
  if (error) return <div>Error: {error.message}</div>;

  function formatDateTime(dateTime) {
    const options = {
      year: "numeric",
      month: "long",
      day: "numeric",
      hour: "numeric",
      minute: "numeric",
      hour12: true,
    };

    return new Date(dateTime).toLocaleString("en-US", options);
  }

  const user = data?.user;
  return (
    <>
      <Modal
        isOpen={isReqVerificationModal}
        onRequestClose={onReqVerification}
        style={customStyles}
        // contentLabel="Example Modal"
      >
        <div className={`request-verification-parent`}>
          <div className="request-verification-code-parent">
            <div className="request-verification-box">
              <div className="request-verificaton-box-txt-flex">
                <img src="assets/images/Icon-material-person.png" alt="" />
                <h2>REQUEST VERIFICATION</h2>
              </div>
              <form action="">
                <div className="request-verification-form-parent">
                  <div className="row request-verification-input">
                    <div className="col-lg-6">
                      <div className="request-verify-label-flex">
                        <label for="">First Name</label>
                        <span>*</span>
                      </div>
                      <input type="fname" defaultValue={user?.firstName} />
                    </div>
                    <div className="col-lg-6">
                      <div className="request-verify-label-flex">
                        <label for="">Last Name</label>
                        <span>*</span>
                      </div>
                      <input type="fname" defaultValue={user?.lastName} />
                    </div>
                  </div>
                  <div className="row request-verification-input">
                    <div className="col-lg-6">
                      <div className="request-verify-label-flex">
                        <label for="">Phone</label>
                        <span>*</span>
                      </div>
                      <input type="fname" defaultValue={user?.phoneNumber} />
                    </div>
                    <div className="col-lg-6">
                      <div className="request-verify-label-flex">
                        <label for="">Email</label>
                        <span>*</span>
                      </div>
                      <input type="fname" defaultValue={user?.email} />
                    </div>
                  </div>
                  <div className="row request-verification-input">
                    <div className="col-lg-6">
                      <div className="request-verify-label-flex">
                        <label for="">Company Name</label>
                        <span>*</span>
                      </div>
                      <input type="fname" defaultValue={user?.companyName} />
                    </div>
                  </div>
                  <div className="row request-verification-input">
                    <div className="col-lg-6">
                      <div className="request-verify-label-flex">
                        <label for="">Street Address</label>
                        <span>*</span>
                      </div>
                      <input type="fname" defaultValue={user?.streetAddress} />
                    </div>
                  </div>
                  <div className="row request-verification-input">
                    <div className="col-lg-6">
                      <div className="request-verify-label-flex">
                        <label for="">Post Code</label>
                      </div>
                      <input type="fname" defaultValue={user?.postCode} />
                    </div>
                    <div className="col-lg-6">
                      <div className="request-verify-label-flex">
                        <label for="">State</label>
                        <span>*</span>
                      </div>
                      <input type="fname" defaultValue={user?.state} />
                    </div>
                  </div>

                  <div className="row sett-the-verification-sec-para">
                    <div className="col-lg-6">
                      <p>
                        Please provide a copy of your Driver Licence so that
                        <br />
                        we can verify your identity for our live bidding
                        <br />
                        platform.
                      </p>
                    </div>
                    <div className="col-lg-6">
                      <p>
                        If you have an updated address label, please provide a
                        photo of the back of the licence.
                      </p>
                    </div>
                  </div>
                  <div className="request-verify-done-btn">
                    <button
                      className="move-to-previous-verification-code"
                      onClick={onReqVerification}
                    >
                      Request Verification
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </Modal>
      <div className="bg-dashboard">
        <main id="main">
          <UserSidebar />

          <div className="dashboard-right-side sett-responsive-ride-side">
            <div className="page-title usr-heading">
              <h1>User Dashboard</h1>
            </div>

            <div className="parent-profile-form">
              <div className="profile-box-parent">
                <div className="profile-edit-text">
                  <h2>Edit Profile</h2>
                </div>
                <form>
                  <div className="formset pr-5">
                    <fieldset className="row">
                      <div className="col-sm-12 col-md-6 col-lg-6 pt-3">
                        <legend>First Name</legend>
                        <input
                          className="form-input restrict-numbers"
                          type="text"
                          id="fname"
                          name="fname"
                          defaultValue={user?.firstName}
                          readOnly
                        />
                      </div>
                      <div className="col-sm-12 col-md-6 col-lg-6 pt-3">
                        <legend>Last Name</legend>
                        <input
                          className="form-input restrict-numbers"
                          type="text"
                          id="fname"
                          name="fname"
                          defaultValue={user?.lastName}
                          readOnly
                        />
                      </div>
                    </fieldset>
                    <fieldset className="row">
                      <div className="col-sm-12 col-md-6 col-lg-6 pt-3">
                        <legend>Phone</legend>
                        <input
                          className="form-input phoneNumber"
                          type="tell"
                          id="fname"
                          name="fname"
                          minLength={11}
                          maxLength={11}
                          size={11}
                          required=""
                          defaultValue={user?.phoneNumber}
                          readOnly
                        />
                      </div>
                      <div className="col-sm-12 col-md-6 col-lg-6 pt-3">
                        <legend>Email:</legend>
                        <input
                          className="form-input"
                          type="email"
                          id="fname"
                          name="fname"
                          defaultValue={user?.email}
                          readOnly
                        />
                      </div>
                    </fieldset>
                    <fieldset className="row">
                      <div className="col-sm-12 col-md-6 col-lg-6 pt-3">
                        <legend>Street Address</legend>
                        <input
                          className="form-input"
                          type="text"
                          id="fname"
                          name="fname"
                          defaultValue={user?.streetAddress}
                          readOnly
                        />
                      </div>
                      <div className="col-sm-12 col-md-6 col-lg-6 pt-3">
                        <legend>Company Name</legend>
                        <input
                          className="form-input"
                          type="text"
                          id="fname"
                          name="fname"
                          defaultValue={user?.companyName}
                          readOnly
                        />
                      </div>
                    </fieldset>
                    <fieldset className="row">
                      <div className="col-sm-12 col-md-6 col-lg-6 pt-3">
                        <legend>Post Code</legend>
                        <input
                          className="form-input phoneNumber"
                          type="text"
                          id="fname"
                          name="fname"
                          defaultValue={user?.postCode}
                          readOnly
                        />
                      </div>
                      <div className="col-sm-12 col-md-6 col-lg-6 pt-3">
                        <legend>State</legend>
                        <input
                          className="form-input"
                          type="text"
                          id="fname"
                          name="fname"
                          defaultValue={user?.state}
                          readOnly
                        />
                      </div>
                      <div className="col-sm-12 col-md-6 col-lg-6 pt-3">
                        <legend>Account Name</legend>
                        <input
                          className="form-input"
                          type="text"
                          id="accname"
                          name="accname"
                          defaultValue={user?.accountName}
                          readOnly
                        />
                      </div>
                      <div className="col-sm-12 col-md-6 col-lg-6 pt-3">
                        <legend>Account Number</legend>
                        <input
                          className="form-input"
                          type="text"
                          id="accNo"
                          name="accNo"
                          defaultValue={user?.accountNumber}
                          readOnly
                        />
                      </div>
                    </fieldset>
                    <div>
                      <img src={`${baseUrl}${user?.drivingLicense}`} />
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </main>
      </div>
    </>
  );
}

export default UserDashBoard;
