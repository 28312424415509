import { React, useState } from "react";
import LogoDaleSig from "../assets/images/logodale.png";
import DaleIconSvg1 from "../assets/images/icon1.svg";
import DaleIconSvg2 from "../assets/images/icon2.svg";
import DaleIconSvg3 from "../assets/images/icon3.svg";
import DaleIconSvg4 from "../assets/images/icon4.svg";
import DaleIconSvg5 from "../assets/images/icon5.svg";
import DaleIconSvg6 from "../assets/images/icon6.svg";
import DaleIconSvg7 from "../assets/images/icon7.svg";
import DaleIconSvg9 from "../assets/images/icon9.svg";
import DaleIconSvg10 from "../assets/images/icon10.svg";
import DaleIconSvg11 from "../assets/images/icon11.svg";
import DaleSpan5 from "../assets/images/span-5.png";
import RegisterAdminDashboardGroup1 from "../assets/images/_Group_.png";
import { Link, useLocation } from "react-router-dom";

import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { logoutUser } from "../redux/slices/userSlice";
import { logoutWishlist } from "../redux/slices/wishlistSlice";
import axios from "axios";
import { useQuery, gql } from "@apollo/client";
import { baseUrl2 } from "../utils/Function";
import Swal from "sweetalert2";

const QUERY_USER = gql`
  query User($id: Int!) {
    user(where: { id: $id }) {
      id
      avatar
    }
  }
`;
function Sidebar({ userType }) {
  const navigate = useNavigate();
  const location = useLocation();
  const userData = useSelector((state) => state.user.userData);
  const dispatch = useDispatch();
  const [selectedFile, setSelectedFile] = useState(null);
  const ID = userData.userID;

  const { loading, error, data } = useQuery(QUERY_USER, {
    variables: { id: ID },
  });
  // console.log(data, "----chal ja")
  const activeTab = (name) => {
    if (name === location.pathname) {
      return "active-side";
    }
  };

  // const handleLogOut = () => {
  //     dispatch(logoutUser());
  //     dispatch(logoutWishlist());
  //     navigate("/");
  //   };

  const handleFileChange = (event) => {
    const selectedFile = event.target.files[0];

    if (selectedFile) {
      const formData = new FormData();
      formData.append("avatar", selectedFile);
      const config = {
        method: "put",
        maxBodyLength: Infinity,
        url: "https://signatureapi.institu-tech.com/users",
        headers: {
          Authorization: `Bearer ${userData?.accessToken}`, // Replace with your actual access token
        },
        data: formData,
      };

      axios
        .request(config)
        .then((response) => {
          Swal.fire(
            "Profile Picture is Updated!",
            "Kindly Reload Your Page",
            "success"
          );
        })
        .catch((error) => {
          console.log(error);
        });
    } else {
      console.log("No file selected.");
    }
  };

  const handleLogout = () => {
    dispatch(logoutUser());
    dispatch(logoutWishlist());
    navigate("/");
  };

  return (
    <>
      <div className="dashboard-left-side">
        <div className="logo-dale">
          <Link to="/">
            <img src={LogoDaleSig} alt="" />
          </Link>
        </div>
        <div className="img-uploder">
          <div className="avatar-upload">
            <div className="avatar-edit">
              <input
                type="file"
                id="imageUpload"
                accept=".png, .jpg, .jpeg"
                onChange={handleFileChange}
              />
              <label htmlFor="imageUpload"></label>
            </div>
            <div className="avatar-preview">
              <img
                src={`${baseUrl2}${data?.user?.avatar}`}
                alt="Avatar"
                class="avatar"
              ></img>
            </div>
            <Link to="/EditProfile"></Link>
          </div>
        </div>
        <div className="main-menus">
          <ul>
            <li
              className={
                location.pathname === "/MainDashboard" ? "active-side" : ""
              }
              aria-current="page"
            >
              <Link to="/MainDashboard" className="" aria-current="page">
                <span>
                  <img src={DaleIconSvg1} alt="" />
                </span>
                Dashboard
              </Link>
            </li>
            <li
              className={
                location.pathname === "/FormRequest" ? "active-side" : ""
              }
              aria-current="page"
            >
              <Link to="/FormRequest" className="" aria-current="page">
                <span>
                  <img src={DaleIconSvg2} alt="" />
                </span>
                Forms Request
                <span className="opacity-side-5">
                  {/* <img src={DaleSpan5} alt="" /> */}
                </span>
              </Link>
            </li>
            <li
              className={location.pathname === "/customer" ? "active-side" : ""}
              aria-current="page"
            >
              <Link to="/customer" className="" aria-current="page">
                <span>
                  <img src={DaleIconSvg3} alt="" />
                </span>
                Customers
              </Link>
            </li>
            <li
              className={
                location.pathname === "/Plates_recieved" ? "active-side" : ""
              }
              aria-current="page"
            >
              <Link to="/Plates_recieved" className="" aria-current="page">
                <span>
                  <img src={DaleIconSvg4} alt="" />
                </span>
                Plates Received
                <span className="opacity-side-5"></span>
              </Link>
            </li>
            <li
              className={
                location.pathname === "/BidsReceived" ? "active-side" : ""
              }
              aria-current="page"
            >
              <Link to="/BidsReceived" className="" aria-current="page">
                <span>
                  <img src={DaleIconSvg5} alt="" />
                </span>
                Bids Received
                <span className=" opacity-side-5">
                  {/* <img src={DaleSpan5} alt="" /> */}
                </span>
              </Link>
            </li>
            <li
              className={location.pathname === "/BidsAct" ? "active-side" : ""}
              aria-current="page"
            >
              <Link to="/BidsAct" className="" aria-current="page">
                <span>
                  <img src={DaleIconSvg6} alt="" />
                </span>
                Bids Activity Log
              </Link>
            </li>
            <li
              className={location.pathname === "/Payment" ? "active-side" : ""}
              aria-current="page"
            >
              <Link to="/Payment" className="" aria-current="page">
                <span>
                  <img src={DaleIconSvg7} alt="" />
                </span>
                Payments
              </Link>
            </li>
            <li
              className={location.pathname === "/Reports" ? "active-side" : ""}
              aria-current="page"
            >
              <Link to="/Reports" className="" aria-current="page">
                <span>
                  <img src={DaleIconSvg9} alt="" />
                </span>
                Reports
              </Link>
            </li>
            <li
              className={location.pathname === "/Analytic" ? "active-side" : ""}
              aria-current="page"
            >
              <Link to="/Analytic" className="" aria-current="page">
                <span>
                  <img src={DaleIconSvg10} alt="" />
                </span>
                Analytics
              </Link>
            </li>
            <li
              className={
                location.pathname === "/Winner_list" ? "active-side" : ""
              }
              aria-current="page"
            >
              <Link to="/Winner_list" className="" aria-current="page">
                <span>
                  <img src={DaleIconSvg11} alt="" />
                </span>
                Winner List
              </Link>
            </li>
          </ul>
        </div>
        <Link onClick={handleLogout} className="logout">
          Logout
        </Link>
        <div className="form-img-txt signup-form-got-to">
          <img src={RegisterAdminDashboardGroup1} alt="" />
          <svg
            xmlns="http://www.w3.org/2000/svg"
            height="1em"
            viewBox="0 0 512 512"
          >
            <path d="M9.4 233.4c-12.5 12.5-12.5 32.8 0 45.3l128 128c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L109.3 288 480 288c17.7 0 32-14.3 32-32s-14.3-32-32-32l-370.7 0 73.4-73.4c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0l-128 128z" />
          </svg>
          <Link to="/">Go to Signature +</Link>
        </div>
      </div>
    </>
  );
}

export default Sidebar;
