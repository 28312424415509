import React from 'react'
import UserSidebar from '../../../../components/UserSidebar'
function Offerfour() {
  return (
<section className="dasgboard-miansection">
  {/* Dashboard */}
  {/* dashboard */}
  <div className="container">
    <div className="row pt-2">
<UserSidebar/>
      <div className="col-sm-9 col-md-9 col-lg-9 dashboard-main-content">
        <div className="dashboard-title">
          <div className="sllermain-title">
            <h1>DASHBOARD</h1>
            <div className="sidebar-brand">
              <h2 id="formButton" onclick="location.href='../offerone.html';">
                Switch to buyer{" "}
                <i className="fa fa-refresh" aria-hidden="true" />
              
              </h2>
            </div>
          </div>
        </div>
        <div className="row my-4 Latest-Order gx-0">
          <div className="col-10 latest_order">
            <div className="order-heading">
              <h1>
                {" "}
                <span className="icon-shop">
                  {" "}
                  <i className="fa-solid fa-location-arrow" />{" "}
                </span>
                Offer
              </h1>
            </div>
            <div className="tabmake">
              <div className="orderhead auction-table">
         
                <ul className="order-five auction-table-body">
                  <li> VIC XX - 3 </li>
                  <li> Lorem ipsum dolor sit amet, consetetur</li>
                  <li> - </li>
                  <li>
                    {" "}
                    <i
                      className="fa-regular fa-eye"
                      onclick="location.href='./offerfive.html';"
                    />{" "}
                  </li>
                </ul>
                <ul
                  className="order-five auction-table-body vichover"
                  style={{ backgroundColor: "#EBEBEB" }}
                >
                  <li> VIC XX - 3 </li>
                  <li> Lorem ipsum dolor sit amet, consetetur</li>
                  <li> - </li>
                  <li>
                    {" "}
                    <i
                      className="fa-regular fa-eye"
                      onclick="location.href='./offerfive.html';"
                    />{" "}
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>

  )
}

export default Offerfour