import React, { useState, useEffect } from "react";
import UserSidebar from "../../../../components/UserSidebar";
import UserBoxIcnMetroHammer from "../../../../assets/images/Icon-metro-hammer.png";
import UserMaskGroup1 from "../../../../assets/images/Mask-Group-1.png";
import UserLayer1 from "../../../../assets/images/Layer-2.png";
import UserIcnAwesomeCrown from "../../../../assets/images/Icon-awesome-crown.png";
import SellerDashboardIcnMetroHammer from "../../../../assets/images/Icon-metro-hammer.png";
import SellerDasboardGroup191 from "../../../../assets/images/Group-191.png";
import "../../../../../node_modules/bootstrap/js/dist/tab";
import Switch from "react-switch";
import { Link } from "react-router-dom";
import { useLazyQuery, useMutation, useQuery } from "@apollo/client";
import { gql } from "@apollo/client";
import CountdownTimer from "../../../../components/Counter";
import AuctionBids from "../../../../components/AuctionBids";
import { useSelector } from "react-redux";
import { RevolvingDot } from "react-loader-spinner";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleXmark } from "@fortawesome/free-solid-svg-icons";

const UPDATE_USER_MUTATION = gql`
  mutation UpdateUser($data: UpdateUserInput!) {
    updateUser(data: $data) {
      mode
    }
  }
`;

const GET_USER_PLATES_QUERY = gql`
  query GetUserPlates {
    me {
      mode
      plates {
        id
        combination
        status
        detail {
          ... on PlateAuction {
            currentBid
            auction {
              endAt
              winner {
                email
              }
              bids {
                id
                price
              }
            }
          }
        }
      }
    }
  }
`;

const GET_ME_PLATES = gql`
  query {
    me {
      plates {
        id
        combination
        status
        detail {
          ... on PlateAuction {
            auction {
              endAt
            }
          }
        }
      }
    }
  }
`;

const GET_AUCTION_PRODUCT = gql`
  query GetAuctionProduct($id: Int!) {
    plate(findPlateInput: { id: $id }) {
      askingPrice
      combination
      id
      detail {
        ... on PlateAuction {
          currentBid
          auction {
            id
            endAt
            bids {
              id
              price
              createdAt
              user {
                firstName
                lastName
                id
                username
              }
            }
          }
        }
        ... on PlateListing {
          id
        }
      }
      status
      comments
      user {
        id
        username
        firstName
        lastName
      }
    }
  }
`;

function Auction() {
  const { userEmail } = useSelector((value) => value.user.userData);
  // console.log("value", userEmail);
  const [isTwoFactorEnable, setIsTwoFactorEnable] = useState(true);
  const [addauctiontable, setAddAuctionTable] = useState("");
  const [activeBids, setActiveBids] = useState([]);
  const [id, setId] = useState();
  const [tabs, setTabs] = useState("");

  const [updateUserMutation] = useMutation(UPDATE_USER_MUTATION);

  const handleToggle = () => {
    setIsTwoFactorEnable((prev) => !prev);
  };

  const [passID, { loading: loading4, error: error4, data: data4 }] =
    useLazyQuery(GET_AUCTION_PRODUCT);

  const triggerMutation = (mode) => {
    const dataToUpdate = {
      mode: mode,
      // Add other fields as needed to update the user
    };
    updateUserMutation({ variables: { data: dataToUpdate } });
    // Execute the mutation
  };

  useEffect(() => {
    triggerMutation(isTwoFactorEnable ? "BUYER" : "SELLER");
    const updatedData = auction.filter((item) => item.status === "IN_AUCTION");
    console.log(updatedData, "---setActiveBids");
    setActiveBids([...updatedData]);
  }, [isTwoFactorEnable]);

  // Fetch the user's plates data using the useQuery hook
  const {
    loading,
    error,
    data,
    refetch: refetch2,
  } = useQuery(GET_USER_PLATES_QUERY, { fetchPolicy: "network-only" });
  const {
    loading: loading3,
    error: error3,
    data: data3,
    refetch,
  } = useQuery(GET_ME_PLATES);
  const plateData = data3?.me?.plates;
  console.log(data, "plateeeee ka data");
  // Use the useMutation hook to execute the mutation

  useEffect(() => {
    refetch();
    refetch2();
  }, [isTwoFactorEnable]);
  // Function to handle switch toggle

  // Function to trigger the mutation with the given mode value

  // Extracting the required data from the query response
  const plateAuctions = data?.me?.plates || [];

  const auction = plateAuctions.filter(
    (item) => item.detail.__typename != "PlateListing"
  );

  // Run the initial mutation on page load or whenever needed

  // if (loading) return <p>Loading...</p>;
  if (error) return <p>Error: {error.message}</p>;

  // const { loading: loading4, error: error4, data: data4 } = useQuery(GET_AUCTION_PRODUCT, {
  //   variables: { id: parseInt(id) },
  // });

  // if (loading) {
  //   return <p>Loading...</p>;
  // }

  // if (error) {
  //   return <p>Error: {error.message}</p>;
  // }

  const handleXmark2 = () => {
    setAddAuctionTable("");
  };

  if (loading3 || loading)
    return (
      <section className="dasgboard-miansection">
        <div className="user-seller-approved-unapproved-container">
          <div className="row pt-2">
            <UserSidebar />
            <div className="col-sm-9 col-md-9 col-lg-9 dashboard-main-content">
              <div className="parent-profile-form">
                <div class="profile-box-parent sett-responsive-auction-box">
                  <div class="parent-auction">
                    <h1 className="text-center">
                      <RevolvingDot
                        height="10"
                        width="10"
                        radius="45"
                        color="#6D6D6D"
                        secondaryColor=""
                        ariaLabel="revolving-dot-loading"
                        wrapperStyle={{}}
                        wrapperClass=""
                        visible={true}
                      />
                    </h1>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    );
  if (error3) return <p>Error :</p>;

  // console.log("plateAuctions", plateAuctions);

  // const activeBids = auction.filter((item) => item.status === "IN_AUCTION");

  // console.log("auction",auction);
  const bidWon = auction.filter(
    (item) =>
      item.detail?.auction?.winner?.email === userEmail &&
      item.status === "SOLD"
  );
  // console.log("won", bidWon);
  const bidLost = auction.filter(
    (item) =>
      item.detail?.auction?.winner?.email != userEmail && item.status === "SOLD"
  );
  // console.log("lost", bidLost);

  // Destructure data to access plates information
  const { me } = data;

  const handleToggleModalAuc = () => {
    setAddAuctionTable("");
  };

  if (loading4) {
    return <p>Loading...</p>;
  }

  if (error4) {
    return <p>Error: {error4.message}</p>;
  }

  const bids = data4?.plate?.detail?.auction?.bids || [];
  const sortedBids = [...bids].sort((a, b) => b.price - a.price);
  console.log(sortedBids, "sordddd");
  const formatDateTime = (dateTimeString) => {
    const options = {
      year: "numeric",
      month: "long",
      day: "numeric",
      hour: "numeric",
      minute: "numeric",
    };

    return new Date(dateTimeString).toLocaleString("en-US", options);
  };

  return (
    <section className="dasgboard-miansection hundred-payment pb-5">
      <div className="user-seller-approved-unapproved-container pb-5">
        <div className="row pt-2">
          <UserSidebar />
          <div className="col-sm-9 col-md-9 col-lg-9 dashboard-main-content">
            <div className="dashboard-title">
              <div className="set-auction-rows-flexes">
                <div className="">
                  <h1>Auction </h1>
                </div>
                <div className="set-switch-flex">
                  <h2 id="formButton">
                    {isTwoFactorEnable ? "Switch to Seller" : "Switch to Buyer"}
                  </h2>
                  <Switch
                    className="react-switch user-seller-buyer-dash-set"
                    onChange={handleToggle}
                    checked={isTwoFactorEnable}
                    aria-labelledby="neat-label"
                    uncheckedIcon={
                      <div
                        style={{
                          fontSize: "10px",
                          color: "#fff",
                          fontWeight: "400",
                          padding: "4px 0 0 4px",
                        }}
                      ></div>
                    }
                    checkedIcon={
                      <div
                        style={{
                          fontSize: "10px",
                          color: "#fff",
                          fontWeight: "400",
                          padding: "4px 0 0 6px",
                        }}
                      ></div>
                    }
                    onColor="#7A724E"
                    offColor="#7A724E"
                    height={35}
                    width={90}
                  />
                </div>
              </div>
            </div>
            {isTwoFactorEnable ? (
              <div className="parent-profile-form">
                <div className="profile-box-parent sett-responsive-auction-box">
                  <div className="parent-auction">
                    <div className="parent-auction-text-flex">
                      <img src={UserBoxIcnMetroHammer} alt="" />
                      <h2>Auction</h2>
                    </div>
                    <div className="parent-main-auction">
                      <nav className="cards-tabs-parent">
                        <div
                          className="nav nav-tabs sett-cards-tabs-flex"
                          id="nav-tab"
                          role="tablist"
                        >
                          <button
                            className="nav-link active"
                            id="nav-home-tab"
                            data-bs-toggle="tab"
                            data-bs-target="#nav-home"
                            type="button"
                            role="tab"
                            aria-controls="nav-home"
                            aria-selected="true"
                            onClick={() => setTabs("activeBids")}
                          >
                            Active Bids
                          </button>
                          <button
                            className="nav-link"
                            id="nav-profile-tab"
                            data-bs-toggle="tab"
                            data-bs-target="#nav-profile"
                            type="button"
                            role="tab"
                            aria-controls="nav-profile"
                            aria-selected="false"
                            onClick={() => setTabs("activeBids")}
                          >
                            Bids Won
                          </button>
                          <button
                            className="nav-link"
                            id="nav-contact-tab"
                            data-bs-toggle="tab"
                            data-bs-target="#nav-contact"
                            type="button"
                            role="tab"
                            aria-controls="nav-contact"
                            aria-selected="false"
                            onClick={() => setTabs("activeBids")}
                          >
                            Bids Lost
                          </button>
                        </div>
                      </nav>

                      <div className="tab-content" id="nav-tabContent">
                        <div
                          className="tab-pane fade show active"
                          id="nav-home"
                          role="tabpanel"
                          aria-labelledby="nav-home-tab"
                        >
                          <div className="parent-auction-fi-tab-cards">
                            <div className="auction-tab-flex-parent">
                              {loading ? (
                                <h1>asda</h1>
                              ) : (
                                activeBids?.map((plate) => (
                                  <div
                                    className="auction-card-box-parent"
                                    key={plate.combination}
                                  >
                                    <div className="auction-card-box-border">
                                      <div className="box-inner-back-img">
                                        <img src={UserMaskGroup1} alt="" />
                                      </div>
                                      <div className="auction-box-plate-one userdash-set-plateone">
                                        <img src={UserLayer1} alt="" />
                                      </div>
                                      <div className="auction-card-fi-btn">
                                        <button>{plate?.combination}</button>
                                      </div>
                                      <div className="parent-join-btn-auction">
                                        <button className="sm-bid">
                                          Current Bid
                                        </button>
                                        <button className="lg-ruppees">
                                          {plate.detail?.currentBid
                                            ? plate.detail.currentBid
                                            : "No Bids Available"}
                                        </button>
                                      </div>

                                      <div className="auction-active-btn">
                                        <button className="auction-status">
                                          Status
                                        </button>
                                        <button className="auction-active">
                                          Active
                                        </button>
                                      </div>
                                    </div>
                                  </div>
                                ))
                              )}
                            </div>
                          </div>
                        </div>
                        <div
                          className="tab-pane fade"
                          id="nav-profile"
                          role="tabpanel"
                          aria-labelledby="nav-profile-tab"
                        >
                          <div className="parent-auction-fi-tab-cards">
                            {bidWon?.map((plate) => (
                              <div
                                className="auction-tab-flex-parent"
                                key={plate.combination}
                              >
                                <div className="auction-card-box-parent">
                                  <div className="auction-card-box-border">
                                    <div className="box-inner-back-img">
                                      <img src={UserMaskGroup1} alt="" />
                                    </div>
                                    <div className="auction-box-plate-one userdash-set-plateone">
                                      <img src={UserLayer1} alt="" />
                                    </div>
                                    <div className="auction-card-fi-btn">
                                      <button>{plate?.combination}</button>
                                    </div>
                                    <div className="parent-join-btn-auction">
                                      <button className="sm-bid">
                                        Current Bid
                                      </button>
                                      <button className="lg-ruppees">
                                        {plate.detail?.currentBid}
                                      </button>
                                    </div>

                                    <div className="auction-active-btn">
                                      <button className="auction-status">
                                        Status
                                      </button>
                                      <button className="auction-active">
                                        Closed
                                      </button>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            ))}
                          </div>
                        </div>
                        <div
                          className="tab-pane fade"
                          id="nav-contact"
                          role="tabpanel"
                          aria-labelledby="nav-contact-tab"
                        >
                          <div className="parent-auction-fi-tab-cards">
                            {bidLost?.map((plate) => (
                              <div
                                className="auction-tab-flex-parent"
                                key={plate.combination}
                              >
                                <div className="auction-card-box-parent">
                                  <div className="auction-card-box-border">
                                    <div className="box-inner-back-img">
                                      <img src={UserMaskGroup1} alt="" />
                                    </div>
                                    <div className="auction-box-plate-one userdash-set-plateone">
                                      <img src={UserLayer1} alt="" />
                                    </div>
                                    <div className="auction-card-fi-btn">
                                      <button>{plate?.combination}</button>
                                    </div>
                                    <div className="parent-join-btn-auction">
                                      <button className="sm-bid">
                                        Current Bid
                                      </button>
                                      <button className="lg-ruppees">
                                        {plate.detail?.currentBid}
                                      </button>
                                    </div>

                                    <div className="auction-active-btn">
                                      <button className="auction-status">
                                        Status
                                      </button>
                                      <button className="auction-active">
                                        Closed
                                      </button>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            ))}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ) : (
              <div className="parent-profile-form">
                <div className="profile-box-parent">
                  <div className="parent-auction">
                    <div className="parent-auction-text-flex">
                      <img src={SellerDashboardIcnMetroHammer} alt="" />
                      <h2>Auction</h2>
                    </div>
                    {addauctiontable === "" ? (
                      <div className="parent-auction-table">
                        <div className="box-table-start">
                          <div className="auction-seller-tble d-flex w-100">
                            <div
                              style={{ width: "16%" }}
                              className="auction-seller-tble-inner"
                            >
                              <h3>Image</h3>
                            </div>
                            <div
                              style={{ width: "16%" }}
                              className="auction-seller-tble-inner"
                            >
                              <h3>Products</h3>
                            </div>
                            <div
                              style={{ width: "25%" }}
                              className="auction-seller-tble-inner"
                            >
                              <h3>Starting Amount</h3>
                            </div>
                            <div
                              style={{ width: "25%" }}
                              className="auction-seller-tble-inner"
                            >
                              <h3>Remaining Time</h3>
                            </div>
                            <div
                              style={{ width: "16.6%" }}
                              className="auction-seller-tble-inner"
                            >
                              <h3>Status</h3>
                            </div>
                          </div>
                          <div>
                            {me.plates
                              .filter((plate) => {
                                // console.log(plate,"-----map")
                                return (
                                  plate.detail.__typename === "PlateAuction"
                                );
                              })
                              .map((plate) => {
                                // console.log(plate ,"----plate")
                                return (
                                  <div key={plate.id}>
                                    <div className="auction-seller-tble d-flex w-100">
                                      <div
                                        style={{ width: "16%" }}
                                        className="auction-seller-tble-inner next-row-none text-align-start set-responsive-img"
                                      >
                                        <img
                                          onClick={() => {
                                            passID({
                                              variables: { id: plate.id },
                                            });

                                            setAddAuctionTable(
                                              "seller-next-screen-table-parent"
                                            );
                                          }}
                                          className="seller-next-screen"
                                          src={SellerDasboardGroup191}
                                          alt=""
                                        />
                                      </div>
                                      <div
                                        style={{ width: "16%" }}
                                        className="auction-seller-tble-inner next-row-none set-the-content"
                                      >
                                        <h3>{plate.combination}</h3>
                                      </div>
                                      <div
                                        style={{ width: "25%" }}
                                        className="auction-seller-tble-inner next-row-none set-this-content"
                                      >
                                        <h3>
                                          {plate.detail?.auction?.bids?.price}
                                        </h3>
                                      </div>
                                      <div
                                        style={{ width: "25%" }}
                                        className="auction-seller-tble-inner next-row-none set-this-content"
                                      >
                                        <h3>
                                          {" "}
                                          {plate.detail?.auction?.endAt ? (
                                            <CountdownTimer
                                              targetDate={new Date(
                                                plate.detail?.auction?.endAt
                                              ).getTime()}
                                            />
                                          ) : (
                                            <p>---</p>
                                          )}
                                        </h3>
                                      </div>
                                      <div
                                        style={{ width: "16.6%" }}
                                        className="auction-seller-tble-inner next-row-none set-this-content"
                                      >
                                        <h3>{plate?.status}</h3>
                                      </div>
                                    </div>
                                  </div>
                                );
                              })}
                          </div>
                        </div>
                      </div>
                    ) : addauctiontable ===
                      "seller-next-screen-table-parent" ? (
                      <div className="seller-next-screen-table-parent">
                        <Link className="sett-the-cros-btn">
                          <FontAwesomeIcon
                            icon={faCircleXmark}
                            style={{ fontSize: "25px", color: "black" }}
                            onClick={handleXmark2}
                          />
                        </Link>
                        <div className="next-seller-screen-para">
                          <p>Victorian Signature plate VIC XX3</p>
                        </div>
                        <div className="seller-next-table">
                          <div className="auction-seller-next-tble d-flex w-100">
                            <div
                              style={{ width: "30%" }}
                              className="auction-seller-tble-next text-center"
                            >
                              <h3>Bidder Name</h3>
                            </div>
                            <div
                              style={{ width: "45%" }}
                              className="auction-seller-tble-next text-center"
                            >
                              <h3>Bidding Time</h3>
                            </div>
                            <div
                              style={{ width: "25%" }}
                              className="auction-seller-tble-next text-center"
                            >
                              <h3>Bidding Amount</h3>
                            </div>
                          </div>
                        </div>
                        {sortedBids.map((bid) => (
                          <div className="seller-next-table" key={bid.id}>
                            <div className="auction-seller-next-tble d-flex w-100">
                              <div
                                style={{ width: "30%" }}
                                className="auction-seller-tble-next text-center"
                              >
                                <h3>{bid.user?.username}</h3>
                              </div>
                              <div
                                style={{ width: "45%" }}
                                className="auction-seller-tble-next text-center"
                              >
                                <h3>{formatDateTime(bid.createdAt)}</h3>
                              </div>
                              <div
                                style={{ width: "25%" }}
                                className="auction-seller-tble-next text-center"
                              >
                                <h3>{bid.price}</h3>
                              </div>
                            </div>
                          </div>
                        ))}
                        {/* {id && <AuctionBids id={id}/>} */}
                      </div>
                    ) : null}
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </section>
  );
}

export default Auction;
