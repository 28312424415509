import React, { useState, useEffect } from 'react';
const AnimatedCursor = () => {
  const [cursorPosition, setCursorPosition] = useState({ x: 0, y: 0 });

  useEffect(() => {
    const handleMouseMove = (event) => {
      setCursorPosition({ x: event.clientX, y: event.clientY });
    };

    window.addEventListener('mousemove', handleMouseMove);

    return () => {
      window.removeEventListener('mousemove', handleMouseMove);
    };
  }, []);

  return (
    <div
      className="animated-cursor"
      style={{ top: cursorPosition.y, left: cursorPosition.x }}
    ></div>
  );
};

export default AnimatedCursor;