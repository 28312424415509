import { configureStore } from '@reduxjs/toolkit'
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage'; // Default storage engine (uses localStorage)
import { combineReducers } from 'redux';
import userSlice from './slices/userSlice'
import wishlistSlice from './slices/wishlistSlice';

const persistConfig = {
  key: 'root', // Key under which your persisted state will be stored
  storage,    // Storage engine to use
};

const rootReducer = combineReducers({
  user: userSlice,
  wishlist: wishlistSlice
})

export const store = configureStore({
  reducer: persistReducer(persistConfig, rootReducer),
})


export const persistor = persistStore(store);