import { Routes, Route, Outlet, Link } from "react-router-dom";
import { ApolloClient, InMemoryCache, gql, useQuery, useMutation } from '@apollo/client';
import Sidebar from '../../../components/Sidebar';
import React, { useState } from 'react'
import { useEffect } from 'react';
import { IpAddress } from '../../../utils/Function';
import Swal from 'sweetalert2'
import ResponsiveNavBarDasbord from '../../../components/ResponsiveNavBarDasbord';

// console.log(IpAddress, 'ip addressss fr')

const client = new ApolloClient({
  uri: IpAddress,
  cache: new InMemoryCache(),
});

const GET_USERS_QUERY = gql`
  query {
    users(where: { status: [DECLINE] }) {
      id
      username
      email
      phoneNumber
    }
  }
`;

const REMOVE_USER_MUTATION = gql`
mutation RemoveUser($id: Int!) {
  deleteUser(data: { id: $id }) {
    username
    status
  }
}
`;

const GET_USER_DETAILS = gql`
  query User($userId: ID!) {
    user(where: { id: $userId }) {
      city
      companyName
      drivingLicense
      email
      firstName
      id
      lastName
      phoneNumber
      postCode
      status
      streetAddress
      username
      state
    }
  }
`;



const customStyles = {
  content: {
    top: '60%',
    left: '60%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    backgroundColor: "transparent",
    border: "none",
    width: "60%"
  },
};


function FormRequest() {
  const [isreqmodalclientlicenseaproval, setIsReqModalClientLicenseAproval] = useState(false)
  
  const Swal = require('sweetalert2')
  
  
  const modalrequeston = () => {
    setIsReqModalClientLicenseAproval((prev) => !prev)
  }

  const [deleteUser] = useMutation(REMOVE_USER_MUTATION, {
    fetchPolicy:"network-only",
    client,
    update: (cache, { data: { deleteUser } }) => {
      cache.modify({
        fields: {
          users(existingUsers = [], { readField }) {
            return existingUsers.filter(
              (userRef) => deleteUser.id !== readField('id', userRef)
            );
          },
        },
      });
    },
    refetchQueries: ['REMOVE_USER_MUTATION'],
  });

  const handleDisable = (id) => {
    console.log(id)

    Swal.fire({
      title: 'Are you sure you want to Trash this request?',
      text: "You are about to Trash the request. Proceeding will permanently remove this request.",
      icon: 'warning',
      showCancelButton: true,
   
      cancelButtonColor: '#7A724E',
      confirmButtonColor: '#BA0D07',
      confirmButtonText: 'Yes, delete it!'
    }).then((result) => {

      if (result.isConfirmed) {
        deleteUser({ variables: { id: parseInt(id) } })
        Swal.fire(
          'Deleted!',
          'Your file has been deleted.',
          'Success'
        )
      }
    })


      .catch((error) => {
        // Handle error
        Swal.fire({
          icon: 'error',
          title: 'Oops...',
          text: 'Something went wrong!',
          footer: '<a href="">Why do I have this issue?</a>'
        })
      });
  };
  //delete end


//approve



  const { loading, error, data } = useQuery(GET_USERS_QUERY, {    fetchPolicy:"network-only",
  client },{  refetchQueries: ['GET_USERS_QUERY'],});
  if (loading) return(
    <div className="bg-dashboard">
    <main id="main">
    <ResponsiveNavBarDasbord/>
      <Sidebar />
      <div className="dashboard-right-side">
            <div className="form-req-tble-parent">
            <div className="payment-inner-page-set">
              <div style={{backgroundColor: "#FFFBF0"}} className="form-req-tble-inner d-flex">
            <h1 style={{color: "#6D6D6D"}}>Loading....</h1>
            </div>
            </div>
          </div>
          </div>
        </main>
        </div>
  );
  if (error) return <div>Error: {error.message}</div>;

  return (   
    
    <div className="bg-dashboard">
      <main id="main">
      <ResponsiveNavBarDasbord/>
        <Sidebar />
        <div className="dashboard-right-side">
          <div className="page-title">
            <h1>Forms Requests</h1>
          </div>
          <div className="payment-inner-page-set">
            <Link to="/FormRequest" className="dec-us">Back</Link>
            <h1 className="decline-title">Declined Requests</h1>
            <div className="form-req-tble-parent">
              <div className="form-req-tble-inner d-flex">
                <div style={{ width: '15%' }} className="form-req-tble-content text-center">
                  <h3>Name</h3>
                </div>
                <div style={{ width: '30%' }} className="form-req-tble-content text-center">
                  <h3>Email</h3>
                </div>
                <div style={{ width: '20%' }} className="form-req-tble-content text-center">
                  <h3>Phone</h3>
                </div>
                <div style={{ width: '35%' }} className="form-req-tble-content text-center">
                  <h3>Status</h3>
                </div>
              </div>
              <div>
              {data.users.slice().reverse().map((user) => (
                  <div className="form-req-tble-inner form-req-tble-inner-2 d-flex mt-3" key={user.username}>
                    <div style={{ width: '15%' }} className="form-req-tble-content text-center">
                      <Link onClick={modalrequeston} to={`/FormDetails/${user.id}`}>
                        <h3 style={{ borderBottom: '2px solid #707070' }}>{user.username}</h3>
                      </Link>
                    </div>
                    <div style={{ width: '30%' }} className="form-req-tble-content text-center">
                      <h3>{user.email}</h3>
                    </div>
                    <div style={{ width: '20%' }} className="form-req-tble-content text-center">
                      <h3>{user.phoneNumber}</h3>
                    </div>
                    <div style={{ width: '35%' }} className="form-req-tble-content d-flex justify-content-center text-center">
                     
                      <div  className="approved-btn-decline decline-btn ms-2">
                        <button onClick={() => handleDisable(user.id)}>Trash</button>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </main>
    </div>

  );
}

export default FormRequest;