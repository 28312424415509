import React, { useEffect, useState,useRef } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { ApolloClient, InMemoryCache, gql, useMutation } from '@apollo/client';
import group from './images/_Group_.png'
import Header from '../../components/Header';
import { useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import AnimatedCursor from '../../components/AnimatedCursor';
import { useDispatch } from 'react-redux'
import { loginUser } from '../../redux/slices/userSlice';
import { extractIdFromToken } from '../../utils/Function';
import { IpAddress } from '../../utils/Function';
  import { FaEye, FaEyeSlash } from 'react-icons/fa';


import Swal from 'sweetalert2'
import { ToastContainer, toast } from 'react-toastify';

const adminID = '1'

const client = new ApolloClient({
  uri: IpAddress,
  cache: new InMemoryCache(),
});

const LOGIN_MUTATION = gql`
  mutation LoginMutation($email: String!, $password: String!) {
    login(loginInput: { email: $email, password: $password }) {
      accessToken
    }
  }
`;
//username: dummy@ele.com
//password: 12345

function LoginApiTest() {
  const emailRef = useRef(null);
  const passwordRef = useRef(null);


  const [showPassword, setShowPassword] = useState(false);

  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    email: '',
    password: '',
  });

  const [isLoading, setIsLoading] = useState(false);
  const dispatch = useDispatch()


  const [loginMutation] = useMutation(LOGIN_MUTATION, { client });

  const handleInputChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const handleLogin = async (e) => {
    e.preventDefault();
    setIsLoading(true);

  

    try {
      const { data } = await loginMutation({
        variables: { email: formData.email, password: formData.password },
      });

      const id = extractIdFromToken(data.login.accessToken)
      dispatch(loginUser({
        ...data.login,
        userID: id,
        userEmail: formData.email
      }))


      if (id == adminID) {
        navigate('/MainDashboard')
      } else {
        // toast.success('Your are Logged in');
        Swal.fire({
          position: 'center',
          icon: 'success',
          title: 'Your are Logged in',
          showConfirmButton: false,
          timer: 2500
        })
        navigate('/');
      }
    } catch (error) {
      console.error(error);
      Swal.fire({
        icon: 'error',
        // title: 'Oops...',
        text: 'Incorrect Email or password.'
      })
    }
  };


  setTimeout(() => {
    setIsLoading(false);
  }, 2000);

  const Swal = require('sweetalert2')

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const handleKeyPress = (event) => {
    if (event.key === 'Enter') {
      // Determine which input field is focused and call handleLogin accordingly
      if (event.target === emailRef.current || event.target === passwordRef.current) {
        handleLogin(event);
      }
    }
  };


  return (
    <>


      <AnimatedCursor />
      <div className="main-scroll-bar login-page set-height-login-back">
        <Header />

        <section className="login-sect">
          <div className="container">
            <div className="form-box">
              <div className="form-border-box">
                <div className="icn hide">
                  <i className="fa-solid fa-xmark"></i>
                </div>
                <div className="form-img">
                  <img src={group} alt="" />
                </div>
                <form autoComplete='off'>
                  <input
                    name="email"
                    type="email"
                    placeholder="Email Address"
                    value={formData.email}
                    onChange={handleInputChange}
                    required
                    ref={emailRef}
                    onKeyDown={handleKeyPress}
                  />
                  <div className="toggle-password">
                  <input
                    name="password"
                    type={showPassword ? "text" : "password"}
                    placeholder="Password"
                    value={formData.password}
                    onChange={handleInputChange}
                    required
                    ref={passwordRef}
                    onKeyDown={handleKeyPress}
                  />
                  {showPassword ? (
                    <FaEyeSlash onClick={togglePasswordVisibility} />
                  ) : (
                    <FaEye onClick={togglePasswordVisibility} />
                  )}
                </div>
                  <div className="form-check remember">
                    <input type="checkbox" value={true} />
                    <p>Remember me</p>
                  </div>
                  <div className="form-box-btn">
                    <button disabled={isLoading} onClick={handleLogin} to="#">
                    {isLoading ? 'Loading...' : 'LOGIN'} <span>  </span>
                      {isLoading ? <FontAwesomeIcon /> : ''}

                    </button>


                  </div>
                  
                  <div className="form-img-txt">
                    <img src={group} alt="" />
                    <svg xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 512 512"><path d="M9.4 233.4c-12.5 12.5-12.5 32.8 0 45.3l128 128c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L109.3 288 480 288c17.7 0 32-14.3 32-32s-14.3-32-32-32l-370.7 0 73.4-73.4c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0l-128 128z" /></svg>
                    <Link to="/">Go to Signature +</Link>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </section>
      </div>
    </>
  );
}

export default LoginApiTest;
