import jwtDecode from "jwt-decode";

export const extractIdFromToken = (token) => {
    try {
        const decodedToken = jwtDecode(token);
        const sub = decodedToken.sub;
        return sub;
    } catch (error) {
        return null;
    }
};


//  ~~~~~LIVE SERVER LINKS


// export const baseUrl = "http://192.168.1.52:3011/";
// export const baseUrl2 = "http://192.168.1.52:3011/";


// export const IpAddress = 'http://192.168.1.52:3011/graphql';

// export const userRegister = 'http://192.168.1.52:3011/users/register';

// export const getPlateUrl = (suffix) => {
//     return `http://192.168.1.52:3011/graphql/plates/${suffix}`;
//   };


// ~~~MANI BHAI SERVER LINKS


export const baseUrl = "https://signatureapi.institu-tech.com/";
export const baseUrl2 = "https://signatureapi.institu-tech.com/";


export const IpAddress = 'https://signatureapi.institu-tech.com/graphql';

export const userRegister = 'https://signatureapi.institu-tech.com/users/register';

export const getPlateUrl = (suffix) => {
    return `https://signatureapi.institu-tech.com/graphql/plates/${suffix}`;
  };
