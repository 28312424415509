import React from 'react'
import { gql } from '@apollo/client';
import { useQuery, useMutation } from '@apollo/client';
import { useNavigate, useParams } from 'react-router-dom';
import cancel from '../../../assets/images/cancel-icn.png'

import Sidebar from '../../../components/Sidebar';
import ResponsiveNavBarDasbord from '../../../components/ResponsiveNavBarDasbord'
import { baseUrl } from '../../../utils/Function';

const GET_PLATES = gql`
  query Plate($id: Int!) {
    plate(findPlateInput: { id: $id }) {
      combination
      numberPlate
      status
      askingPrice
      comments
      user{
        firstName
        lastName
        email
        drivingLicense
        phoneNumber
      }
    }
  }
`;


function Plates_recieved_details() {
  const navigate = useNavigate()
  const { id } = useParams();
  const { loading, error, data } = useQuery(GET_PLATES, {
    variables: { id: parseInt(id) },
  });

  const handelCancel=()=>{
    navigate("/Plates_recieved")
  }


  if (loading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>Error: {error.message}</div>;
  }

  const plate = data?.plate;


  return (
    <div className='bg-dashboard'>
      <main id="main">
        <Sidebar />
        <ResponsiveNavBarDasbord />
        <div className="dashboard-right-side plates-rec-parent-modal">
          <div className="page-title">
            <h1>PLATES RECEIVED</h1>
          </div>
          <div className="modal-form-request-parent">
            <div className="modal-form-request-inner">
              <div className="cancel-icn">
                <img src={cancel} onClick={handelCancel}></img>
              </div>
              <div className="row mt-4">
                <div className="col-lg-12">
                  <div className="row">
                    <div className="col-lg-6">
                      <div className="modal-input-form-req py-2">
                        <label htmlFor="modalinp1">
                          First Name
                          <input type="text" defaultValue={plate?.user?.firstName} readOnly />
                        </label>
                      </div>
                    </div>
                    <div className="col-lg-6">
                      <div className="modal-input-form-req py-2">
                        <label htmlFor="modalinp1">
                          Last Name
                          <input type="text" placeholder="Smith" defaultValue={plate?.user?.lastName} readOnly />
                        </label>
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-lg-12">
                      <div className="modal-input-form-req py-2">
                        <label htmlFor="modalinp1">
                          Email
                          <input type="email" placeholder="smith_jhon12@outlook.com" defaultValue={plate?.user?.email} readOnly />
                        </label>
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-lg-12">
                      <div className="modal-input-form-req py-2">
                        <label htmlFor="modalinp1">
                          Phone
                          <input type="text" placeholder="1234 567 890" defaultValue={plate?.user?.phoneNumber} readOnly />
                        </label>
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-lg-12">
                      <div className="modal-input-form-req py-2">
                        <label htmlFor="modalinp1">
                          Plate Combination
                          <input type="text" placeholder="XX - 3 " defaultValue={plate?.combination} readOnly />
                        </label>
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-lg-12">
                      <div className="modal-input-form-req py-2">
                        <label htmlFor="modalinp1">
                          Desired Plate Price
                          <input type="text" placeholder="$4000.00" defaultValue={plate?.askingPrice} readOnly />
                        </label>
                      </div>
                    </div>
                  </div>
                
                  <div className="row">
                    <div className="col-lg-12">
                      <div className="modal-input-form-req py-2">
                        <label className="d-flex flex-column" htmlFor="modalinp1">
                          Comment
                          <textarea
                            placeholder="Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc porttitor augue a leo imperdiet,
                                          sed tincidunt lorem suscipit. Ut nibh mauris, rutrum et amet, rutrum sed massa."
                            defaultValue={plate?.comments}
                            readOnly />
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-4 d-flex">
                  <div className="two-img-parent justify-content-between d-flex">
                    <div className="img-plate-modal">
                    <img src={`${baseUrl}${plate?.numberPlate}`} alt="Number Plate"/> 
                    </div>
                  <div className="two-img-paren flex-column">
                  <img src={`${baseUrl}${plate?.user?.drivingLicense}`} alt="DrivingLicense"/>                       
                  </div>
                  </div>
                </div>
              </div>
          
            </div>
          </div>
        </div>
      </main>

    </div>

  )
}

export default Plates_recieved_details