import React, { useState } from 'react';
import Header from '../../../components/Header';
import Footer from '../../../components/Footer';
import AnimatedCursor from '../../../components/AnimatedCursor';
import axios from 'axios';
import Swal from 'sweetalert2';
import { baseUrl } from '../../../utils/Function';

function Contact() {
    const [formData, setFormData] = useState({
        firstName: '',
        lastName: '',
        email: '',
        telephone: '',
        subject: '',
        comments: ''
    });

    const handleSubmit = (event) => {
        event.preventDefault();

        const data = JSON.stringify({
            firstname: formData.firstName,
            lastname: formData.lastName,
            email: formData.email,
            phone: formData.telephone,
            subject: formData.subject,
            comments: formData.comments
        });

        const config = {
            method: 'post',
            maxBodyLength: Infinity,
            url: `${baseUrl}emails/contactus`,
            headers: {
                'Content-Type': 'application/json'
            },
            data: data
        };

        axios.request(config)
            .then((response) => {
                Swal.fire("Thank You", "Form Submitted Successfully!", "success");
                console.log(JSON.stringify(response.data));
                setFormData({
                    firstName: '',
                    lastName: '',
                    email: '',
                    telephone: '',
                    subject: '',
                    comments: ''
                }); // Reset form data
            })
            .catch((error) => {
                Swal.fire("Error", "Form Submitted Failed!", "error");
                console.log(error);
            });
    };

    const handleChange = (event) => {
        const { name, value } = event.target;
        setFormData((prevData) => ({
            ...prevData,
            [name]: value
        }));
    };



    return (
        <>
            <AnimatedCursor />
            <div className="main-scroll-bar">
                <header className="header-bg">
                    <Header />
                    <section className="top_banner">
                        <div className="container">
                            <div className="grid-row contactpage">
                                <div className="grid-col-2">
                                    <div className="contentBox">
                                        <h1>
                                            Contact Us
                                        </h1>
                                        <p>
                                            Lorem ipsum dolor sit amet, consectetur <br />
                                            adipiscing elit. Nam convallis rhoncus eros, <br />
                                            nec scelerisque nisi
                                        </p>
                                    </div>
                                </div>
                                <div className="grid-col-2"></div>
                            </div>
                        </div>
                    </section>
                </header>
                <div className="main_contact-page">
                    <section className="form-sec">
                        <div className="container">
                            <div className="parentfrom">
                                <div className="mainheading">
                                    <h2>
                                        CONTACT FORM
                                    </h2>
                                    <p>
                                        Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed et
                                        volutpat tellus, quis finibus risus. Nam ullamcorper auctor elit
                                        lobortis faucibus.
                                    </p>
                                </div>
                                <div className="form-main-div">
                                    <form onSubmit={handleSubmit}>
                                        <div className="gri-col-divider">
                                            <input
                                                type="text"
                                                name="firstName"
                                                placeholder="First Name*"
                                                value={formData.firstName}
                                                onChange={handleChange}
                                            />
                                            <input
                                                type="text"
                                                name="lastName"
                                                placeholder="Last Name*"
                                                value={formData.lastName}
                                                onChange={handleChange}
                                            />
                                        </div>
                                        <input
                                            type="email"
                                            name="email"
                                            placeholder="Enter Your Email*"
                                            value={formData.email}
                                            onChange={handleChange}
                                        />
                                        <input
                                            type="text"
                                            name="telephone"
                                            placeholder="Telephone*"
                                            value={formData.telephone}
                                            onChange={handleChange}
                                        />
                                        <label htmlFor="help">
                                            How can we help ?
                                            <input
                                                type="text"
                                                name="subject"
                                                placeholder="Subject*"
                                                value={formData.subject}
                                                onChange={handleChange}
                                            />
                                        </label>
                                        <textarea
                                            name="comments"
                                            placeholder="Comments / Questions"
                                            id="comments"
                                            cols={30}
                                            rows={10}
                                            value={formData.comments}
                                            onChange={handleChange}
                                        />
                                        <button type="submit" className="submit-btn" data-aos="">
                                            Send Message
                                        </button>
                                    </form>

                                </div>
                            </div>
                        </div>
                    </section>
                    <Footer />
                </div>
            </div>

        </>
    )
}

export default Contact;