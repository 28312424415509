import React, { useEffect, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import group from '../../../assets/images/_Group_.png'
import Header from '../../../components/Header';
import AnimatedCursor from '../../../components/AnimatedCursor';
function RegisterSuccuessFull() {
  return (
    <>

      <AnimatedCursor />
      <div className="main-scroll-bar login-page set-height-login-back" style={{height:"100vh"}}>
        <Header />
        <section className="custom-register">
          <div className="Web-Container">
            <div className="form-box">
              <div className="form-border-box">
                <div className="icn hide">
                  <i className="fa-solid fa-xmark"></i>
                </div>
                <div className="form-img">
                  <img src={group} alt="" />
                </div>
                <form>
                  <div className="form-check">
                    <p style={{color:"#4BB543", padding:"0 0 0 126px"}}>Registered Succuessfully</p>
                  </div>
                  <div className="form-box-btn">
                    <Link to="/LoginApiTest">Login</Link>


                  </div>
                </form>
              </div>
            </div>
          </div>
        </section>
      </div>
    </>
  );
}

export default RegisterSuccuessFull;
