import React, { useState, useRef } from 'react';
import { Link, useLocation, useNavigate } from "react-router-dom";
import AnimatedCursor from "../../components/AnimatedCursor";
import Header from "../../components/Header";
import axios from "axios";

import ForgetGroup from "../../assets/images/_Group_.png";
import { baseUrl } from '../../utils/Function';
function ForgetPassword() {
    const location = useLocation();
    // console.log(location.state.email,'route params');
    // const emailID = location.state.email


    const inputRefs = [useRef(), useRef(), useRef(), useRef()];
  const [verificationCode, setVerificationCode] = useState(["", "", "", ""]);

  const handleVerificationCodeChange = (index, value) => {
    if (value.length <= 1 && /^\d*$/.test(value)) {
      const newVerificationCode = [...verificationCode];
      newVerificationCode[index] = value;
      setVerificationCode(newVerificationCode);

      if (value.length > 0 && index < inputRefs.length - 1) {
        inputRefs[index + 1].current.focus();
      }
    }
  };

  const navigate = useNavigate();



  const mail =location.state.email
  const intValue = parseInt(verificationCode.join(''), 10);
  console.log(mail,'email');
  console.log(intValue,'code');

const handleSubmit = async () => {
    let data = JSON.stringify({
        "email": mail,
        "code": intValue
      });
      let config = {
        method: 'post',
        maxBodyLength: Infinity,
        url: `${baseUrl}auth/verify-code`,
        headers: {
          'Content-Type': 'application/json',
        },
        data : data
      };
      axios.request(config)
      .then((response) => {
        console.log(JSON.stringify(response.data));

        
      })
      .catch((error) => {
        console.log(error);
      });
      navigate("/NewPassword" , { state:{intValue ,mail} } )
}


  return (
    <>
      <AnimatedCursor />
      <div className="main-scroll-bar login-page set-height-login-back">
        <Header />

        <section className="login-sect">
          <div className="container">
            <div className="form-box">
              <div className="form-border-box set-frgt-frm-box">
              <Link to="/ForgetPassword" className="rght-bk-arrow">
                <i className="fa-solid fa-rectangle-xmark"></i>
                  </Link>
                <div className="icn hide">
                  <i className="fa-solid fa-xmark"></i>
                </div>
                <div className="form-img set-frgt-img">
                  <img src={ForgetGroup} alt="" />
                </div>
                {/* <div className="frgt-vrfy-txt-main">
                  <h3>ENTER VERIFICATION CODE</h3>
                </div> */}
                <div className="verification-code-input set-vrfy-input-feild">
                    {verificationCode.map((digit, index) => (
                      <input
                        key={index}
                        ref={inputRefs[index]}
                        type="text"
                        maxLength="1"
                        value={digit}
                        onChange={(e) =>
                          handleVerificationCodeChange(index, e.target.value)
                        }
                      />
                    ))}
                  </div>
                  {verificationCode}

                <div className="sbmt-back-parent">
       
                  <div  onClick={() =>
                      console.log(
                        "Entered Verification Code:",
                        verificationCode.join("")
                      )
                    } className="vrfy-sbmt-btn">
                    <button onClick={handleSubmit} >Submit</button>
                  </div>
                </div>
                <div className="form-img-txt">
                  <img src={ForgetGroup} alt="" />
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    height="1em"
                    viewBox="0 0 512 512"
                  >
                    <path d="M9.4 233.4c-12.5 12.5-12.5 32.8 0 45.3l128 128c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L109.3 288 480 288c17.7 0 32-14.3 32-32s-14.3-32-32-32l-370.7 0 73.4-73.4c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0l-128 128z" />
                  </svg>
                  <Link to="/">Go to Signature +</Link>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </>
  );
}

export default ForgetPassword;