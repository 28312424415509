import React from 'react'
import {useEffect} from 'react'
import Header from '../../../components/Header'
import Footer from '../../../components/Footer'
import plate1 from '../../../assets/images/plate-1.png'
import logo from '../../../assets/images/logo.png'
import { gql, useQuery } from '@apollo/client'
import AnimatedCursor from '../../../components/AnimatedCursor'
import { baseUrl } from '../../../utils/Function';

import { useLocation } from "react-router-dom";


const SOLD_PLATES = gql`
query {
  plates(where: { status: SOLD }) {
    combination
    updatedAt
    numberPlate
    status
    detail {
      ... on PlateAuction {
        currentBid
      }
      ... on PlateListing {
        currentOffer

      }
    }
  }
}`;




function Results() {

  const { pathname } = useLocation()
  
    useEffect(() => {
        window.scrollTo(0, 0);
    }, [pathname]);

  const { loadin, error, data } = useQuery(SOLD_PLATES);

  if (loadin) {
    return <div>Loadin...</div>
  }
  if (error) {
    return <div>Error {error.message}...</div>
  }
  const plates = data?.plates || [];
  const currentBid = plates[0]?.detail?.auction?.bids.find(
    (bid) => bid.currentBid
  )?.price;

  // const baseUrl = "http://192.168.18.9:5000/";
  const cardData = [
    {
      sold: true,
      date: '1st Feb 2023',
      price: '$4,000',
    },

  ]
  return (
    <>

      <AnimatedCursor />
      <div class="main-scroll-bar">
        <header class="header-bg inner-page">
          {/* <?php include('includes/header.php'); ?> */}
          <Header />
          <section class="top_banner">
            <div class="container">
              <div class="grid-row auction-grid">
                <div class="grid-col-2">
                  <div class="contentBox content leFadeIn">
                    <h1 class="leFadeIn" data-aos="fade-right" data-aos-duration="1700">Results</h1>
                    <p data-aos="fade-right" data-aos-duration="1800">Lorem ipsum dolor sit amet, consectetur
                      adipiscing elit. Nam convallis rhoncus eros,
                      nec scelerisque nisi.</p>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </header>
        <div class="main">
          <section class="card-section">
            <div class="container">
              <div class="card-grid">


                {data && data.plates.map((plate) => (
                  <div className="main-card">
                    <div className="card-logo">
                      <img src={logo} alt="" />
                    </div>
                    <div className="card-logo-img">
                      <img src={`${baseUrl}${plate?.numberPlate}`} alt="" />
                    </div>
                    <div className="card-main-txt">
                      <div className="card-txt">
                        {console.log(plate)}
                        <h4>{plate.status}</h4>
                        {/* const formattedDate = date.substring(0, 10); */}
                        <p>{plate.updatedAt.substring(0, 10)}</p>
                      </div>
                      <div className="card-txt-btn">
                        {plate.detail.offers && plate.detail.offers.length > 0 ? (
                          <a href="#">$ {plate.detail.offers[0].currentOffer}</a>
                        ) : (
                          <p>No offer available</p>
                        )}
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </section>
        </div>
        <Footer />
      </div>
    </>
  )
}

export default Results;