import React, { useEffect, useState } from 'react';
import { useQuery } from '@apollo/client';
import { gql } from '@apollo/client';

const GET_PLATES = gql`
  query GetPlate($plateId: Int!) {
    plate(findPlateInput: { id: $plateId }) {
      detail {
        ...on PlateListing {
          offers {
            price
          }
        }
      }
    }
  }
`;

const PriceCountComponent = ({ plateId }) => {
  const { loading, error, data } = useQuery(GET_PLATES, {
    variables: { plateId },
  });
  const [priceCount, setPriceCount] = useState(0);

  useEffect(() => {
    if (!loading && !error) {
      const pricesCount = data.plate.detail.offers.length;
      console.log("Number of prices:", pricesCount);
      setPriceCount(pricesCount);
    }
  }, [loading, error, data]);

  if (loading) {
    return <p>Loading...</p>;
  }
  if (error) {
    return <p>Error: {error.message}</p>;
  }
  
  return (
    <div>
      <h3>{priceCount}</h3>
    </div>
  );
};

export default PriceCountComponent;