import React from 'react'

function Verify_code() {
  return (
<section className="login-parent-serious">
  <div className="container">
    <div className="login-box-parent">
      <div className="loginn-box d-flex flex-column justify-content-center">
        <div className="loginn-box-inner-parent">
          <div className="logo-login text-center">
            <img src="assets/images/login-box-logo.png" alt="" />
          </div>
          <div className="login-heading text-center">
            <h1>Enter Verification Code</h1>
          </div>
          <div className="login-heading text-center">
            <h2>
              Verification code has been sent to <br />
              s***@gmail.com
            </h2>
          </div>
          <div className="verify-code-input-parent mt-5 mb-3">
            <form action="#">
              <h4 className="text-center">Verification Code</h4>
              <div className="d-flex my-3 justify-content-center">
                <input
                  type="tel"
                  maxLength={1}
                  pattern="[0-9]"
                  className="form-control-custom"
                />
                <input
                  type="tel"
                  maxLength={1}
                  pattern="[0-9]"
                  className="form-control-custom"
                />
                <input
                  type="tel"
                  maxLength={1}
                  pattern="[0-9]"
                  className="form-control-custom"
                />
                <input
                  type="tel"
                  maxLength={1}
                  pattern="[0-9]"
                  className="form-control-custom"
                />
              </div>
              <div className="d-flex justify-content-center">
                <h5 className="text-center mt-3 mb-1">Resend Code</h5>
              </div>
            </form>
          </div>
          <div className="submit-link-2-parent d-flex justify-content-center mb-5">
            <div className="submit-link-2 d-flex justify-content-center my-3">
              <a href="dashboard-main.php">Confirm</a>
            </div>
            <div className="left-sub-back-link">
              <a href="verify-acc.php">
                {" "}
                <img src="assets/images/circ-left-chevron.png" alt="" />
              </a>
            </div>
          </div>
          <div className="goback-link text-center my-2">
            <a href="">
              <img src="assets/images/go-back-link.png" alt="" />
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>

  )
}

export default Verify_code