import React from 'react'
import AnimatedCursor from './AnimatedCursor';
import Header from './Header';
import Footer from './Footer';
import WebStarIcn from '../assets/images/star.png';
import plate from '../assets/images/plate-1.png';
import { Link } from 'react-router-dom';
import { gql, useQuery } from '@apollo/client';
import { useSelector } from 'react-redux';
import { baseUrl } from '../utils/Function';




function Results() {
const {list} = useSelector(value=>value.wishlist)
console.log(list,"---")
  return (
    <>

      <AnimatedCursor />
      <div class="main-scroll-bar">
        <header class="header-bg inner-page">
          <Header />
          <section class="top_banner">
            <div class="container">
              <div class="grid-row auction-grid">
                <div class="grid-col-2">
                  <div class="contentBox content leFadeIn">
                    <h1 class="leFadeIn" data-aos="fade-right" data-aos-duration="1700">WatchList</h1>
                    <p data-aos="fade-right" data-aos-duration="1800">Lorem ipsum dolor sit amet, consectetur
                      adipiscing elit. Nam convallis rhoncus erss
                      nec scelerisque nisi.</p>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </header>
        <div class="main">
          <section class="card-section">
            <div class="Web-Container">
              <div class="card-grid">
              {
              list ? list.length >0? list.map((item,index)=>{
                console.log("item",item);                
                return(
                <div className="card-parent-buy">
                <div className="card-inner-buy">
                  <div className="card-buy-inner-parent">
                    <div className="card-content-buy-top">
                      <div className="buy-card-plate">
                        <img src={`${baseUrl}${item?.numberPlate}`} alt="Plate" />
                        <div className="star-icn-cart"></div>
                      </div>
                    </div>
                    <div className="gridBuy-text">
                      <span>{item?.combination}</span>
          
                    </div>
                    <div className="auction-price">
                      <small className="bg-green-bg">Offer considered</small>
                      <div>Asking Price: ${item?.askingPrice}</div>
                    </div>
                  </div>
                </div>
              </div>
              )})  
              :'':''
            }
              </div>
            </div>
          </section>
        </div>
        <Footer />
      </div>
    </>
  )
}

export default Results;