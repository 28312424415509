import React from 'react'
import Sidebar from '../../../components/Sidebar'

function Payment2() {
    return (
        <div class="bg-dashboard">
            <main id="main">
                <Sidebar />
                <div className="dashboard-right-side payment-parent2">
                    <div className="page-title">
                        <h1>PAYMENT</h1>
                    </div>
                    <div className="box-pages-parent">
                        <div className="header-payment-2 d-flex">
                            <div className="payment-header-left">
                                <h1>Invoice For Buyer</h1>
                            </div>
                            <div className="payment-header-left">
                                <h2>XX-01</h2>
                            </div>
                        </div>
                        <div className="payment2-table-parent">
                            <div className="payment2-table-inner-parent d-flex mt-3">
                                <div style={{ width: "5%" }} className="payment2-table-inner">
                                    <h4>
                                        <input type="checkbox" />
                                    </h4>
                                </div>
                                <div style={{ width: "15%" }} className="payment2-table-inner">
                                    <h4>Invoice #</h4>
                                </div>
                                <div style={{ width: "15%" }} className="payment2-table-inner">
                                    <h4>Buyer</h4>
                                </div>
                                <div style={{ width: "25%" }} className="payment2-table-inner">
                                    <h4>
                                        Transaction Type <i className="fa-solid fa-chevron-down" />
                                    </h4>
                                </div>
                                <div style={{ width: "15%" }} className="payment2-table-inner">
                                    <h4>Date</h4>
                                </div>
                                <div style={{ width: "25%" }} className="payment2-table-inner">
                                    <h4>
                                        Download Reciept <i className="fa-solid fa-download" />
                                    </h4>
                                </div>
                            </div>
                        </div>
                        <div className="payment2-table-parent">
                            <div className="payment2-table-inner-parent payment2-table-inner-parent2 d-flex mt-3">
                                <div style={{ width: "5%" }} className="payment2-table-inner">
                                    <h4>
                                        <input type="checkbox" />
                                    </h4>
                                </div>
                                <div style={{ width: "15%" }} className="payment2-table-inner">
                                    <h4>1234</h4>
                                </div>
                                <div style={{ width: "15%" }} className="payment2-table-inner">
                                    <h4>Mark Allen</h4>
                                </div>
                                <div style={{ width: "25%" }} className="payment2-table-inner">
                                    <h4>Initial Deposit</h4>
                                </div>
                                <div style={{ width: "15%" }} className="payment2-table-inner">
                                    <h4>13 May, 2023</h4>
                                </div>
                                <div style={{ width: "25%" }} className="payment2-table-inner">
                                    <div className="downl-rep-tble-icn d-flex justify-content-center">
                                        <div className="downl-rep-tble-icn-inner ms-2 me-3">
                                            <img src="assets/images/pdf-icn.png" alt="" />
                                        </div>
                                        <div className="downl-rep-tble-icn-inner">
                                            <img src="assets/images/excel-icn.png" alt="" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="payment2-table-parent">
                            <div className="payment2-table-inner-parent payment2-table-inner-parent2 d-flex mt-3">
                                <div style={{ width: "5%" }} className="payment2-table-inner">
                                    <h4>
                                        <input type="checkbox" />
                                    </h4>
                                </div>
                                <div style={{ width: "15%" }} className="payment2-table-inner">
                                    <h4>1234</h4>
                                </div>
                                <div style={{ width: "15%" }} className="payment2-table-inner">
                                    <h4>Mark Allen</h4>
                                </div>
                                <div style={{ width: "25%" }} className="payment2-table-inner">
                                    <h4>Remaining Amount</h4>
                                </div>
                                <div style={{ width: "15%" }} className="payment2-table-inner">
                                    <h4>13 May, 2023</h4>
                                </div>
                                <div style={{ width: "25%" }} className="payment2-table-inner">
                                    <div className="downl-rep-tble-icn d-flex justify-content-center">
                                        <div className="downl-rep-tble-icn-inner ms-2 me-3">
                                            <img src="assets/images/pdf-icn.png" alt="" />
                                        </div>
                                        <div className="downl-rep-tble-icn-inner">
                                            <img src="assets/images/excel-icn.png" alt="" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="header-payment-2 d-flex mt-4">
                            <div className="payment-header-left">
                                <h1>Invoice Seller</h1>
                            </div>
                        </div>
                        <div className="payment2-table-parent">
                            <div className="payment2-table-inner-parent d-flex mt-3">
                                <div style={{ width: "5%" }} className="payment2-table-inner">
                                    <h4>
                                        <input type="checkbox" />
                                    </h4>
                                </div>
                                <div style={{ width: "15%" }} className="payment2-table-inner">
                                    <h4>Invoice #</h4>
                                </div>
                                <div style={{ width: "15%" }} className="payment2-table-inner">
                                    <h4>Seller</h4>
                                </div>
                                <div style={{ width: "25%" }} className="payment2-table-inner">
                                    <h4>
                                        Transaction Type <i className="fa-solid fa-chevron-down" />
                                    </h4>
                                </div>
                                <div style={{ width: "15%" }} className="payment2-table-inner">
                                    <h4>Date</h4>
                                </div>
                                <div style={{ width: "25%" }} className="payment2-table-inner">
                                    <h4>
                                        Download Reciept <i className="fa-solid fa-download" />
                                    </h4>
                                </div>
                            </div>
                        </div>
                        <div className="payment2-table-parent mb-5">
                            <div className="payment2-table-inner-parent payment2-table-inner-parent2 d-flex mt-3">
                                <div style={{ width: "5%" }} className="payment2-table-inner">
                                    <h4>
                                        <input type="checkbox" />
                                    </h4>
                                </div>
                                <div style={{ width: "15%" }} className="payment2-table-inner">
                                    <h4>1234</h4>
                                </div>
                                <div style={{ width: "15%" }} className="payment2-table-inner">
                                    <h4>John Smith</h4>
                                </div>
                                <div style={{ width: "25%" }} className="payment2-table-inner">
                                    <h4>Seller's Amount</h4>
                                </div>
                                <div style={{ width: "15%" }} className="payment2-table-inner">
                                    <h4>13 May, 2023</h4>
                                </div>
                                <div style={{ width: "25%" }} className="payment2-table-inner">
                                    <div className="downl-rep-tble-icn d-flex justify-content-center">
                                        <div className="downl-rep-tble-icn-inner ms-2 me-3">
                                            <img src="assets/images/pdf-icn.png" alt="" />
                                        </div>
                                        <div className="downl-rep-tble-icn-inner">
                                            <img src="assets/images/excel-icn.png" alt="" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </main>
        </div>
    )
}

export default Payment2