import React from 'react';
import { useQuery,gql } from '@apollo/client';
import { useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';


const GET_AUCTION_PRODUCT = gql`
  query GetAuctionProduct($id: Int!) {
    plate(findPlateInput: { id: $id }) {
      askingPrice
      combination
      id
      detail {
        ... on PlateAuction {
          currentBid
          auction {
            id
            endAt
            bids {
              id
              price
              createdAt
              user {
                firstName
                lastName
                id
                username
            }
            }
          }
        }
        ... on PlateListing {
          id
        }
      }
      status
      comments
      user {
        id
        username
        firstName
        lastName
      }
    }
  }
`;


function Allbids(props) {
  // const userData = useSelector((state) => state.user.userData);
  // const id = userData.userID;
  const ID = props;
  
    const { loading, error, data } = useQuery(GET_AUCTION_PRODUCT, {
    variables: { id: parseInt(ID) },
  });
  {console.log(data, "is page pr dataaaaa ...")}
  if (loading) {
    return <p>Loading...</p>;
  }

  if (error) {
    return <p>Error: {error.message}</p>;
  }

  

  const bids = data?.plate?.detail?.auction?.bids || [];
  const sortedBids = [...bids].sort((a, b) => b.price - a.price);

  const formatDateTime = (dateTimeString) => {
    const options = {
      year: 'numeric',
      month: 'long',
      day: 'numeric',
      hour: 'numeric',
      minute: 'numeric',
    };

    return new Date(dateTimeString).toLocaleString('en-US', options);
  };
 

  return (
 <>
                                           {console.log(ID, "IDSSSS")}

  
       {sortedBids.map((bid) => (
          

                                        <div className="seller-next-table" key={bid.id}>
                                        <div className="auction-seller-next-tble d-flex w-100">
                                          <div style={{ width: "30%" }} className="auction-seller-tble-next text-center">
                                            <h3>{bid.user?.username}</h3>
                                            
                                          </div>
                                          <div style={{ width: "45%" }} className="auction-seller-tble-next text-center">
                                            <h3>{formatDateTime(bid.createdAt)}</h3>
                                          </div>
                                          <div style={{ width: "25%" }} className="auction-seller-tble-next text-center">
                                            <h3>{bid.price}</h3>
                                          </div>
                                        </div>
                                      </div>
            ))}
            </>

  );
}

export default Allbids;
