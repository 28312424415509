import React from 'react'
import Sidebar from '../../../components/Sidebar'

function Main_fourth_modal() {
  return (
<div class="bg-dashboard">
  <main id="main">
  <Sidebar />
  <div className="dashboard-right-side main-fourth-modal-parent">
  <div className="modal-form-request-parent">
    <div style={{ boxShadow: "none" }} className="modal-form-request-inner">
      <div className="cancel-icn">
        <a href="dashboard-main.php">
          <img src="assets/images/cancel-icn.png" alt="" />
        </a>
      </div>
      <div className="abc-heading text-center abc-chng">
        <h1>AB-01</h1>
      </div>
      <div className="row">
        <div className="col-lg-6 bd-left">
          <div className="main-modal-width">
            <div className="abc-heading text-center">
              <h1>Seller Details</h1>
            </div>
            <div className="row d-flex justify-content-center">
              <div className="col-lg-12">
                <div className="modal-input-form-req py-2">
                  <label htmlFor="modalinp1">
                    Name
                    <input type="text" placeholder="Ethen Booker" />
                  </label>
                </div>
              </div>
            </div>
            <div className="row d-flex justify-content-center">
              <div className="col-lg-12">
                <div className="modal-input-form-req py-2">
                  <label htmlFor="modalinp1">
                    Email
                    <input
                      type="email"
                      placeholder="ethenbooker12@outlook.com"
                    />
                  </label>
                </div>
              </div>
            </div>
            <div className="row d-flex justify-content-center">
              <div className="col-lg-12">
                <div className="modal-input-form-req py-2">
                  <label className="d-flex flex-column" htmlFor="modalinp1">
                    Address
                    <textarea
                      placeholder="Lorem ipsum dolor sit amet, consectetur ad
                                          ipiscing elit. Etiam consectetur ex ut felis co
                                          ngue sagittis."
                      defaultValue={
                        "                                            "
                      }
                    />
                  </label>
                </div>
              </div>
            </div>
            <div className="row d-flex justify-content-center">
              <div className="col-lg-12">
                <div className="modal-input-form-req py-2">
                  <label htmlFor="modalinp1">
                    Reserve Price
                    <input type="text" placeholder="$4000.00" />
                  </label>
                </div>
              </div>
            </div>
            <div className="row d-flex justify-content-center">
              <div className="col-lg-12">
                <div className="modal-input-form-req py-2">
                  <label htmlFor="modalinp1">
                    Desired Plate Price
                    <input type="text" placeholder="$4000.00" />
                  </label>
                </div>
              </div>
            </div>
            <div className="send-req-btn">
              <a href="">Amount Transfer</a>
            </div>
          </div>
        </div>
        <div className="col-lg-6">
          <div className="main-modal-width">
            <div className="abc-heading text-center">
              <h1>Buyer Details</h1>
            </div>
            <div className="row d-flex justify-content-center">
              <div className="col-lg-12">
                <div className="modal-input-form-req py-2">
                  <label htmlFor="modalinp1">
                    Name
                    <input type="text" placeholder="John Smith" />
                  </label>
                </div>
              </div>
            </div>
            <div className="row d-flex justify-content-center">
              <div className="col-lg-12">
                <div className="modal-input-form-req py-2">
                  <label htmlFor="modalinp1">
                    Email
                    <input type="email" placeholder="jh_smith12@outlook.com" />
                  </label>
                </div>
              </div>
            </div>
            <div className="row d-flex justify-content-center">
              <div className="col-lg-12">
                <div className="modal-input-form-req py-2">
                  <label className="d-flex flex-column" htmlFor="modalinp1">
                    Address
                    <textarea
                      placeholder="Lorem ipsum dolor sit amet, consectetur ad
                                          ipiscing elit. Etiam consectetur ex ut felis co
                                          ngue sagittis."
                      defaultValue={
                        "                                            "
                      }
                    />
                  </label>
                </div>
              </div>
            </div>
            <div className="row d-flex justify-content-center">
              <div className="col-lg-12">
                <div className="modal-input-form-req py-2">
                  <label htmlFor="modalinp1">
                    Winning Bid
                    <input type="text" placeholder="$4000.00" />
                  </label>
                </div>
              </div>
            </div>
            <div className="row d-flex justify-content-center">
              <div className="col-lg-12">
                <div className="modal-input-form-req py-2">
                  <label htmlFor="modalinp1">Status</label>
                  <img src="assets/images/pending-btn-main.png" alt="" />
                </div>
              </div>
            </div>
            <div className="send-req-btn">
              <h6>Request for Initial Deposit</h6>
              <a href="">Send Requests</a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>


  </main>
  {/* Latest compiled JavaScript */}
</div>
  )
}

export default Main_fourth_modal