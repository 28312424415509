import React, { useState, useEffect } from "react";
import UserSidebar from "../../../../components/UserSidebar";
import logo from "../../../../assets/images/logoas.png";
import Img1 from "../../../../assets/images/saller1.png";
import Img2 from "../../../../assets/images/saller2.png";
import Img3 from "../../../../assets/images/saller3.png";
import Img4 from "../../../../assets/images/saller4.png";
import BuyerChartIconImg from "../../../../assets/images/charticon.png";
import BuyerCartIconImg from "../../../../assets/images/cart.png";
import Switch from "react-switch";
import { Link } from "react-router-dom";
import { useParams } from "react-router-dom";
import { useQuery, gql, useMutation } from "@apollo/client";
import { RevolvingDot } from "react-loader-spinner";

const UPDATE_USER_MUTATION = gql`
  mutation UpdateUser($data: UpdateUserInput!) {
    updateUser(data: $data) {
      mode
    }
  }
`;

const USER_NAME = gql`
  query {
    me {
      firstName
    }
  }
`;
const GET_USER_ACTIVITIES = gql`
query {
  me {
    activities {
      subject
      description
    }
  }
}
`;

function ApprovedUser() {
  const pageSize = 5;
  const [currentPage, setCurrentPage] = useState(1);
  const [isTwoFactorEnable, setIsTwoFactorEnable] = useState(false);
  const [updateUserMutation] = useMutation(UPDATE_USER_MUTATION, {
    refetchQueries: [
      { query: GET_USER_ACTIVITIES },
      // You can add more queries to refetch, if needed
    ],
  });
  const {
    loading: loading2,
    error: error2,
    data: data2,
  } = useQuery(GET_USER_ACTIVITIES);

  const { loading: loading3, error: error3, data: data3 } = useQuery(USER_NAME);
  const username = data3?.me?.firstName;
  // Function to handle switch toggle
  const handleToggle = () => {
    setIsTwoFactorEnable((prevState) => !prevState);
  };

  // Function to trigger the mutation with the given mode value
  const triggerMutation = (mode) => {
    const dataToUpdate = {
      mode: mode,
      // Add other fields as needed to update the user
    };
    // Execute the mutation
    updateUserMutation({ variables: { data: dataToUpdate } });
  };

  // Run the initial mutation on page load or whenever needed
  useEffect(() => {
    triggerMutation(isTwoFactorEnable ? "SELLER" : "BUYER");
  }, [isTwoFactorEnable]);

  const { id } = useParams();
  // const userId = parseInt(id);

  if (loading2 || loading3)
    return (
      <section className="dasgboard-miansection">
        <div className="user-seller-approved-unapproved-container">
          <div className="row pt-2">
            <UserSidebar />
            <div className="col-sm-9 col-md-9 col-lg-9 dashboard-main-content">
              <div className="parent-profile-form">
                <div class="profile-box-parent sett-responsive-auction-box">
                  <div class="parent-auction">
                    <h1 style={{ color: "#6D6D6D" }}>
                      <RevolvingDot
                        height="10"
                        width="10"
                        radius="45"
                        color="#6D6D6D"
                        secondaryColor=""
                        ariaLabel="revolving-dot-loading"
                        wrapperStyle={{}}
                        wrapperClass=""
                        visible={true}
                      />
                    </h1>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    );

  if (error2) return <p>Error: {error2.message}</p>;
  if (error3) return <p>Error: {error3.message}</p>;

  const activities = data2?.me?.activities;
  const handlePreviousPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  const handleNextPage = () => {
    const maxPage = Math.ceil(activities.length / pageSize);
    if (currentPage < maxPage) {
      setCurrentPage(currentPage + 1);
    }
  };
  const reversedActivities = [...activities].reverse();
  const startIndex = (currentPage - 1) * pageSize;
  const endIndex = startIndex + pageSize;
  const currentActivities = reversedActivities.slice(startIndex, endIndex);

  function formatDateTime(dateTime) {
    const options = {
      year: "numeric",
      month: "long",
      day: "numeric",
      hour: "numeric",
      minute: "numeric",
      hour12: true,
    };

    return new Date(dateTime).toLocaleString("en-US", options);
  }

  return (
    <div>
      <section className="dasgboard-miansection">
        <div className="user-seller-approved-unapproved-container">
          <div className="row pt-2">
            <UserSidebar />
            <div className="col-sm-9 col-md-9 col-lg-9 dashboard-main-content">
              <div className="dashboard-title">
                <div className="sllermain-title">
                  <h1>DASHBOARD</h1>
                  <div className="sidebar-brand">
                    <div className="set-switch-flex">
                      <h2 id="formButton">
                        {isTwoFactorEnable
                          ? "Switch to Seller"
                          : "Switch to Buyer"}
                      </h2>
                      <Switch
                        className="react-switch user-seller-buyer-dash-set"
                        onChange={handleToggle}
                        checked={isTwoFactorEnable}
                        aria-labelledby="neat-label"
                        uncheckedIcon={
                          <div
                            style={{
                              fontSize: "10px",
                              color: "#fff",
                              fontWeight: "400",
                              padding: "4px 0 0 4px",
                            }}
                          ></div>
                        }
                        checkedIcon={
                          <div
                            style={{
                              fontSize: "10px",
                              color: "#fff",
                              fontWeight: "400",
                              padding: "4px 0 0 6px",
                            }}
                          ></div>
                        }
                        onColor="#7A724E"
                        offColor="#7A724E"
                        height={35}
                        width={90}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="dashboard-destailbox">
                <div className="wellcome-message-one">
                  <img src={logo} alt="" className="img-fluid" />
                </div>
                <div className="wellcome-message-two">
                  <p>
                    Hello {username}! <br />
                    Welcome to Signature+
                    <br />
                    From your account dashboard you can view your recent orders,
                    manage your account's setting and billing addresses, and
                    edit your password and account details.
                  </p>
                  <a
                  // data-bs-toggle="modal"
                  // href="#exampleModalToggle"
                  // role="button"
                  className="reqvalidation"
                >
                  APPROVED
                </a>
                </div>
              </div>

              {isTwoFactorEnable ? (
                <div className="row my-4 Latest-Order gx-0">
                  <div className="col-8 latest_order">
                    <div className="order-heading set-ordering text-center">
                      <h1> Notifications</h1>
                    </div>
                    <div className="tabmake">
                      <div className="orderhead">
                        {/* <ul className="order-first">
               
                          <li>Type Of Activity</li>
                          <li>Username</li>

                          <li>User Status</li>

                          <li>Created At</li>
                        </ul> */}
                        {currentActivities.map((activity) => (
                          <div className="order-second" key={activity.id}>
                            <h3>{activity.subject}</h3>
                            <p>
                            {activity?.description}
                              </p>
                            {/* {activity?.type === "CREATE_PLATE" ? (
                              <p>
                                Plate created successfully. Thank you for your
                                submission!
                              </p>
                            ) : activity?.type === "PLACE_COUNTER_OFFER" ? (
                              <p>
                                Counter offer placed successfully. Thank you for
                                your response!
                              </p>
                            ) : activity?.type === "PLACE_OFFER" ? (
                              <p>
                                Offer placed successfully. We will be in touch.
                                Thank you!
                              </p>
                            ) : activity?.type === "PLACE_BID" ? (
                              <p>
                                Your bid has been successfully placed. Good
                                luck!
                              </p>
                            ) : activity?.type === "REGISTER" ? (
                              <p>
                                Congratulations! Your account has been
                                registered.
                              </p>
                            ) : activity?.type === "ACCOUNT_VERIFICATION" ? (
                              <p>
                                Congratulation! Your account has been verified.
                              </p>
                            ) : (
                              <p></p>
                            )} */}
                          </div>
                        ))}
                        <div className="pagination-buttons text-end">
                          {activities.length > 5 && (
                            <>
                              <button
                                className="approved-btn-3"
                                onClick={handlePreviousPage}
                                disabled={currentPage === 1}
                              >
                                Previous
                              </button>
                              <button
                                className="approved-btn-3 mx-2"
                                onClick={handleNextPage}
                                disabled={
                                  currentPage * pageSize >= activities.length
                                }
                              >
                                Next
                              </button>
                            </>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="col-4 set-user-box ">
                    <div className="order-firstbox sallerfashboart">
                      <div className="bids-images-saller">
                        <img src={Img1} alt="" className="img-fluid" />
                      </div>
                      <div className="bids-text-saller">
                        <h2> Total Bids </h2>
                        <h1 className="my-4">0</h1>
                        <a href="/UserSellerAuction" className="btnorder">
                          View More
                        </a>
                      </div>
                    </div>
                    {/* 2 */}
                    <div className="order-firstbox sallerfashboart">
                      <div className="bids-images-saller">
                        <img src={Img2} alt="" className="img-fluid" />
                      </div>
                      <div className="bids-text-saller">
                        <h2> Bids Won </h2>
                        <h1 className="my-4">0</h1>
                        <a href="/UserSellerAuction" className="btnorder">
                          View More
                        </a>
                      </div>
                    </div>
                    {/* 3 */}
                    <div className="order-firstbox sallerfashboart">
                      <div className="bids-images-saller">
                        <img src={Img3} alt="" className="img-fluid" />
                      </div>
                      <div className="bids-text-saller">
                        <h2> Bids Lost </h2>
                        <h1 className="my-4">0</h1>
                        <a href="/UserSellerAuction" className="btnorder">
                          View More
                        </a>
                      </div>
                    </div>
                    {/* 4 */}
                    <div className="order-firstbox sallerfashboart">
                      <div className="bids-images-saller">
                        <img src={Img4} alt="" className="img-fluid" />
                      </div>
                      <div className="bids-text-saller">
                        <h2> Watchlist </h2>
                        <h1 className="my-4">0</h1>
                        <a href="/WatchList" className="btnorder">
                          View More
                        </a>
                      </div>
                    </div>
                    {/* 5 */}
                  </div>
                </div>
              ) : (
                <div className="row my-4 Latest-Order gx-0">
                  <div className="col-8 latest_order">
                    <div className="order-heading set-ordering text-center">
                      <h1> Notifications</h1>
                    </div>
                    <div className="tabmake">
                      <div className="orderhead">
                        {/* <ul className="order-first">
                 
                          <li>Type Of Activity</li>
                          <li>Username</li>

                          <li>User Status</li>

                          <li>Created At</li>
                        </ul> */}
                        {currentActivities.map((activity) => (
                          <div className="order-second" key={activity.id}>
                            <h3>{activity.subject}</h3>
                            <p>
                            {activity?.description}
                              </p>
                            {/* {activity?.type === "CREATE_PLATE" ? (
                              <p>
                                Plate created successfully. Thank you for your
                                submission!
                              </p>
                            ) : activity?.type === "PLACE_COUNTER_OFFER" ? (
                              <p>
                                Counter offer placed successfully. Thank you for
                                your response!
                              </p>
                            ) : activity?.type === "PLACE_OFFER" ? (
                              <p>
                                Offer placed successfully. We will be in touch.
                                Thank you!
                              </p>
                            ) : activity?.type === "PLACE_BID" ? (
                              <p>
                                Your bid has been successfully placed. Good
                                luck!
                              </p>
                            ) : activity?.type === "REGISTER" ? (
                              <p>
                                Congratulations! Your account has been
                                registered.
                              </p>
                            ) : activity?.type === "ACCOUNT_VERIFICATION" ? (
                              <p>
                                Congratulation! Your account has been verified.
                              </p>
                            ) : (
                              <p></p> 
                            )}*/}
                          </div>
                        ))}
                        {/* <ul className='order-second d-block text-'>
                          <li>
                          <h3>hello</h3>
                          </li>
                          
                          <li>
                          
                          </li>
                        </ul> */}
                        <div className="pagination-buttons text-end">
                        {activities.length > 5 && (
                            <>
                              <button
                                className="approved-btn-3"
                                onClick={handlePreviousPage}
                                disabled={currentPage === 1}
                              >
                                Previous
                              </button>
                              <button
                                className="approved-btn-3 mx-2"
                                onClick={handleNextPage}
                                disabled={
                                  currentPage * pageSize >= activities.length
                                }
                              >
                                Next
                              </button>
                            </>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-4">
                    <div className="order-firstbox mb-5">
                      <img
                        src={BuyerChartIconImg}
                        alt=""
                        className="img-fluid"
                      />
                      <h2> Total Sales </h2>
                      <h1 className="my-4">0</h1>
                      {/* <Link to="" className="btnorder">
                        View More
                      </Link> */}
                    </div>
                    <div className="order-firstbox">
                      <img
                        src={BuyerCartIconImg}
                        alt=""
                        className="img-fluid"
                      />
                      <h2> No. Plates For Sale </h2>
                      <h1 className="my-4">0</h1>
                      {/* <Link to="" className="btnorder">
                        View More
                      </Link> */}
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}

export default ApprovedUser;
