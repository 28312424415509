import React, { useState } from 'react'
import UserSidebar from '../../../../components/UserSidebar'
import UserDasboradBoxIcnMaterialPayment from "../../../../assets/images/Icon-material-payment.png"
import Paymenti from '../../../../components/payment/Paymenti'
import { useLocation, useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { gql, useQuery } from '@apollo/client';





const GET_USERS_QUERY = gql`
  query user($id: Int!) {
    user(where: { id: $id }) {
      id
      username
      email
      firstName
      lastName
      city
      country
      streetAddress
      city
      phoneNumber
      postCode
      state
    }
  }
`;










function Payment() {

  const [addpaymentdetail, setAddPaymentDetail] = useState("");
  // const amount = useParams();
  const userData = useSelector((state) => state.user.userData);
  const id = userData.userID;
  // const finalAmount = userData.userID.slice(1,amount.length)


  const { loading, error, data } = useQuery(GET_USERS_QUERY, {
    variables: { id: parseInt(id) },
  });

  const user = data?.user;


  return (
    <section className="dasgboard-miansection hundred-payment pb-5">
      {/* Dashboard */}
      {/* dashboard */}
      <div className="user-seller-approved-unapproved-container pb-5">
        <div className="row pt-2">
          <UserSidebar />
          <div className="parent-profile-form col-sm-9 col-md-9 col-lg-9 dashboard-main-content">
            <div className="dashboard-title">
              <h1>Payment Method</h1>
            </div>
            <div className="profile-box-parent set-payment-box">
              {addpaymentdetail === "" ?
                <div className="parent-of-payment">
                  <div className="payment-text-flex-parent">
                    <div className="payment-text-img-flex">
                      <img src={UserDasboradBoxIcnMaterialPayment} alt="" />
                      <h2>Payment</h2>
                    </div>
                    <div className="payment-fi-btn show">
                      {/* <button className="add-payment-screen" onClick={() => setAddPaymentDetail('')}>Add Payment Method</button> */}
                    </div>
                  </div>
                  <div className="formset pr-5">
                  <fieldset className="row">
                      <div>
                        <legend>Name</legend>
                        <input
                          className="form-input restrict-numbers"
                          type="text"
                          id="fname"
                          name="fname"
                          readOnly
                          defaultValue={user?.firstName}
                        />
                      </div>
                      <div>
                        <legend>Email</legend>
                        <input
                          className="form-input restrict-numbers"
                          type="text"
                          id="fname"
                          name="fname"
                          readOnly

                          defaultValue={user?.email}
                        />
                      </div>
                      <div>
                        <legend>Phone Number</legend>
                        <input
                          className="form-input restrict-numbers"
                          type="text"
                          id="fname"
                          name="fname"
                          readOnly

                          defaultValue={user?.phoneNumber}
                        />
                      </div>
                      <div>
                        <legend>Account Name*</legend>
                        <input
                          className="form-input restrict-numbers"
                          type="text"
                          id="fname"
                          name="fname"
                          required
                          // placeholder='Account Name'
                        />
                      </div>
                     
                      <div>
                        <legend>Account Number*</legend>
                        <input
                          className="form-input restrict-numbers"
                          type="text"
                          id="fname"
                          name="fname"
                          required

                          // placeholder='Account Number'
                        />
                      </div>
                    </fieldset>
                    <div className="UserDash-Logut-Btn-Set">
                      <button className="logout" type="submit">
                        Save Change
                      </button>
                    </div>
                    </div>



                </div> : addpaymentdetail === "payment-next-text-flex-parent" ?
                  <div className="parent-next-payment">
                    <div className="check payment-next-text-flex-parent">
                      <div className="payment-next-img-text-flex">
                        <h2>Payment via Stripe</h2>
                      </div>
                      <div className="payment-next-btn">
                        <button>Add Payment Method</button>
                      </div>
                    </div>
                    {/* <Paymenti amount={parseFloat(finalAmount)}/> */}
                  </div> : null
              }
            </div>
          </div>
        </div>
      </div>
    </section>

  )
}

export default Payment