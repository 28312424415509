import { useEffect, useState } from "react";

import { Elements } from "@stripe/react-stripe-js";
import CheckoutForm from "./CheckoutForm";
import { loadStripe } from "@stripe/stripe-js";
import { useParams } from "react-router-dom";

function Paymenti({amount}) {
  const [stripePromise, setStripePromise] = useState(null);
  const [clientSecret, setClientSecret] = useState("");
  console.log(amount);
  useEffect(() => {
    fetch("/config").then(async (r) => {
      const { publishableKey } = await r.json();
      console.log("pub",publishableKey);
      setStripePromise(loadStripe(publishableKey));
    });
  }, []);

  // useEffect(() => {
  //   fetch("/create-payment-intent", {
  //     method: "POST",
  //     body: JSON.stringify({
  //       amount: 3600
  //     }),
  //   }).then(async (result) => {
  //     var { clientSecret } = await result.json();
  //     console.log("pay_int",clientSecret);
  //     setClientSecret(clientSecret);
  //   });
  // }, []);

  return (
    <>
      {clientSecret && stripePromise && (
        <Elements stripe={stripePromise} options={{ clientSecret }}>
          <CheckoutForm clientSecret={clientSecret}/>
        </Elements>
      )}
    </>
  );
}

export default Paymenti;
