import { createSlice } from '@reduxjs/toolkit'

const initialState = {
    list: [],
    counter: 0,
}

export const wishlistSlice = createSlice({
    name: 'wishlist',
    initialState,
    reducers: {
        increament: (state) => {
            state.counter += 1
        },
        decreament: (state) => {
            state.counter -= 1
        },
        logoutWishlist: (state) => {
            return {
                ...state,
                counter: 0,
                list:[]
            }
        },
        addWishList:(state,action)=>{
            // 
           return {
            ...state,
            list: action.payload
           }
        },
        removeWishList:(state,action)=>{
            // 
            return {
                ...state,
                list: action.payload
            }

        }
    },
})

// Action creators are generated for each case reducer function
export const { increament, decreament, logoutWishlist,addWishList,removeWishList } = wishlistSlice.actions

export default wishlistSlice.reducer