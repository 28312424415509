import React from "react";
import Sidebar from "../../../components/Sidebar";
import WinnerListEye from "../../../assets/images/Group-24.png";
import WinnerListNoneEye from "../../../assets/images/Icon-awesome-eye-slash.png";
import { gql, useQuery } from "@apollo/client";
import ResponsiveNavBarDasbord from '../../../components/ResponsiveNavBarDasbord'
const GET_AUCTION_WINNERLIST = gql`
  query {
    plates(where: { status: SOLD, purpose: AUCTION }) {
      combination
      user {
        username
      }
      detail {
        ... on PlateAuction {
          currentBid
          auction {
            endAt
            winner {
              username
            }
          }
        }
      }
    }
  }
`;


function formatDateTime(dateTime) {
  const options = {
    year: 'numeric',
    month: 'long',
    day: 'numeric',

  };

  return new Date(dateTime).toLocaleString('en-US', options);
}



function Winner_list() {
  const { loading, error, data } = useQuery(GET_AUCTION_WINNERLIST);

  if (loading) {
    return (
      <div className="bg-dashboard">
    <main id="main">
    <ResponsiveNavBarDasbord/>
      <Sidebar />
      <div className="dashboard-right-side">
            <div className="form-req-tble-parent">
            <div className="payment-inner-page-set">
              <div style={{backgroundColor: "#FFFBF0"}} className="form-req-tble-inner d-flex">
            <h1 style={{color: "#6D6D6D"}}>Loading....</h1>
            </div>
            </div>
          </div>
          </div>
        </main>
        </div>
    );
  }

  if (error) {
    return <div>Error: {error.message}</div>;
  }

  const winnerList = data?.plates || [];

  return (
    <>
      <div class="bg-dashboard">
        <main id="main">
        <ResponsiveNavBarDasbord/>
          <Sidebar />
          <div className="dashboard-right-side">
            <div className="page-title">
              <h1>Winners</h1>
            </div>
            <div className="payment-inner-page-set box-winners-parent-al">
              <div className="winner-table-parent">
                <div className="winner-table d-flex w-100">
                  <div
                    style={{ width: "22%" }}
                    className="winner-table-inner text-center"
                  >
                    <h3>Plate Number</h3>
                  </div>
                  <div
                    style={{ width: "14%" }}
                    className="winner-table-inner text-center"
                  >
                    <h3>Buyer</h3>
                  </div>
                  <div
                    style={{ width: "14%" }}
                    className="winner-table-inner text-center"
                  >
                    <h3>Seller</h3>
                  </div>
                  <div
                    style={{ width: "22%" }}
                    className="winner-table-inner text-center"
                  >
                    <h3>Winning Bid</h3>
                  </div>
                  <div
                    style={{ width: "22%" }}
                    className="winner-table-inner text-center"
                  >
                    <h3>Conclusion Date</h3>
                  </div>
                  <div
                    style={{ width: "6%" }}
                    className="winner-table-inner text-center"
                  >
                    <h3 />
                  </div>
                </div>
                {winnerList.slice().reverse().map((plate) => (
                  <div
                    key={plate.combination}
                    className="winner-table winner-table-2 d-flex w-100"
                  >
                    <div
                      style={{ width: "22%" }}
                      className="winner-table-inner text-center"
                    >
                      <h3>{plate.combination}</h3>
                    </div>
                    <div
                      style={{ width: "14%" }}
                      className="winner-table-inner text-center"
                    >
                      <h3>{plate?.user?.username }</h3>
                    </div>
                    <div
                      style={{ width: "14%" }}
                      className="winner-table-inner text-center"
                    >
                      <h3>{plate?.detail?.auction?.winner?.username || "No Winner"}</h3>
                    </div>
                    <div
                      style={{ width: "22%" }}
                      className="winner-table-inner text-center"
                    >
                      <h3>{plate?.detail?.currentBid || "No Bid"}</h3>
                    </div>
                    <div
                      style={{ width: "29%" }}
                      className="winner-table-inner text-center"
                    >
                      <h3>{formatDateTime(plate.detail.auction.endAt)}</h3>
                    </div>
                   
                  </div>
                ))}
              </div>
            </div>
          </div>
        </main>
      </div>
    </>
  );
}

export default Winner_list;