import React, { useRef, useState } from "react";
import { ApolloClient, InMemoryCache, gql, useQuery, useMutation, useLazyQuery, } from "@apollo/client";
import {useEffect } from "react";
import Header from "../../../components/Header";
import AnimatedCursor from "../../../components/AnimatedCursor";
import RegisterGroup1 from '../../../assets/images/_Group_.png'
import axios from 'axios';
import {baseUrl, userRegister} from '../../../utils/Function'
import FormData from 'form-data';
// import Modal from 'react-modal';
import { Link, useLocation, useNavigate } from "react-router-dom";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { children } from "bootstrap/js/dist/dom/selector-engine";
//axios integration

//end

const COUNTRIES = gql`
  query {
    countries {
      code
      name
    }
  }
`;



const Register = () => {
  const [selectedFileName, setSelectedFileName] = useState("");
  const [formData, setFormData] = useState({
    username: '',
    email: '',
    password: '',
    firstName: '',
    lastName: '',
    companyName: '',
    countryCode: "",
    streetAddress: '',
    cityCode: '',
    state: '',
    postCode: '',
    phoneNumber: '',
    drivingLicense: null,
  });

  const navigate = useNavigate()

  // ----------------------form validation part ---------------------
  const [errors, setErrors] = useState({});
  
  const validateForm = () => {
    let isValid = true;
    const newErrors = {};

    // Username validation: alphanumeric, 4-16 characters
    const usernameRegex = /^[a-zA-Z0-9]{2,16}$/;
    if (!usernameRegex.test(formData.username)) {
      newErrors.username = 'Username must be alphanumeric and 2-16 characters';
      isValid = false;
      usernameRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
    } else if (formData.username.trim() === '') {
      newErrors.username = 'Username is required';
      isValid = false;
    }

    // Email validation
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    console.log("VALID EMAIL", emailRegex.test(formData.email))
    if (!emailRegex.test(formData.email)) {
      newErrors.email = 'Invalid email format';
      isValid = false;
      usernameRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
    } else if (formData.email.trim() === '') {
      newErrors.email = 'Email is required';
      isValid = false;
    }

    const weakpassword =/^[a-zA-Z0-9]{2,16}$/;
    // const weakpassword =/^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d]{3,}$/;
    console.log("VALID PASSWORD", weakpassword.test(formData.password))
    if (!weakpassword.test(formData.password)) {
      newErrors.password = 'Must be at least 8 characters long. Should include a combination of uppercase and lowercase letters.Should contain at least one numeric digit (0-9).';
      isValid = false;
      usernameRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' });

    } else if (formData.password.trim() === '') {
      newErrors.password = 'password is required';
      isValid = false;
    }
    // First name validation: letters only
    const firstNameRegex = /^[A-Za-z]+$/;
    if (!firstNameRegex.test(formData.firstName)) {
      newErrors.firstName = 'First name must contain letters only';
      isValid = false;
      usernameRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' });

    } else if (formData.firstName.trim() === '') {
      newErrors.firstName = 'First name is required';
      isValid = false;
    }

    // Last name validation: letters only
    const lastNameRegex = /^[A-Za-z]+$/;
    if (!lastNameRegex.test(formData.lastName)) {
      newErrors.lastName = 'Last name must contain letters only';
      isValid = false;
      usernameRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' });

    } else if (formData.lastName.trim() === '') {
      newErrors.lastName = 'Last name is required';
      isValid = false;
    }

    // Company name validation: alphanumeric with spaces
    if (formData.companyName.trim() === '') {
      newErrors.companyName = 'Company name is required';
      isValid = false;
    }



    // Street address validation: alphanumeric with spaces
    const streetAddressRegex = /^[a-zA-Z0-9\s]+$/;
    if (!streetAddressRegex.test(formData.streetAddress)) {
      newErrors.streetAddress = 'Street address must be alphanumeric with spaces';
      isValid = false;
    } else if (formData.streetAddress.trim() === '') {
      newErrors.streetAddress = 'Street address is required';
      usernameRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' });

      isValid = false;
    }

    // City code validation: alphanumeric


    // State validation: letters only
    const stateRegex = /^[A-Za-z]+$/;
    if (!stateRegex.test(formData.state)) {
      newErrors.state = 'State must contain letters only';
      isValid = false;
    } else if (formData.state.trim() === '') {
      newErrors.state = 'State is required';
      isValid = false;
    }

    setErrors(newErrors);
    return isValid;
  };


  // // ------------end form validation--------------------


  const formRef = useRef(null);
  const usernameRef = useRef(null); // Add this line
  const emailRef = useRef(null); //




  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
  };

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    setFormData((prevFormData) => ({
      ...prevFormData,
      drivingLicense: file,
    }));
    setSelectedFileName(file.name);

  };


  const handleSubmit = (e) => {
    e.preventDefault();
    console.log("Handle SUBMIT")
    if (validateForm()) {
      // Perform form submission logic
    console.log('Form submitted successfully!');
      
    const data = new FormData();
    data.append('username', formData.username);
    data.append('email', formData.email);
    data.append('password', formData.password);
    data.append('firstName', formData.firstName);
    data.append('lastName', formData.lastName);
    data.append('companyName', formData.companyName);
    data.append('countryCode', formData.countryCode);
    data.append('streetAddress', formData.streetAddress);
    data.append('cityCode', formData.cityCode);
    data.append('state', formData.state);
    data.append('postCode', formData.postCode);
    data.append('phoneNumber', formData.phoneNumber);
    data.append('drivingLicense', formData.drivingLicense);
  
 

    axios
      .post(`${baseUrl}users/register`, data, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      })
      .then((response) => {
        console.log(response.data);
        toast.success("Submited sucessfull");
        window.location.reload();

        })

        .catch((error) => {
          console.log("error.response.data.message", error)
          toast.error(error.response.data.message);
        });
    }

  };


  const [cititesList, setcititesList] = useState([]);

  const CITIES = gql`
  query {
    countries(countryCode: "${formData.countryCode}") {
      cities {
        code
        name
      }
    }
  }
  `;

  const countiresList = useQuery(COUNTRIES);


  const [getCities] = useLazyQuery(CITIES, {
    fetchPolicy: "no-cache",
    onCompleted: (data) => {
      console.log(data);
      setcititesList([...data?.countries[0]?.cities]);
    },
  });

  useEffect(() => {
    if (formData.countryCode !== "") {
      getCities();
    }
  }, [formData.countryCode]);


  const onClick = () => {
    console.log(formData)
    // console.log(RegUser)
  }


  console.log(errors,"-- errors")

  const [isregistersubmitsucuess, setIsRegisterSubmitSucuess] = useState("")


  // const notify = () => toast("Your Application Succesfully Submitted!")
  return (


    <>
      <ToastContainer
        position="top-center"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />

      <AnimatedCursor />
      <div className="main-scroll-bar login-page">
        <section className="header-sec">
          <div className="Web-Container">
            <Header />
          </div>
        </section>
        <section className="login-sect">
          <div className="Web-Container">
            <div className="form-box">
              {isregistersubmitsucuess === "" ?
                <div className="form-border-box"  ref={formRef}>
                  <div className="icn hide">
                    <i className="fa-solid fa-xmark" />
                  </div>
                  <div className="form-img">
                    <img src={RegisterGroup1} alt="" />
                  </div>
                  <form onSubmit={handleSubmit}>
                    <input
                      type="text"
                      name="username"
                      placeholder="Username"
                      value={formData.username}
                      ref={usernameRef}
                      onChange={handleChange}
                    />
                    {errors.username && <span style={{ color: "red", margin: "0px", textAlign: "left" }}>{errors.username}</span>}

                  <input
                    type="text"
                    name="email"
                    placeholder="Email"
                    value={formData.email}
                    ref={emailRef}
                    required
                    onChange={handleChange}
                  />
                    {errors.email && <span style={{color:"red"}}>{errors.email}</span>}

                  <input
                    type="password"
                    name="password"
                    placeholder="Password"
                    value={formData.password}
                    required
                    onChange={handleChange}
                  />
                  <div className="sign-para">
                    <p>Valid Drivers License*</p>
                    <div className="num-plate-photo-parent">
                      <div class="choose-file">
                        <label className="custom-file-upload">
                          <img />
                          <input
                            name="numberPlate"
                            type="file"
                            onChange={handleFileChange}
                          />
                          Choose File
                        </label>
                        <span>
                         {selectedFileName || "No file chosen"}
                        </span>
                      </div>
                    </div>
                    </div>
                    <div className="sign-bill">
                      <h3>Billing Details</h3>
                      <p>
                        *You will only be charged a 5% buyer's premium if you are
                        the highest bidder &amp; win the auction
                      </p>
                      <input
                        type="text"
                        name="fname"
                        placeholder="fname"
                        value={formData.firstName}
                        required
                        onChange={(e) => {
                          setFormData({ ...formData, firstName: e.target.value });
                        }}
                      />
                      {errors.firstName && <span style={{ color: "red" }}>{errors.firstName}</span>}

                      <input
                        type="text"
                        name="lname"
                        placeholder="last name"
                        value={formData.lastName}
                        required
                        onChange={(e) => {
                          setFormData({ ...formData, lastName: e.target.value });
                        }}
                      />
                      {errors.lastName && <span style={{ color: "red" }}>{errors.lastName}</span>}


                      <input
                        type="name"
                        placeholder="Company Name"
                        name="company"
                        value={formData.companyName}
                        required
                        onChange={(e) => {
                          setFormData({
                            ...formData,
                            companyName: e.target.value,
                          });
                        }}
                      />
                      {errors.companyName && <span style={{ color: "red" }}>{errors.companyName}</span>}

                    </div>
                    <div className="plate-sell-drp-dwn signup-slct">
                      <select
                        name="country"
                        value={formData.countryCode}
                        onChange={(e) => {
                          console.log(e.target.value);
                          setFormData({
                            ...formData,
                            countryCode: e.target.value,
                          });
                        }}
                      >
                        {/* {errors.countryCode && <span style={{color:"red"}}>{errors.countryCode}</span>} */}
                        <option value="">Choose Country</option>
                        {countiresList?.data?.countries.map((country, i) => (
                          <option key={i} value={country.code}>
                            {country.name}
                          </option>
                        ))}
                      </select>
                    </div>

                    <textarea
                      id=""
                      cols={30}
                      rows={8}
                      placeholder="Street Address*"
                      name="address"
                      // defaultValue={""}
                      value={formData.streetAddress}
                      required
                      onChange={(e) => {
                        setFormData({
                          ...formData,
                          streetAddress: e.target.value,
                        });
                      }}
                    />
                    {errors.streetAddress && <span style={{ color: "red" }}>{errors.streetAddress}</span>}

                    <div className="plate-sell-drp-dwn signup-slct">
                      <select
                        name="city"
                        value={formData.cityCode}
                        onChange={(e) => {
                          setFormData({ ...formData, cityCode: e.target.value });
                        }}
                      >
                        <option value="">Choose City</option>
                        {cititesList.map((item, i) => (
                          <option key={i} value={item.code}>
                            {item.name}
                          </option>
                        ))}

                      </select>
                      {/* {errors.cityCode && <span style={{color:"red"}}>{errors.cityCode}</span>} */}

                    </div>
                    <input
                      type="name"
                      placeholder="State*"
                      name="state"
                      value={formData.state}
                      required
                      onChange={(e) => {
                        setFormData({ ...formData, state: e.target.value });
                      }}
                    />
                    {errors.state && <span style={{ color: "red" }}>{errors.state}</span>}

                    <input
                      type="name"
                      placeholder="Postcode/Zipcode*"
                      name="post_code"
                      value={formData.postCode}
                      required
                      onChange={(e) => {
                        setFormData({ ...formData, postCode: e.target.value });
                      }}
                    />
                    <input
                      type="Number"
                      placeholder="Phone Number*"
                      name="phone"
                            value={formData.phoneNumber}
                      required
                      maxLength="11"
                      onChange={(e) => {
                        if (e.target.value.length <= 11) {
                          setFormData({ ...formData, phoneNumber: e.target.value });
                        }
                      }}
                    />
                    {errors.phoneNumber && <span style={{ color: "red" }}>{errors.phoneNumber}</span>}

                    <div className="sign-last-para">
                      <p>
                        Your personal data will be used to support your experience
                        throughout this website, to manage access to your account
                        and for other purposes described in our Privacy Policy.
                      </p>
                    </div>
                    <div className="form-box-btn">

                      <button
                        className="btn w-100"
                        name="registerBtn"
                        type="submit"
                      >
                        Register
                      </button>
                    </div>
                    <div className="form-img-txt signup-form-got-to">
                      <img src={RegisterGroup1} alt="" />
                      <svg xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 512 512"><path d="M9.4 233.4c-12.5 12.5-12.5 32.8 0 45.3l128 128c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L109.3 288 480 288c17.7 0 32-14.3 32-32s-14.3-32-32-32l-370.7 0 73.4-73.4c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0l-128 128z" /></svg>
                      <Link to="/">Go to Signature +</Link>
                    </div>
                  </form>
                </div> :isregistersubmitsucuess === "form-box" ?
                
                <div className="form-box">
                <div className="form-border-box">
                  <div className="icn hide">
                    <i className="fa-solid fa-xmark"></i>
                  </div>
                  <div className="form-img">
                    {/* <img src={group} alt="" /> */}
                  </div>
                  <form>
                    <div className="form-check">
                      <p>Remember Me</p>
                    </div>
                    <div className="form-box-btn">
                      <Link to="/LoginApiTest">Login</Link>
                    </div>
                    <div className="form-img-txt">
                      {/* <img src={group} alt="" /> */}
                      <svg xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 512 512"><path d="M9.4 233.4c-12.5 12.5-12.5 32.8 0 45.3l128 128c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L109.3 288 480 288c17.7 0 32-14.3 32-32s-14.3-32-32-32l-370.7 0 73.4-73.4c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0l-128 128z" /></svg>
                      <Link to="/">Go to Signature +</Link>
                    </div>
                  </form>
                </div>
              </div>
                : null
              }
            </div>
          </div>
        </section>
      </div>
    </>
  );
};

export default Register