import React, { useState, useEffect } from "react";
import UserSidebar from "../../../../components/UserSidebar";
import "../../../../../node_modules/bootstrap/js/dist/dropdown";
import UserDashboardBoxIcnMetroHammer from "../../../../assets/images/Icon-metro-hammer.png";
import UserDashboardMaskGroup1 from "../../../../assets/images/Mask-Group-1.png";
import UserDashboardLayer1 from "../../../../assets/images/Layer-2.png";
import UserDashboardIcnMaterialLocalOffer from "../../../../assets/images/Icon-material-local-offer.png";
import SellerDashboardIcnMaterialLocalOffer from "../../../../assets/images/Icon-material-local-offer.png";
import SellerDashboardIcGroup24 from "../../../../assets/images/Group-24.png";
import Switch from "react-switch";
import { gql, useQuery, useMutation, useLazyQuery } from "@apollo/client";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faChevronRight,
  faChevronLeft,
  faCircleXmark,
} from "@fortawesome/free-solid-svg-icons";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
const UPDATE_USER_MUTATION = gql`
  mutation UpdateUser($data: UpdateUserInput!) {
    updateUser(data: $data) {
      mode
    }
  }
`;
const ME_QUERY = gql`
  query {
    me {
      firstName
      lastName
      email
      phoneNumber
      drivingLicense
      city
      companyName
      country
      plates {
        id
        combination
        comments
        askingPrice
        detail {
          ... on PlateListing {
            isOpenForPrice
            settlePrice
            offers {
              id
              user {
                username
              }
              price
              counter
              status
            }
          }
        }
      }
    }
  }
`;
const PLATE_QUERY = gql`
  query Plate($id: Int!) {
    plate(findPlateInput: { id: $id }) {
      combination
      comments
      detail {
        ... on PlateListing {
          offers {
            user {
              username
            }
            id
            price
            createdAt
            counter
            status
          }
        }
      }
    }
  }
`;
const UPDATE_OFFER_MUTATION = gql`
  mutation UpdateOffer($id: Int!, $counterValue: Float!) {
    updateOffer(
      where: { id: $id }
      data: { counter: $counterValue, status: COUNTER }
    ) {
      id
      status
    }
  }
`;
const UPDATE_OFFER_STATUS = gql`
  mutation UpdateOfferStatus($offerId: Int!) {
    updateOffer(data: { status: APPROVE }, where: { id: $offerId }) {
      id
      status
    }
  }
`;
const DECLINE_OFFER_STATUS = gql`
  mutation UpdateOfferStatus($offerId: Int!) {
    updateOffer(data: { status: DECLINE }, where: { id: $offerId }) {
      id
      status
    }
  }
`;
function Offerone() {
  const [plateData, setPlateData] = useState({});
  const [isTwoFactorEnable, setIsTwoFactorEnable] = useState(true);

  const handleToggle = () => {
    setIsTwoFactorEnable((prev) => !prev);
  };

  const [addcardcounterapproved, setCardCounterApproved] = useState("");
  const [addoffertable, setAddOfferTable] = useState("");
  const [addoffertablesecond, setAddOfferTableSecond] = useState("");
  const [visiblePlates, setVisiblePlates] = useState(3);
  const [currentPage, setCurrentPage] = useState(1);
  const [isLoading, setisLoading] = useState(true);
  const [counterID, setCounterID] = useState(1);
  const [counterValue, setCounterValue] = useState(null);
  const handleCounterClick = (id) => {
    setCounterID(id);
  };
  const handleImageClick = (id) => {
    passID({
      variables: { id: id },
    });
    setAddOfferTable("offer-next-screen-parent");
    setAddOfferTableSecond("third-table-add-parent");
  };
  const [updateUserMutation] = useMutation(UPDATE_USER_MUTATION, {
    refetchQueries: [
      { query: ME_QUERY },
      // You can add more queries to refetch, if needed
    ],
  });

  const [updateOfferStatus] = useMutation(UPDATE_OFFER_STATUS);

  const [updateOfferStatus2] = useMutation(DECLINE_OFFER_STATUS);

  const handleApprove = (offerId) => {
    updateOfferStatus({
      variables: { offerId },
    })
      .then((response) => {
        Swal.fire({
          position: "center",
          icon: "success",
          title: "Offer Accepted",
          showConfirmButton: false,
          timer: 2500,
        });
      })
      .catch((error) => {
        console.error("Error updating offer:", error);
      });
  };
  const handledecline = (offerId) => {
    updateOfferStatus2({
      variables: { offerId },
    })
      .then((response) => {
        Swal.fire({
          position: "center",
          icon: "success",
          title: "Offer Decline",
          showConfirmButton: false,
          timer: 2500,
        });
      })
      .catch((error) => {
        console.error("Error updating offer:", error);
      });
  };

  const handleXmark = () => {
    setAddOfferTable("");
  };
  const handleXmark2 = () => {
    setAddOfferTable("");
  };
  // Function to handle switch toggle

  // Function to trigger the mutation with the given mode value
  const triggerMutation = (mode) => {
    const dataToUpdate = {
      mode: mode,
      // Add other fields as needed to update the user
    };

    // Execute the mutation
    updateUserMutation({ variables: { data: dataToUpdate } });
  };

  // Run the initial mutation on page load or whenever needed
  useEffect(() => {
    triggerMutation(isTwoFactorEnable ? "BUYER" : "SELLER");
  }, [isTwoFactorEnable]);

  const [updateOffer] = useMutation(UPDATE_OFFER_MUTATION);
  const handleUpdateOffer = () => {
    const offerIdToUpdate = counterID;

    updateOffer({
      variables: {
        id: offerIdToUpdate,
        counterValue: parseFloat(counterValue),
      },
    })
      .then((response) => {
        console.log(
          "Offer updated successfully:",
          response.data.updateOffer.id
        );
        Swal.fire({
          position: "center",
          icon: "success",
          title: "Offer Countered",
          showConfirmButton: false,
          timer: 2500,
        });
        setCounterValue("");
      })
      .catch((error) => {
        console.error("Error updating offer:", error.message);
      });
    Swal.fire({
      position: "center",
      icon: "error",
      title: "Something went wrong",
      showConfirmButton: false,
      timer: 2500,
    });
  };

  const { loading, error, data } = useQuery(ME_QUERY, {
    fetchPolicy: "network-only",
  });
  const plates = data?.me?.plates;
  const Swal = require("sweetalert2");

  const [passID, { loading: loading2, error: error2, data: data2 }] =
    useLazyQuery(PLATE_QUERY);

  if (error2) return <p>Error: {error2.message}</p>;
  const offers2 = data2?.plate?.detail?.offers || [];

  if (loading || loading2) {
    return (
      <section className="dasgboard-miansection">
        <div className="user-seller-approved-unapproved-container">
          <div className="row pt-2">
            <UserSidebar />
            <div className="col-sm-9 col-md-9 col-lg-9 dashboard-main-content">
              <div className="parent-profile-form">
                <div class="profile-box-parent sett-responsive-auction-box">
                  <div class="parent-auction">
                    <h1 style={{ color: "#6D6D6D" }}>Loading....</h1>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    );
  }

  if (error) {
    return <p>Error: {error.message}</p>;
  }

  if (!data?.me || !data?.me?.plates) {
    return <p>No data available.</p>;
  }

  const offers = data?.me?.plates.flatMap((plate) => plate?.detail?.offers);
  const prices = offers
    .map((offer) => offer?.price)
    .filter((price) => !isNaN(price));
  const highestPrice = Math.max(...prices);

  const platesPerPage = 3;
  const startIndex = (currentPage - 1) * platesPerPage;
  const endIndex = startIndex + platesPerPage;
  const totalPages = Math.ceil(plates.length / platesPerPage);

  const handleLoadMore = (page) => {
    setCurrentPage(page);
  };
  const handleToggleModal = () => {
    setCardCounterApproved("");
  };

  const handelDecline = () => {
    setCardCounterApproved("");
  };

  const getHighestPriceOffer = (offers) => {
    if (!offers || offers.length === 0) {
      return null;
    }
    return offers.reduce((highestOffer, currentOffer) => {
      return highestOffer.createdAt > currentOffer.createdAt
        ? highestOffer
        : currentOffer;
    });
  };

  const platesToShow = plates.slice(startIndex, endIndex);

  return (
    <section className="dasgboard-miansection hundred-payment pb-5">
      <div className="user-seller-approved-unapproved-container pb-5">
        <div className="row pt-2">
          <UserSidebar />
          <div className="col-sm-9 col-md-9 col-lg-9 dashboard-main-content">
            <div className="dashboard-title">
              <div className="set-auction-rows-flexes">
                <div className="">
                  <h1>Offer</h1>
                </div>
                <div className="set-switch-flex">
                  <h2 id="formButton">
                    {isTwoFactorEnable ? "Switch to Seller" : "Switch to Buyer"}
                  </h2>
                  <Switch
                    className="react-switch user-seller-buyer-dash-set"
                    onChange={handleToggle}
                    checked={isTwoFactorEnable}
                    aria-labelledby="neat-label"
                    uncheckedIcon={
                      <div
                        style={{
                          fontSize: "10px",
                          color: "#fff",
                          fontWeight: "400",
                          padding: "4px 0 0 4px",
                        }}
                      ></div>
                    }
                    checkedIcon={
                      <div
                        style={{
                          fontSize: "10px",
                          color: "#fff",
                          fontWeight: "400",
                          padding: "4px 0 0 6px",
                        }}
                      ></div>
                    }
                    onColor="#7A724E"
                    offColor="#7A724E"
                    height={35}
                    width={90}
                  />
                  {console.log(
                    data?.me?.plates?.[0]?.detail?.offers?.status,
                    "status"
                  )}
                </div>
              </div>
            </div>
            {isTwoFactorEnable ? (
              <div className="parent-profile-form">
                <div className="profile-box-parent sett-responsive-auction-box">
                  {addcardcounterapproved === "" ? (
                    <div className="parent-offer">
                      <div className="parent-auction-text-flex">
                        <img src={UserDashboardBoxIcnMetroHammer} alt="" />
                        <h2>OFFER2</h2>
                      </div>
                      <div className="parent-auction-fi-tab-cards">
                        {platesToShow
                          .filter(
                            (plate) =>
                              plate.detail.__typename === "PlateListing"
                          )
                          .map((plate) => {
                            const highestPriceOffer = getHighestPriceOffer(
                              plate.detail.offers
                            );
                            const highestPriceStatus =
                              highestPriceOffer?.status;

                            // Check if the highest price offer's status is "DECLINE"
                            const isDecline = highestPriceStatus === "DECLINE";
                            const isApproved = highestPriceStatus === "APPROVE";

                            return isDecline ? (
                              <div className="auction-card-box-parent">
                                {/* ... other elements ... */}
                                <div className="parent-date-time-flex"></div>
                              </div>
                            ) : (
                              <div className="auction-card-box-parent">
                                <div className="auction-card-box-border">
                                  <div className="box-inner-back-img">
                                    <img src={UserDashboardMaskGroup1} alt="" />
                                  </div>
                                  <div className="auction-box-plate-one userdash-set-offer-plateone">
                                    <img src={UserDashboardLayer1} alt="" />
                                  </div>
                                  <div className="auction-card-fi-btn">
                                    <button>{plate?.combination}</button>
                                  </div>
                                  <div className="parent-join-btn-auction">
                                    <button className="sm-bid set-padding-offer">
                                      Asking Price
                                    </button>
                                    <button className="lg-ruppees">
                                      ${plate?.askingPrice}
                                    </button>
                                  </div>
                                  <div className="offer-card-view-btn">
                                    <Link
                                      to={`/OfferConsider/${plate.id}`}
                                      className="view-link-button"
                                    >
                                      View
                                    </Link>
                                  </div>
                                  {isApproved ? (
                                    <div className="offer-card-counter-btn">
                                      <button className="add-offer-screen">
                                        Offer Accepted
                                      </button>
                                    </div>
                                  ) : (
                                    <div className="offer-card-counter-btn">
                                      <button
                                        className="add-offer-screen"
                                        onClick={() => {
                                          setPlateData(plate);
                                          console.log(plate, plateData);
                                          setCardCounterApproved(
                                            "add-offer-first-screen"
                                          );
                                        }}
                                      >
                                        Counter Offer
                                      </button>
                                    </div>
                                  )}
                                  <div className="dropdown">
                                    <button
                                      className="btn btn-secondary dropdown-toggle set-userdash-back-color"
                                      type="button"
                                      id="dropdownMenuButton1"
                                      data-bs-toggle="dropdown"
                                      aria-expanded="false"
                                    >
                                      Your Offer
                                    </button>
                                    <ul
                                      className="dropdown-menu set-drop-menu-userdash"
                                      aria-labelledby="dropdownMenuButton1"
                                      style={{ background: "#3C720D" }}
                                    >
                                      <li>
                                        <Link
                                          className="dropdown-item"
                                          to=""
                                          style={{
                                            fontSize: "18px",
                                            fontWeight: "600",
                                            padding: "0 0 8px 0",
                                          }}
                                        >
                                          $
                                          {
                                            getHighestPriceOffer(
                                              plate.detail.offers
                                            )?.price
                                          }
                                        </Link>
                                      </li>

                                      <div className="parent-date-time-flex">
                                        <li>
                                          <Link
                                            className="dropdown-item"
                                            href=""
                                            style={{
                                              fontSize: "8px",
                                              fontWeight: "400",
                                            }}
                                          >
                                            {
                                              getHighestPriceOffer(
                                                plate?.detail?.offers
                                              )?.status
                                            }
                                            {console.log(
                                              getHighestPriceOffer(
                                                plate?.detail?.offers
                                              )?.status,
                                              "statussssssss"
                                            )}
                                          </Link>
                                        </li>
                                        <li>
                                          <Link
                                            className="dropdown-item"
                                            href=""
                                            style={{
                                              fontSize: "8px",
                                              fontWeight: "400",
                                            }}
                                          >
                                            {
                                              getHighestPriceOffer(
                                                plate.detail.offers
                                              )?.createdAt
                                            }
                                          </Link>
                                        </li>
                                      </div>
                                    </ul>
                                    {console.log(
                                      getHighestPriceOffer(plate.detail.offers)
                                        ?.counter,
                                      "counterOffer"
                                    )}
                                  </div>
                                </div>
                              </div>
                            );
                          })}

                        {/* <div className="auction-tab-flex-parent offer-gapping">
                          {platesToShow
                            .filter(
                              (plate) =>
                                plate.detail.__typename === "PlateListing"
                            )
                            .map((plate) => {
                              return (
                                <div className="auction-card-box-parent">
                                  <div className="auction-card-box-border">
                                    <div className="box-inner-back-img">
                                      <img
                                        src={UserDashboardMaskGroup1}
                                        alt=""
                                      />
                                    </div>
                                    <div className="auction-box-plate-one userdash-set-offer-plateone">
                                      <img src={UserDashboardLayer1} alt="" />
                                    </div>
                                    <div className="auction-card-fi-btn">
                                      <button>{plate?.combination}</button>
                                    </div>
                                    <div className="parent-join-btn-auction">
                                      <button className="sm-bid set-padding-offer">
                                        Asking Price
                                      </button>
                                      <button className="lg-ruppees">
                                        ${plate?.askingPrice}
                                      </button>
                                    </div>
                                    <div className="offer-card-view-btn">
                                      <Link
                                        to={`/OfferConsider/${plate.id}`}
                                        className="view-link-button"
                                      >
                                        View
                                      </Link>
                                    </div>
                                    <div className="offer-card-counter-btn">
                                      <button
                                        className="add-offer-screen"
                                        onClick={() => {
                                          setPlateData(plate);
                                          console.log(plate, plateData);
                                          setCardCounterApproved(
                                            "add-offer-first-screen"
                                          );
                                        }}
                                      >
                                        Counter Offer
                                      </button>
                                    </div>
                                    <div className="dropdown">
                                      <button
                                        className="btn btn-secondary dropdown-toggle set-userdash-back-color"
                                        type="button"
                                        id="dropdownMenuButton1"
                                        data-bs-toggle="dropdown"
                                        aria-expanded="false"
                                      >
                                        Your Offer
                                      </button>
                                      <ul
                                        className="dropdown-menu set-drop-menu-userdash"
                                        aria-labelledby="dropdownMenuButton1"
                                        style={{ background: "#3C720D" }}
                                      >
                                        <li>
                                          <Link
                                            className="dropdown-item"
                                            to=""
                                            style={{
                                              fontSize: "18px",
                                              fontWeight: "600",
                                              padding: "0 0 8px 0",
                                            }}
                                          >
                                            $
                                            {
                                              getHighestPriceOffer(
                                                plate.detail.offers
                                              )?.price
                                            }
                                          </Link>
                                        </li>

                                        <div className="parent-date-time-flex">
                                          <li>
                                            <Link
                                              className="dropdown-item"
                                              href=""
                                              style={{
                                                fontSize: "8px",
                                                fontWeight: "400",
                                              }}
                                            >
                                              {
                                                getHighestPriceOffer(
                                                  plate?.detail?.offers
                                                )?.status
                                              }
                                              {console.log(
                                                getHighestPriceOffer(
                                                  plate?.detail?.offers
                                                )?.status,
                                                "statussssssss"
                                              )}
                                            </Link>
                                          </li>
                                          <li>
                                            <Link
                                              className="dropdown-item"
                                              href=""
                                              style={{
                                                fontSize: "8px",
                                                fontWeight: "400",
                                              }}
                                            >
                                              {
                                                getHighestPriceOffer(
                                                  plate.detail.offers
                                                )?.createdAt
                                              }
                                            </Link>
                                          </li>
                                        </div>
                                      </ul>
                                      {console.log(
                                        getHighestPriceOffer(
                                          plate.detail.offers
                                        )?.counter,
                                        "counterOffer"
                                      )}
                                    </div>
                                  </div>
                                </div>
                              );
                            })}
                        </div> */}
                      </div>
                      <div className="pagination">
                        <button
                          className={`pagination-btn prev ${
                            currentPage === 1 ? "disabled" : ""
                          }`}
                          onClick={() => handleLoadMore(currentPage - 1)}
                          disabled={currentPage === 1}
                        >
                          <FontAwesomeIcon
                            icon={faChevronLeft}
                            style={{ padding: "0px 25px" }}
                          />
                        </button>
                        {Array.from({ length: totalPages }).map((_, index) => (
                          <button
                            key={index}
                            className={`pagination-btn ${
                              currentPage === index + 1 ? "active" : ""
                            }`}
                            onClick={() => handleLoadMore(index + 1)}
                          >
                            {index + 1}
                          </button>
                        ))}
                        <button
                          className={`pagination-btn next ${
                            currentPage === totalPages ? "disabled" : ""
                          }`}
                          onClick={() => handleLoadMore(currentPage + 1)}
                          disabled={currentPage === totalPages}
                        >
                          <FontAwesomeIcon
                            icon={faChevronRight}
                            style={{ padding: "0px 25px" }}
                          />
                        </button>
                      </div>
                    </div>
                  ) : addcardcounterapproved === "add-offer-first-screen" ? (
                    <div className="add-offer-screen-one">
                      <div className="add-offer-first-screen">
                        <div className="parent-offer-next-flex">
                          <img
                            src={UserDashboardIcnMaterialLocalOffer}
                            alt=""
                          />
                          <h2>OFFER</h2>
                          <Link onClick={handleToggleModal}>
                            <FontAwesomeIcon
                              icon={faCircleXmark}
                              style={{ fontSize: "25px", color: "black" }}
                            />
                          </Link>
                        </div>
                        <div className="offer-next-screen-btn-parent">
                          <div className="add-offer-btn">
                            <button>{plateData?.combination}</button>
                          </div>
                          <div className="add-offer-heading">
                            <h2>Counter Offer</h2>
                          </div>
                          <div className="add-offer-price-btn">
                            <button>
                              {
                                getHighestPriceOffer(plateData.detail.offers)
                                  ?.counter
                              }
                            </button>
                          </div>
                          <div className="add-more-btn-flex">
                            <button
                              className="accept-offer add-more-offer-screen"
                              onClick={() => {
                                handleApprove(
                                  getHighestPriceOffer(plateData.detail.offers)
                                    ?.id
                                );
                              }}
                            >
                              ACCEPT
                            </button>
                            <button
                              className="decline-offer add-back-offer-screen"
                              onClick={() => {
                                handledecline(
                                  getHighestPriceOffer(plateData.detail.offers)
                                    ?.id
                                );
                              }}
                            >
                              DECLINE
                            </button>
                          </div>
                        </div>
                      </div>

                      {addcardcounterapproved === "offer-next-para-parent" ? (
                        <div className="offer-next-screen-card">
                          <Link onClick={handleToggleModal}>
                            <FontAwesomeIcon
                              icon={faCircleXmark}
                              style={{ fontSize: "25px", color: "black" }}
                            />
                          </Link>
                          <div className="offer-next-para-parent">
                            <div className="offer-next-para">
                              <p>Balance to be paid now</p>
                            </div>
                            <div className="offer-next-price">
                              <p>$6,400.00</p>
                            </div>
                            <div className="offer-next-second-para">
                              <p>Select Deposit Payment Method</p>
                            </div>
                            <div className="offer-next-second-para-label-flex">
                              <label className="offer-checkbox-parent">
                                <input type="checkbox" checked="checked" />
                                <div className="checkmark"></div>
                              </label>
                              <p>Credit or Debit Card via Stripe</p>
                            </div>
                            <div className="next-offer-last-btn">
                              <button>Pay Deposit via Stripe</button>
                            </div>
                          </div>
                        </div>
                      ) : null}
                    </div>
                  ) : null}
                </div>
              </div>
            ) : (
              <div className="parent-profile-form">
                <div className="profile-box-parent">
                  <div className="parent-offer">
                    <div className="parent-auction-text-flex">
                      <img src={SellerDashboardIcnMaterialLocalOffer} alt="" />
                      <h2>OFFER</h2>
                    </div>
                    {addoffertable === "" ? (
                      <div className="parent-offer-table">
                        <div className="box-table-start">
                          <div className="offer-seller-tble d-flex w-100">
                            <div
                              style={{ width: "28%" }}
                              className="offer-seller-tble-inner text-center"
                            >
                              <h3>Product Name</h3>
                            </div>
                            <div
                              style={{ width: "45%" }}
                              className="offer-seller-tble-inner set-width"
                            >
                              <h3>Description</h3>
                            </div>
                            <div
                              style={{ width: "17%" }}
                              className="offer-seller-tble-inner text-center"
                            >
                              <h3>Desired Plate Price</h3>
                            </div>
                            <div
                              style={{ width: "10%" }}
                              className="offer-seller-tble-inner text-center set-offer-width"
                            >
                              <h3>Offers</h3>
                            </div>
                          </div>
                          {plates
                            .filter(
                              (plate) =>
                                plate.detail.__typename === "PlateListing"
                            )
                            .map((plate) => (
                              <div className="offer-seller-tble d-flex w-100">
                                <div
                                  style={{ width: "28%" }}
                                  className="offer-seller-tble-next-line set-offer-plate-num back-color-sett-offfer-change"
                                >
                                  <h3>{plate?.combination}</h3>
                                </div>
                                <div
                                  style={{ width: "45%" }}
                                  className="offer-seller-tble-next-line set-offer-para back-color-sett-offfer-change set-width"
                                >
                                  <h3>{plate?.comments}</h3>
                                </div>
                                <div
                                  style={{ width: "17%" }}
                                  className="offer-seller-tble-next-line text-center set-offer-text back-color-sett-offfer-change"
                                >
                                  <h3>{plate?.askingPrice}</h3>
                                </div>
                                <div
                                  style={{ width: "10%" }}
                                  className="offer-seller-tble-next-line set-offer-img text-center back-color-sett-offfer-change set-offer-width"
                                >
                                  <img
                                    onClick={() => {
                                      handleImageClick(plate?.id);
                                    }}
                                    className="seller-next-offer-screen"
                                    src={SellerDashboardIcGroup24}
                                    alt=""
                                  />
                                </div>
                              </div>
                            ))}
                        </div>
                      </div>
                    ) : addoffertable === "offer-next-screen-parent" ? (
                      <div className="offer-next-screen-parent">
                        <Link className="sett-the-cros-btn">
                          <FontAwesomeIcon
                            icon={faCircleXmark}
                            style={{ fontSize: "25px", color: "black" }}
                            onClick={handleXmark2}
                          />
                        </Link>
                        <div className="offer-next-table-para">
                          {console.log(data2?.plate.combination, "in modal")}
                          <p>
                            Victorian Signature plate {data2?.plate.combination}
                          </p>
                        </div>
                        <div className="offer-next-table-parent">
                          <div className="offer-seller-tble d-flex w-100">
                            <div
                              style={{ width: "25%" }}
                              className="offer-seller-tble-inner text-center"
                            >
                              <h3>Name</h3>
                            </div>
                            <div
                              style={{ width: "18%" }}
                              className="offer-seller-tble-inner"
                            >
                              <h3>BUYER OFFER</h3>
                            </div>
                            <div
                              style={{ width: "22%" }}
                              className="offer-seller-tble-inner text-center"
                            >
                              <h3>COUNTER OFFER</h3>
                            </div>
                            <div
                              style={{ width: "35%" }}
                              className="offer-seller-tble-inner text-center"
                            ></div>
                          </div>
                          {offers2.length > 0 ? (
                            <>
                              {offers2.map((offer, index) => (
                                <div className="offer-seller-tble d-flex w-100">
                                  <div
                                    style={{ width: "100%" }}
                                    className="offer-seller-tble-next-page text-center"
                                  >
                                    <h3>{offer.user.username}</h3>
                                  </div>
                                  <div
                                    style={{ width: "100%" }}
                                    className="offer-seller-tble-next-page text-center"
                                  >
                                    <h3>{offer.price}</h3>
                                  </div>
                                  <div
                                    style={{ width: "100%" }}
                                    className="offer-seller-tble-next-page text-center"
                                  >
                                    <h3>{offer.counter}</h3>
                                  </div>

                                  {offer?.status === "DECLINE" ? (
                                    <>
                                      {" "}
                                      <button className="offer-table-decline add-more-offer-table">
                                        Decline
                                      </button>
                                    </>
                                  ) : offer?.status === "APPROVE" ? (
                                    <>
                                      {" "}
                                      <button className="offer-table-accept add-more-offer-table">
                                        Accept
                                      </button>
                                    </>
                                  ) : (
                                    <div
                                      style={{ width: "35%" }}
                                      className="offer-seller-tble-next-page next-page-offer-btn text-center"
                                    >
                                      <button
                                        onClick={() => {
                                          handleApprove(offer.id);
                                        }}
                                        className="offer-table-accept add-more-offer-table"
                                      >
                                        Accept
                                      </button>

                                      <button
                                        onClick={() => {
                                          handledecline(offer.id);
                                        }}
                                        className="offer-table-decline add-more-offer-table"
                                      >
                                        Decline
                                      </button>

                                      <button
                                        onClick={() => {
                                          handleCounterClick(offer.id);
                                          setAddOfferTable(
                                            "third-table-add-parent"
                                          );
                                        }}
                                        className="offer-table-counter add-more-offer-table"
                                      >
                                        Counter
                                      </button>
                                    </div>
                                  )}
                                </div>
                              ))}
                            </>
                          ) : (
                            <p>No offers available.</p>
                          )}
                        </div>
                      </div>
                    ) : addoffertable === "third-table-add-parent" ? (
                      <div className="third-table-add-parent">
                        <Link className="sett-the-cros-btn-sec">
                          <FontAwesomeIcon
                            icon={faCircleXmark}
                            onClick={handleXmark}
                            style={{ fontSize: "25px", color: "black" }}
                          />
                        </Link>
                        <div className="third-screen-parent">
                          <div className="third-screen-form">
                            <label for="">Amount</label>
                          </div>
                          <div className="third-screen-input-para">
                            <p>$</p>
                            <input
                              type="text"
                              inputMode="numeric"
                              pattern="[0-9]*"
                              value={counterValue}
                              onChange={(e) => setCounterValue(e.target.value)}
                            />
                          </div>
                          <div className="thired-screen-offer-form-btn">
                            <button
                              className="move-to-offer-main-screen"
                              onClick={handleUpdateOffer}
                            >
                              Submit
                            </button>
                          </div>
                        </div>
                      </div>
                    ) : null}
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </section>
  );
}
export default Offerone;
