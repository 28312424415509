import React from 'react';
import groupnew from './images/_Group_.png';
import { Link, Navigate, useLocation, useNavigate } from 'react-router-dom';
import { useState } from 'react';
import { baseUrl } from '../../utils/Function';
import axios from "axios";


function Np() {
    const [pass, setPass] = useState(""); 

    const location = useLocation();
    const navigate = useNavigate();

    const handleChange =(event)=>{
        setPass(event.target.value);

    }
    const mailAddress = location.state.mail
    const verCode = location.state.intValue
    console.log(mailAddress,'Email------');
    console.log(location.state.intValue,'code');
    console.log(location , "passs")

    const handleClick = ()=>{
        let data = JSON.stringify({
            "email": mailAddress,
            "code": verCode,
            "password": pass
          });
          let config = {
            method: 'post',
            maxBodyLength: Infinity,
            url: `${baseUrl}auth/reset-password`,
            headers: {
              'Content-Type': 'application/json'
            },
            data : data
          };
          axios.request(config)
          .then((response) => {
            console.log(JSON.stringify(response.data));
          })
          .catch((error) => {
            console.log(error);
          });
          navigate("/Conformation")
    }

  return (
    <>
      <div className="main-scroll-bar login-page set-height-login-back newpasswordpage">
        <section className="login-sect">
          
          <div className="container">
            <div className="form-box">
              <div className="form-border-box">
              <Link to="/LoginApiTest" className="rght-bk-arrow">
                <i className="fa-solid fa-rectangle-xmark"></i>
                  </Link>
                <div className="icn hide">
                  <i className="fa-solid fa-xmark"></i>
                </div>
                <div className="form-img">
                  <img src={groupnew} alt="" />
                </div>
                <form autoComplete='off'>
                  <div className='new-psswrd'>
                 
                    <input type='text' value={pass} onChange={handleChange} placeholder='Enter New Password'/>
                  </div>
                  <Link className='newpass-btn' to="/Conformation" onClick={handleClick}>Confirm </Link>                  
                  <div className="form-img-txt">
                    <img src={groupnew} alt="" />
                    <svg xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 512 512"><path d="M9.4 233.4c-12.5 12.5-12.5 32.8 0 45.3l128 128c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L109.3 288 480 288c17.7 0 32-14.3 32-32s-14.3-32-32-32l-370.7 0 73.4-73.4c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0l-128 128z" /></svg>
                    <Link to="/">Go to Signature +</Link>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </section>
      </div>
    </>
  );
}

export default Np;
