import React, { useState } from 'react';
import Switch from "react-switch";
import UserSidebar from '../../../../components/UserSidebar';
import UserDasboardBoxIcnMaterialSecurity from "../../../../assets/images/Icon-material-security.png";
import { gql, useMutation } from '@apollo/client';
import { ToastContainer, toast } from 'react-toastify';

const UPDATE_USER_MUTATION = gql`
  mutation UpdateUser($data: UpdateUserInput!) {
    updateUser(data: $data) {
      id
    }
  }
`;

function Account_security() {
  const [isTwoFactorEnable, setIsTwoFactorEnable] = useState(false);
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [passwordsMatch, setPasswordsMatch] = useState(true);

  const [updateUserMutation] = useMutation(UPDATE_USER_MUTATION);

  const handlePasswordChange = (event) => {
    setPassword(event.target.value);
    setPasswordsMatch(event.target.value === confirmPassword);
  };

  const handleConfirmPasswordChange = (event) => {
    setConfirmPassword(event.target.value);
    setPasswordsMatch(event.target.value === password);
  };

  const handleFormSubmit = async (event) => {
    event.preventDefault();
    try {
      if (password !== confirmPassword) {
        setPasswordsMatch(false);
        return;
      }

      // Execute the mutation
      const { data } = await updateUserMutation(
        {
          variables: {
            data: {
              password: password,
            },
          },
        });

      // Handle the response data if needed
      // console.log('Updated user ID:', data.updateUser.id);
      // Show success toast

      toast.success('Password updated successfully!', {
        position: 'top-right',
        autoClose: 3000, // 3 seconds
        hideProgressBar: false,
      });

      // Reset the password input after successful submission
      setPassword('');
      setConfirmPassword('');
      setPasswordsMatch(true);
    } catch (error) {
      // Handle errors if necessary
      // console.error('Error updating user:', error.message);
    }
  };

  return (
    <section className="dasgboard-miansection pb-5">
      <div className="user-seller-approved-unapproved-container pb-5">
        <div className="row pt-2">
          <UserSidebar />
          <div className="col-sm-9 col-md-9 col-lg-9 dashboard-main-content">
            <div className="dashboard-title">
              <h1>Account Security</h1>
            </div>
            <div className="parent-profile-form">
              <div className="profile-box-parent">
                <div className="account-security-text">
                  <img src={UserDasboardBoxIcnMaterialSecurity} alt="" />
                  <h2>Security / Change password</h2>
                </div>
                <form action="" onSubmit={handleFormSubmit}>
                  <div className="parent-account-security">
                    <label htmlFor="">New Password</label><br />
                
                   

                    <input
                      type="password"
                      value={password}
                      onChange={handlePasswordChange}
                    /><br />
                    <label htmlFor="">Confirm Password</label><br />
                 
                    <input
                      type="password"
                      value={confirmPassword}
                      onChange={handleConfirmPasswordChange}
                    />
                    {!passwordsMatch && <p style={{ color: 'red' }}>Passwords do not match!</p>}
                  </div>

                  <div className="account-security-btn">
                    <button type="submit">Update</button>
                  </div>
                </form>
                <ToastContainer />
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Account_security;
