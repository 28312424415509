import React, { useState } from "react";
import UnApprovedUser from "./UnApprovedUser";
import ApprovedUser from "./ApprovedUser";
import { gql, useQuery } from "@apollo/client";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from 'react-redux';
import { logoutUser } from '../../../../redux/slices/userSlice';
import { logoutWishlist } from '../../../../redux/slices/wishlistSlice';

const Me = gql`
  query {
    me {
      status
      firstName
      lastName
      email
      phoneNumber
      drivingLicense
    }
  }
`;

function BuyerDashboard() {
  const navigate = useNavigate();
  const [isTwoFactorEnable, setIsTwoFactorEnable] = useState(true);
  const handleToggle = () => {
    setIsTwoFactorEnable((prev) => !prev);
  };
  const dispatch = useDispatch()
  const { loadin, error, data } = useQuery(Me);
  if (loadin) {
    return <div>Loading...</div>;
  }
  if (error) {
    return <div>Error: {error.message}</div>;
  }
  if (data?.me?.status === "APPROVED") {
    return <ApprovedUser />;
  }
  if (data?.me?.status === "DECLINE") {
    navigate("/LoginApiTest");
    dispatch(logoutUser());
    dispatch(logoutWishlist());
  } else {
    return <UnApprovedUser />;
  }
}

export default BuyerDashboard;
