import React from "react";
import { useQuery } from "@apollo/client";
import { gql } from "@apollo/client";
// import { Link } from 'react-router-dom';
import PlateItem from "./PlateItem";
import { useSelector } from "react-redux";
import { useState } from "react";
import { baseUrl } from "../../../utils/Function";
import { RevolvingDot } from "react-loader-spinner";

const Plate = ({ data1, data2, data3, data4 }) => {
  const userData = useSelector((state) => state.user.userData);
  const wishList = useSelector((value) => value.wishlist);
  console.log("wishList", wishList);
  const token = userData.accessToken;
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 6; // Adjust the number of items per page according to your needs

  let val1 = 0;
  let val2 = 0;

  if (data4?.length) {
    val1 = Number(data4[0]);
    val2 = Number(data4[1]);
  }

  const GET_PLATES = gql`
  query Plates {
    plates(
      where: {
        purpose: LISTING
        status: APPROVED
        term:"${data1}"
        pattern: "${data3}"
        characterCount: ${data2} 
      }
    ) {
      askingPrice
      combination
      comments
      numberPlate
      detail {
        ... on PlateListing {
          id
          isOpenForPrice
          settlePrice
        }
      }
      id
      purpose
      status
    }
  }
`;

  const { loading, error, data } = useQuery(GET_PLATES, {fetchPolicy: "network-only"}, {
    variables: { lowValues: data4 },
  });

  if (loading) {
    return <RevolvingDot
    height="100"
    width="100"
    radius="45"
    color="#6D6D6D"
    secondaryColor=''
    ariaLabel="revolving-dot-loading"
    wrapperStyle={{}}
    wrapperClass=""
    visible={true}
  />;
  }

  if (error) {
    return <div>Error: {error.message}</div>;
  }

  const goToPreviousPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  const goToNextPage = () => {
    console.log({ data });
    const totalPages = Math.ceil(data?.plates?.length / itemsPerPage);
    if (currentPage < totalPages) {
      setCurrentPage(currentPage + 1);
    }
  };

  function renderPageNumbers() {
    const totalPages = Math.ceil(data?.plates?.length / itemsPerPage);
    const pageNumbers = [];
    for (let i = 1; i <= totalPages; i++) {
      pageNumbers.push(
        <button
          style={{ color: "#fff" }}
          key={i}
          className={i === currentPage ? "active" : ""}
          onClick={() => setCurrentPage(i)}
        >
          {i}
        </button>
      );
    }

    return pageNumbers;
  }

  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;

  // Slice the users array based on pagination indices
  const myPlates = data.plates.slice()
  .reverse()
  const displayPlates = myPlates.slice(startIndex, endIndex);

  return (
    <div className="main">
      <section className="main-buy">
        <div>
          <div className="parent-twogrid">
            {/* <div className="grid-buy-main"> */}
            <div className="right-main-grid-buy">
              <div className="right-main-buy-grid-inner">
                {displayPlates &&
                  displayPlates
                    .map((plate) => (
                      <PlateItem
                        key={plate.id}
                        plate={plate}
                        baseUrl={baseUrl}
                        token={token}
                      />
                    ))}
              </div>
            </div>
            {/* </div> */}
          </div>
        </div>
      </section>

      {data?.plates?.length === 0 ? null : (
        <div>
          <button onClick={goToPreviousPage} className="approved-btn-3 mx-2">
            Previous
          </button>
          <button onClick={goToNextPage} className="approved-btn-3">
            Next
          </button>
        </div>
      )}
    </div>
  );
};

export default Plate;
